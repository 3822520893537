import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { BIObjectiveSetting } from '../../../models/bi-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BIObjectiveSettingService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-BIObjectiveSetting';

  constructor(
    dbServ: DynamoDBService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): BIObjectiveSetting {
    return item ? new BIObjectiveSetting(item) : new BIObjectiveSetting();
  }

  override sort(): void {
    (this.items as BIObjectiveSetting[])?.sort((a, b) => {
      return b.startDate.getTime() - a.startDate.getTime();
    });
  }
}
