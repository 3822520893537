import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { PSWorksheet } from '../../../models/ps-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from '../../notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class PSWorksheetService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-PSWorksheet';

  constructor(
    dbServ: DynamoDBService,
    private notificationsServ: NotificationsService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): PSWorksheet {
    return item ? new PSWorksheet(item) : new PSWorksheet();
  }

  override sort(): void {
    (this.items as PSWorksheet[])?.sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime();
    });
  }

  override async add(worksheet: PSWorksheet): Promise<any> {
    await super.add(worksheet);
    if (worksheet.step == PSWorksheet.STEP_EXECUTION) {
      await this.notificationsServ.updatePSNotification(worksheet);
    }
  }

  override async update(worksheet: PSWorksheet): Promise<any> {
    await super.update(worksheet);
    if (worksheet.step == PSWorksheet.STEP_EXECUTION) {
      await this.notificationsServ.updatePSNotification(worksheet);
    } else if (worksheet.step == PSWorksheet.STEP_COMPLETED) {
      await this.notificationsServ.deleteWorksheetNotification(worksheet);
    }
  }

  override async delete(worksheet: PSWorksheet): Promise<any> {
    await super.delete(worksheet);
    await this.notificationsServ.deleteWorksheetNotification(worksheet);
  }
}
