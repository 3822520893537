import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { BAMarathon } from '../../../models/ba-marathon';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BAMarathonService extends WorksheetService {
  protected readonly table = environment.aws.resourceNamePrefix + '-BAMarathon';

  constructor(dbServ: DynamoDBService) {
    super(dbServ);
  }

  createWorksheet(item?: any): BAMarathon {
    return new BAMarathon(item);
  }

  override sort(): void {
    if (this.items) {
      (this.items as BAMarathon[]).sort((a, b) => {
        return b.createdAt.getTime() - a.createdAt.getTime();
      });
    }
  }

  get bestAccomplishMarathon(): BAMarathon {
    const finishedMaratons = (this.items as BAMarathon[]).filter((e) => {
      return e.done == true;
    });
    finishedMaratons.sort((a, b) => {
      return b.accomplishment - a.accomplishment;
    });
    return finishedMaratons[0] as BAMarathon;
  }

  get bestDelightMarathon(): BAMarathon {
    const finishedMaratons = (this.items as BAMarathon[]).filter((e) => {
      return e.done == true;
    });
    finishedMaratons.sort((a, b) => {
      return (b.delight + b.bonus) - (a.delight + a.bonus);
    });
    return finishedMaratons[0] as BAMarathon;
  }

  latestMarathon(): BAMarathon | null {
    this.sort();
    return this.items ? this.items[0] as BAMarathon : null;
  }
}
