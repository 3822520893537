import { Injectable } from '@angular/core';
import { LocalNotifications, Schedule } from '@capacitor/local-notifications';

export { Schedule as Schedule }

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {
  constructor() { }

  async hasPermission(): Promise<boolean> {
    const result = await LocalNotifications.checkPermissions();
    return result.display === 'granted';
  }

  async canRequestPermission(): Promise<boolean> {
    const result = await LocalNotifications.checkPermissions();
    return result.display !== 'denied';
  }

  async requestPermission(): Promise<boolean> {
    const result = await LocalNotifications.requestPermissions();
    return result.display === 'granted';
  }

  async updateNotification(
    notificationId: number,
    schedule: Schedule,
    title: string,
    body: string,
    params?: any
  ): Promise<void> {
    try {
      await LocalNotifications.cancel({
        notifications: [
          { id: notificationId }
        ]
      });

      const options = Object.assign({
        id: notificationId,
        title,
        body,
        schedule,
        smallIcon: "ic_popup_reminder"
      }, params);
      await LocalNotifications.schedule({ notifications: [options] });
      console.log(`LocalNotificationsService: update notification id = ${notificationId}. schedule.at = ${schedule.at}`);
    } catch (error) {
      console.log('LocalNotificationsService: update error', error);
      throw error;
    }
  }

  async deleteNotification(notificationId: number): Promise<void> {
    try {
      await LocalNotifications.cancel({
        notifications: [
          { id: notificationId },
        ]
      });
      console.log(`LocalNotificationsService: deleteNotification: id = ${notificationId}`);
    } catch (e) {
      console.log('LocalNotificationsService: deleteNotification error', e);
    }
  }

  async deleteAllNotification(): Promise<void> {
    try {
      const result = await LocalNotifications.getPending();
      await LocalNotifications.cancel(result);
    } catch (e) {
      console.log('LocalNotificationsService: deleteAllNotification error', e);
    }
  }

  async getPending(): Promise<any[]> {
    const result = await LocalNotifications.getPending();
    return result.notifications;
  }
}
