import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  public static readonly assignments = [
    { code: 'C1',  selfCheck: 'intervention', modules: ['PE', 'BA1', 'BA2', 'BA3', 'BA4', 'BA5', 'EP1', 'EP2'] },
    { code: 'C2',  selfCheck: 'intervention', modules: ['PE', 'CR1', 'CR2', 'CR3', 'CR4', 'CR5', 'EP1', 'EP2'] },
    { code: 'C3',  selfCheck: 'intervention', modules: ['PE', 'PS1', 'PS2', 'PS3', 'PS4', 'PS5', 'PS6', 'EP'] },
    { code: 'C4',  selfCheck: 'intervention', modules: ['PE', 'AT1', 'AT2', 'AT3', 'AT4', 'AT5', 'EP1', 'EP2'] },
    { code: 'C5',  selfCheck: 'intervention', modules: ['PE', 'BI1', 'BI2', 'BI3', 'BI4', 'BI5', 'EP1', 'EP2'] },
    { code: 'C6',  selfCheck: 'intervention', modules: ['PE', 'BA1', 'BA2', 'CR3', 'CR4', 'BACR5', 'EP1', 'EP2'] },
    { code: 'C7',  selfCheck: 'intervention', modules: ['PE', 'BA1', 'BA2', 'PS3', 'PS4', 'BAPS5', 'EP1', 'EP2'] },
    { code: 'C8',  selfCheck: 'intervention', modules: ['PE', 'BA1', 'BA2', 'AT3', 'AT4', 'BAAT5', 'EP1', 'EP2'] },
    { code: 'C9',  selfCheck: 'intervention', modules: ['PE', 'BA1', 'BA2', 'BI3', 'BI4', 'BABI5', 'EP1', 'EP2'] },
    { code: 'C10', selfCheck: 'intervention', modules: ['PE', 'EP1', 'EP2'] },
    { code: 'C11', selfCheck: 'observation', modules: ['PE', 'HI1', 'HI2', 'HI3', 'EP1', 'EP2'] },
    { code: 'C12_C1', selfCheck: 'observation', modules: ['PE', 'EP1', 'BA1', 'BA2', 'BA3', 'BA4', 'BA5', 'EP2'] },
    { code: 'C12_C2', selfCheck: 'observation', modules: ['PE', 'EP1', 'CR1', 'CR2', 'CR3', 'CR4', 'CR5', 'EP2'] },
    { code: 'C12_C3', selfCheck: 'observation', modules: ['PE', 'EP', 'PS1', 'PS2', 'PS3', 'PS4', 'PS5', 'PS6'] },
    { code: 'C12_C4', selfCheck: 'observation', modules: ['PE', 'EP1', 'AT1', 'AT2', 'AT3', 'AT4', 'AT5', 'EP2'] },
    { code: 'C12_C5', selfCheck: 'observation', modules: ['PE', 'EP1', 'BI1', 'BI2', 'BI3', 'BI4', 'BI5', 'EP2'] },
    { code: 'C12_C6', selfCheck: 'observation', modules: ['PE', 'EP1', 'BA1', 'BA2', 'CR3', 'CR4', 'BACR5', 'EP2'] },
    { code: 'C12_C7', selfCheck: 'observation', modules: ['PE', 'EP1', 'BA1', 'BA2', 'PS3', 'PS4', 'BAPS5', 'EP2'] },
    { code: 'C12_C8', selfCheck: 'observation', modules: ['PE', 'EP1', 'BA1', 'BA2', 'AT3', 'AT4', 'BAAT5', 'EP2'] },
    { code: 'C12_C9', selfCheck: 'observation', modules: ['PE', 'EP1', 'BA1', 'BA2', 'BI3', 'BI4', 'BABI5', 'EP2'] },
    { code: 'C12_C11', selfCheck: 'observation', modules: ['PE', 'EP1', 'HI1', 'HI2', 'HI3', 'EP2'] }
  ];

  private defaults: any[] = [
    // 初回レッスン
    { name: 'pe', order: 0, assigned: true },
    // 第1回レッスン
    { name: 'ba1', order: 1, assigned: false },
    { name: 'sm1', order: 2, assigned: false },
    { name: 'cr1', order: 3, assigned: false },
    { name: 'ps1', order: 4, assigned: false },
    { name: 'at1', order: 5, assigned: false },
    { name: 'bi1', order: 6, assigned: false },
    { name: 'hi1', order: 7, assigned: false },
    // 第2回レッスン
    { name: 'ba2', order: 8, assigned: false },
    { name: 'cr2', order: 9, assigned: false },
    { name: 'ps2', order: 10, assigned: false },
    { name: 'at2', order: 11, assigned: false },
    { name: 'bi2', order: 12, assigned: false },
    { name: 'hi2', order: 13, assigned: false },
    // 第3回レッスン
    { name: 'ba3', order: 14, assigned: false },
    { name: 'cr3', order: 15, assigned: false },
    { name: 'ps3', order: 16, assigned: false },
    { name: 'at3', order: 17, assigned: false },
    { name: 'bi3', order: 18, assigned: false },
    { name: 'hi3', order: 19, assigned: false },
    // 第4回レッスン
    { name: 'ba4', order: 20, assigned: false },
    { name: 'cr4', order: 21, assigned: false },
    { name: 'ps4', order: 22, assigned: false },
    { name: 'at4', order: 23, assigned: false },
    { name: 'bi4', order: 24, assigned: false },
    // 第5回レッスン
    { name: 'ba5', order: 25, assigned: false },
    { name: 'cr5', order: 26, assigned: false },
    { name: 'ps5', order: 27, assigned: false },
    { name: 'at5', order: 28, assigned: false },
    { name: 'bi5', order: 28, assigned: false },
    { name: 'bacr5', order: 29, assigned: false },
    { name: 'baps5', order: 30, assigned: false },
    { name: 'baat5', order: 31, assigned: false },
    { name: 'babi5', order: 32, assigned: false },
    // 第6回レッスン
    { name: 'ps6', order: 33, assigned: false },
    // 最終回レッスン
    { name: 'ep', order: 34, assigned: false },
    { name: 'ep1', order: 33, assigned: false },
    { name: 'ep2', order: 34, assigned: false },
  ];

  private worksheetOrder: { [key: string]: number } = {
    'sc': 0,
    'co': 1,
    'ba': 2,
    'sm': 3,
    'cr': 4,
    'ps': 5,
    'at': 6,
    'bi': 7
  };

  private items: any[] = [];
  private assignmentCode!: string | undefined;
  private assignmentSelfCheck!: string;
  private assignmentWorksheets: {name:string, startedAt:Date | undefined}[] = [];
  private assignmentWorksheetItem: any = {};

  get code(): string | undefined {
    return this.assignmentCode;
  }

  get selfCheck(): string {
    return this.assignmentSelfCheck;
  }

  get list(): any[] {
    return this.items;
  }

  get order(): string[] {
    return this.items.map((v) => v.name);
  }

  get worksheets(): string[] {
    return this.assignmentWorksheets.map(e => e.name);
  }

  constructor() {}

  enableWorksheet(name: string, startedAt:Date|undefined = undefined): boolean {
    if (this.worksheetOrder[name] !== undefined
      && this.assignmentWorksheets.find((e) => e.name == name) == undefined) {
      this.assignmentWorksheets.push({ name:name, startedAt:startedAt });
      this.assignmentWorksheets.sort((a, b) => this.worksheetOrder[a.name] - this.worksheetOrder[b.name]);
      console.log("enableWorksheet", this.assignmentWorksheets);
      return true;
    }
    return false;
  }

  isWorksheetEnabled(name: string): boolean {
    return this.assignmentWorksheets.find((e) => e.name == name) !== undefined;
  }

  worksheetStartedAt(name: string): Date|undefined {
    const ws = this.assignmentWorksheets.find((e) => e.name == name);
    return ws ? ws.startedAt : undefined;
  }

  enableWorksheetItem(name:string, items:string[]): boolean {
    let worksheetItems = this.assignmentWorksheetItem[name];
    if (!worksheetItems) {
      worksheetItems = this.assignmentWorksheetItem[name] = [];
    }
    let updated = false;
    for (let item of items) {
      if (worksheetItems.find((v: string) => v == item) == undefined) {
        worksheetItems.push(item);
        updated = true;
      }
    }
    return updated;
  }

  getWorksheetItem(name: string): string[] {
    return this.assignmentWorksheetItem[name];
  }

  hasModule(name: string): boolean {
    return this.items.find((e) => {
      return e.assigned && e.name.startsWith(name);
    }) !== undefined;
  }

  isAssigned(name: string): boolean {
    return this.items.find((e) => e.name == name).assigned;
  }

  setAssignment(code: string | undefined) {
    this.assignmentCode = code;
    console.log(`Module.setAssignment: ${code}`);
    if (!code) {
      this.items = this.defaults.concat().map((v) => {
        return {
          name: v.name,
          order: v.order,
          assigned: v.assigned
        };
      });
      return;
    }

    const assignment = ModuleService.assignments.find((e) => e.code == code);
    if (assignment) {
      const assignedModules = assignment.modules.map((v, i) => {
        const name = v.toLowerCase();
        return {
          name: name,
          order: i,
          assigned: true
        };
      });

      const unassignedModules = this.defaults
      .filter((v) => {
        return assignedModules.find((e) => v.name == e.name) == null;
      })
      .map((v, i) => {
        return {
          name: v.name,
          order: i + assignedModules.length,
          assigned: false
        };
      });

      this.items = assignedModules.concat(unassignedModules).sort((a, b) => a.order - b.order);
      this.items.forEach((item) => { if (item.assigned) console.log('Module.item', item); });

      this.assignmentSelfCheck = assignment.selfCheck;
    }

    this.assignmentWorksheets = [];
    this.assignmentWorksheetItem = {};

    //待機群
    AppConfig.WAITING_LIST_USER = code.startsWith("C12");
  }
}
