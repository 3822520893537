import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/services';
import { AuthStatus } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard {
  constructor(
    private userServ: UserService,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const authStatus = this.userServ.getAuthStatus();
    console.log('RegistrationGuard.canActivate auth status: ', AuthStatus[authStatus]);

    if (authStatus === AuthStatus.EMAIL_VERIFICATION_REQUIRED) {
      this.router.navigate(['/settings/email-change']);
      return false;
    }

    const status = this.userServ.getAttribute(UserService.ATTR_STATUS);
    console.log("RegistrationGuard.canActivate UserService.ATTR_STATUS: ", status);
    if (!status) {
      this.router.navigate(['/registration']);
      return false;
    }

    return true;
  }
}
