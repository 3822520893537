import { Injectable } from '@angular/core';
import { Messaging, getToken, onMessage, MessagePayload } from '@angular/fire/messaging';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { Auth } from 'aws-amplify';
import { Storage } from '@ionic/storage-angular';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../../environments/environment';
import { DynamoDBService } from '../database/dynamodb.service';
import { WebPush } from '../../models/web-push';

interface ScheduleOn {
  hour: number;
  minute: number;
}

export interface Schedule {
  at?: Date;
  on?: ScheduleOn;
}

export abstract class BaseWebPushService {
  private static readonly IOS_SUPPORT_MIN_VERSION = '16.4';
  private static readonly INITIALIZATION_RETRIES = 3;
  private static readonly UPDATE_NOTIFICATION_RETRIES = 5;
  private static readonly UPDATE_NOTIFICATION_RETRY_INTERVAL = 3000;

  constructor(
    private messaging: Messaging,
    private platform: Platform
  ) { }

  private supported: boolean | null = null;
  private initializing = false;
  private initialized = false;
  private currentToken: string | null = null;

  protected items: WebPush[] = [];

  get token(): string | null {
    return this.currentToken;
  }

  async hasPermission(): Promise<boolean> {
    if (await this.isSupported() == false) {
      return false;
    }

    const permission = Notification.permission;
    console.log(`${this.constructor.name} hasPermission`, permission);
    if (permission !== 'granted') {
      return false;
    }

    console.log(`${this.constructor.name} initialized`, this.initialized);
    if (this.initialized === false) {
      await this.initialize();
    }

    return true;
  }

  async canRequestPermission(): Promise<boolean> {
    if (await this.isSupported() == false) {
      return false;
    }

    const permission = Notification.permission;
    console.log(`${this.constructor.name} canRequestPermission`, permission);
    return (permission !== 'denied');
  }

  async requestPermission(): Promise<boolean> {
    if (await this.isSupported() == false) {
      return false;
    }

    const permission = await Notification.requestPermission();
    console.log(`${this.constructor.name} requestPermission`, permission);
    if (permission !== 'granted') {
      return false;
    }

    console.log(`${this.constructor.name} initialized`, this.initialized);
    if (this.initialized === false) {
      await this.initialize();
    }

    return true;
  }

  private async isSupported(): Promise<boolean> {
    if (this.supported !== null) {
      return this.supported;
    }
    this.supported = (await this.isSupportedOS()) && !Capacitor.isNativePlatform();
    return this.supported;
  }

  private async isSupportedOS(): Promise<boolean> {
    const hasMessaging = this.messaging ? true : false;

    // iOS16.4 以上かつPWAで表示中の場合のみWebプッシュをサポート
    if (this.platform.is('ios')) {
      const device = await Device.getInfo();
      const versionStrToNums = (version: string) => {
        return version.split('.').map(part => parseInt(part, 10));
      }
      const osVersion = device.osVersion;
      const osVerNums = versionStrToNums(osVersion);
      const supportedOsVerNums = versionStrToNums(BaseWebPushService.IOS_SUPPORT_MIN_VERSION);
      const isSupportedOs =
        (osVerNums[0] > supportedOsVerNums[0]) ||
        (osVerNums[0] == supportedOsVerNums[0] && osVerNums[1] >= supportedOsVerNums[1]);
      console.log(`os version: ${osVersion}`);
      console.log(`ios support min version: ${BaseWebPushService.IOS_SUPPORT_MIN_VERSION}`);
      console.log(`is Supported os: ${isSupportedOs}`);
  
      const isPWA = this.platform.is('pwa');
      console.log(`is PWA: ${isPWA}`);
      return isSupportedOs && isPWA && hasMessaging;
    } else {
      return hasMessaging;
    }
  }

  private async initialize(retry:number = 0): Promise<void> {
    try {
      if (this.initializing) {
        console.log(`${this.constructor.name} is initializing...`);
        return;
      }
      this.initializing = true;

      // FCM トークンを取得
      const currentToken = await getToken(this.messaging);
      if (currentToken) {
        console.log(`${this.constructor.name} Got FCM token`, { currentToken });
        this.currentToken = currentToken;

        // フォアグラウンド中の通知を処理
        onMessage(this.messaging, (message: MessagePayload) => {
          console.log(`${this.constructor.name} New foreground notification`, message);
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            if (registrations.length > 0) {
              const registration = registrations.find((registration: ServiceWorkerRegistration) => {
                const { scope, active } = registration;
                return scope && scope.includes('firebase-cloud-messaging-push-scope')
                  && active && active.state === 'activated';
              });
              console.log(`${this.constructor.name} ServiceWorkerRegistration`, registration);
              if (registration) {
                // フォアグラウンド中の通知を表示
                const notification = message.notification;
                if (notification) {
                  const title = notification.title || 'お知らせ';
                  const options: NotificationOptions = {
                    body: notification.body || '',
                    icon: notification.icon || 'assets/icons/icon-default.webp',
                  };
                  registration.showNotification(title, options);
                }
              }
            }
          });
        });

        // 初期化済みフラグを立てる
        this.initialized = true;
      }
    } catch(error: any) {
      // FCM トークン取得時に Service Worker が登録されていない場合は所定回数リトライ
      const { name, message } = error;
      if (name && name === 'AbortError' && message && message.includes("no active Service Worker")) {
        if (retry < BaseWebPushService.INITIALIZATION_RETRIES) {
          console.log(`${this.constructor.name} retry initialize(${retry + 1})`);
          this.initializing = false;
          await this.initialize(retry + 1);
        }
      } else {
        console.error('Unable to get messaging token.', error);
      }
    } finally {
      this.initializing = false;
    }
  }

  async updateNotification(
    notificationId: number,
    schedule: Schedule,
    title: string,
    body: string,
    params?: any
  ): Promise<void> {
    try {
      this.waitForToken();

      let triggerAt: number, scheduleOption: object | undefined = undefined;
      if (schedule.at !== undefined) {
        triggerAt = schedule.at.getTime();
      } else if (schedule.on !== undefined) {
        triggerAt = this.getTriggerAt(schedule.on.hour, schedule.on.minute).getTime();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        scheduleOption = {
          hour: schedule.on.hour,
          minute: schedule.on.minute,
          timeZone: timeZone
        }
      } else {
        throw new Error('Invalid schedule');
      }

      const extra = {
        notification: {
          icon: 'assets/icons/icon-default.webp',
          requireInteraction: true
        },
        fcmOptions: {
          link: '/'
        },
        ...params
      };

      const webPush = new WebPush({
        id: notificationId,
        title: title,
        body: body,
        triggerAt: triggerAt,
        token: this.token,
        schedule: scheduleOption,
        extra: extra
      });
      console.log(`WebPushService updateNotification: triggerAt=${new Date(webPush.triggerAt)}`, webPush);

      await this.put(webPush);

      if (this.items.length === 0) {
        this.items = await this.query();
      }

      const index = this.items.findIndex(e => e.id === webPush.id);
      if (index < 0) {
        this.items.push(webPush);
      } else {
        this.items[index] = webPush;
      }
    } catch (error) {
      console.log('WebPushService: update error', error);
      throw error;
    }
  }

  private async waitForToken(): Promise<void> {
    if (!this.token) {
      console.log('WebPushService token is none');

      let retry = 0;
      while (!this.token && retry < BaseWebPushService.UPDATE_NOTIFICATION_RETRIES) {
        console.log('WebPushService wait for token', retry);
        await new Promise(resolve => setTimeout(resolve, BaseWebPushService.UPDATE_NOTIFICATION_RETRY_INTERVAL));
        retry++;
      }

      if (!this.token) {
        throw new Error('Unable to get messaging token.');
      }
    }
  }

  private getTriggerAt(hour: number, minute: number): Date {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute);
    if (date < now) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  }

  async deleteNotification(notificationId: number): Promise<void> {
    console.log(`WebPushService deleteNotification: id = ${notificationId}`);
    await this.delete(notificationId);
    this.items = this.items.filter(item => item.id !== notificationId);
  }

  async deleteAllNotification(): Promise<void> {
    console.log('WebPushService deleteAllNotification');
    const tasks = this.items.map(async (item) => this.delete(item.id));
    await Promise.all(tasks);
    this.items = [];
  }

  async getPending(): Promise<WebPush[]> {
    if (this.items.length > 0) {
      console.log('WebPushService: items is loaded');
      return this.items;
    }

    this.items = await this.query();
    console.log('WebPushService getPending', this.items);
    return this.items;
  }

  protected abstract query(): Promise<WebPush[]>;

  protected abstract put(webPush: WebPush): Promise<void>;

  protected abstract delete(id: number): Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class WebPushService extends BaseWebPushService {
  public static readonly UNIQUE_ID_KEY = 'WebPushService.uniqueIdKey';
  protected readonly table = environment.aws.resourceNamePrefix + '-WebPush';

  constructor(
    private dbServ: DynamoDBService,
    private storage: Storage,
    messaging: Messaging,
    platform: Platform
  ) {
    super(messaging, platform);
  }

  private uniqueId: string | null = null;

  private async getUniqueId(): Promise<string> {
    if (!this.uniqueId) {
      this.uniqueId = await this.storage.get(WebPushService.UNIQUE_ID_KEY);
      if (!this.uniqueId) {
        this.uniqueId = uuidv4();
        await this.storage.set(WebPushService.UNIQUE_ID_KEY, this.uniqueId);
      }
    }
    return this.uniqueId;
  }

  private async getNotificationUniqueId(id: number): Promise<string> {
    await this.getUniqueId();
    return `${this.uniqueId}:${id}`;
  }

  protected async query(): Promise<WebPush[]> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        KeyConditionExpression: '#userId = :userId',
        ExpressionAttributeNames: {
          '#userId': 'userId',
        },
        ExpressionAttributeValues: {
          ':userId': credentials.identityId,
        },
        ProjectionExpression: 'id, title, body, triggerAt',
      };
      const result = await doc.query(params).promise();
      const items = result.Items;

      const uniqueId = await this.getUniqueId();
      return items?.filter((item: any) => item?.id.startsWith(`${uniqueId}:`))
        .map((item: any) => {
          item.id = parseInt(item.id.split(':')[1], 10);
          return new WebPush(item);
        }) || [];
    } catch (error) {
      console.log('WebPushService: query error', error);
      throw error;
    }
  }

  protected async put(webPush: WebPush): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const notificationUniqueId = await this.getNotificationUniqueId(webPush.id);
      const item: any = webPush.toJSON();
      item.id = notificationUniqueId;
      item.userId = credentials.identityId;
      const params = {
        TableName: this.table,
        Item: item,
      };
      await doc.put(params).promise();
    } catch (error) {
      console.log('WebPushService: put error', error);
      throw error;
    }
  }

  protected async delete(id: number): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const notificationUniqueId = await this.getNotificationUniqueId(id);
      const params = {
        TableName: this.table,
        Key: {
          userId: credentials.identityId,
          id: notificationUniqueId,
        },
      };
      await doc.delete(params).promise();
    } catch (error) {
      console.log('WebPushService: delete error', error);
      throw error;
    }
  }
}
