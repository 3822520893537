import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { RegistrationGuard } from './guards/registration.guard';
import { PreSurveyGuard } from './guards/pre-survey.guard';
import { ConsultationDetailPage } from './pages/consultation/consultation-detail/consultation-detail.page';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.routes').then( m => m.routes)
  },
  {
    path: 'auth',
    loadComponent: () => import('./pages/auth/auth.page').then( m => m.AuthPage),
  },
  {
    path: 'registration',
    loadComponent: () => import('./pages/registration/registration.page').then( m => m.RegistrationPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'pre-survey',
    loadComponent: () => import('./pages/pre-survey/pre-survey.page').then( m => m.PreSurveyPage),
    canActivate: [AuthGuard, RegistrationGuard]
  },
  {
    path: 'self-check/detail',
    loadComponent: () => import('./pages/self-check/self-check-detail/self-check-detail.page').then( m => m.SelfCheckDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'consultation/detail',
    loadComponent: () => import('./pages/consultation/consultation-detail/consultation-detail.page').then( m => m.ConsultationDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard],
    canDeactivate: [(component: ConsultationDetailPage) => component.canDeactivate()],
  },
  {
    path: 'lesson/part',
    loadComponent: () => import('./pages/lesson/lesson-part/lesson-part.page').then( m => m.LessonPartPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'lesson/finish',
    loadComponent: () => import('./pages/lesson/lesson-finish/lesson-finish.page').then( m => m.LessonFinishPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'sm-worksheet/detail',
    loadComponent: () => import('./pages/worksheet/sm-worksheet/sm-worksheet-detail/sm-worksheet-detail.page').then( m => m.SMWorksheetDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'sm-worksheet/edit',
    loadComponent: () => import('./pages/worksheet/sm-worksheet/sm-worksheet-edit/sm-worksheet-edit.page').then( m => m.SMWorksheetEditPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'sm-worksheet/list',
    loadComponent: () => import('./pages/worksheet/sm-worksheet/sm-worksheet.page').then( m => m.SMWorksheetPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'cr-worksheet/detail',
    loadComponent: () => import('./pages/worksheet/cr-worksheet/cr-worksheet-detail/cr-worksheet-detail.page').then( m => m.CRWorksheetDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'cr-worksheet/lesson',
    loadComponent: () => import('./pages/worksheet/cr-worksheet/cr-worksheet-lesson/cr-worksheet-lesson.page').then( m => m.CRWorksheetLessonPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ba-worksheet/detail',
    loadComponent: () => import('./pages/worksheet/ba-worksheet/ba-worksheet-detail/ba-worksheet-detail.page').then( m => m.BAWorksheetDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ba-worksheet/edit',
    loadComponent: () => import('./pages/worksheet/ba-worksheet/ba-worksheet-edit/ba-worksheet-edit.page').then( m => m.BAWorksheetEditPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ba-worksheet/mission',
    loadComponent: () => import('./pages/worksheet/ba-worksheet/ba-worksheet-mission/ba-worksheet-mission.page').then( m => m.BAWorksheetMissionPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ba-worksheet/add',
    loadComponent: () => import('./pages/worksheet/ba-worksheet/ba-worksheet-detail/ba-worksheet-add/ba-worksheet-add.page').then( m => m.BAWorksheetAddPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ba-worksheet/result',
    loadComponent: () => import('./pages/worksheet/ba-worksheet/ba-worksheet-detail/ba-worksheet-result/ba-worksheet-result.page').then( m => m.BAWorksheetResultPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ba-worksheet/marathon',
    loadComponent: () => import('./pages/worksheet/ba-worksheet/ba-worksheet-marathon/ba-worksheet-marathon.page').then( m => m.BAWorksheetMarathonPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ps-worksheet/detail',
    loadComponent: () => import('./pages/worksheet/ps-worksheet/ps-worksheet-detail/ps-worksheet-detail.page').then( m => m.PSWorksheetDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ps-worksheet/edit',
    loadComponent: () => import('./pages/worksheet/ps-worksheet/ps-worksheet-edit/ps-worksheet-edit.page').then( m => m.PSWorksheetEditPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ps-worksheet/merits',
    loadComponent: () => import('./pages/worksheet/ps-worksheet/ps-worksheet-merits/ps-worksheet-merits.page').then( m => m.PSWorksheetMeritsPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'ps-worksheet/finish',
    loadComponent: () => import('./pages/worksheet/ps-worksheet/ps-worksheet-finish/ps-worksheet-finish.page').then( m => m.PSWorksheetFinishPage)
  },
  {
    path: 'at-worksheet/lesson',
    loadComponent: () => import('./pages/worksheet/at-worksheet/at-worksheet-lesson/at-worksheet-lesson.page').then( m => m.ATWorksheetLessonPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'bi-worksheet/detail',
    loadComponent: () => import('./pages/worksheet/bi-worksheet/bi-worksheet-detail/bi-worksheet-detail.page').then( m => m.BIWorksheetDetailPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'settings',
    loadComponent: () => import('./pages/settings/settings.page').then( m => m.SettingsPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'settings/avatar',
    loadComponent: () => import('./pages/settings/settings.page').then( m => m.SettingsPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'settings/password',
    loadComponent: () => import('./pages/settings/settings.page').then( m => m.SettingsPage),
    canActivate: [AuthGuard, RegistrationGuard, PreSurveyGuard]
  },
  {
    path: 'settings/email-change',
    loadComponent: () => import('./pages/settings/email-change/email-change.page').then( m => m.EmailChangePage),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/developer',
    loadComponent: () => import('./pages/settings/developer/developer.page').then( m => m.DeveloperPage)
  },
  {
    path: 'settings/license',
    loadComponent: () => import('./pages/settings/license/license.page').then( m => m.LicensePage)
  },
];
