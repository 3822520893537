import { Worksheet } from './worksheet';

export class BAMarathon extends Worksheet {
  accomplishment!: number;
  delight!: number;
  bonus!: number;
  started!: boolean;
  done!: boolean;

  constructor(fields?: any) {
    super(fields);

    if (!this.accomplishment) this.accomplishment = 0;
    if (!this.delight) this.delight = 0;
    if (!this.bonus) this.bonus = 0;
    if (!this.started) this.started = false;
    if (!this.done) this.done = false;
  }

  override toJSON(): any {
    let json: any = super.toJSON();

    if (this.accomplishment !== undefined) json.accomplishment = this.accomplishment;
    if (this.delight !== undefined) json.delight = this.delight;
    if (this.bonus !== undefined) json.bonus = this.bonus;
    if (this.started !== undefined) json.started = this.started;
    if (this.done !== undefined) json.done = this.done;

    return json;
  }

  get canSave(): boolean {
    return this.started && this.done == false;
  }

  override revert(originalData: any): void {
    super.revert(originalData);

    if (originalData.accomplishment)
      this.accomplishment = originalData.accomplishment;
    else this.accomplishment = 0;
    if (originalData.delight) this.delight = originalData.delight;
    else this.delight = 0;
    if (originalData.bonus) this.bonus = originalData.bonus;
    else this.bonus = 0;
    if (originalData.started) this.started = originalData.started;
    else this.started = false;
    if (originalData.done) this.done = originalData.done;
    else this.done = false;
  }
}
