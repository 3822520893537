import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { BAWorksheet } from '../../../models/ba-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from '../../notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class BAWorksheetService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-BAWorksheet';

  constructor(
    dbServ: DynamoDBService,
    private notificationsServ: NotificationsService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): BAWorksheet {
    return item ? new BAWorksheet(item) : new BAWorksheet();
  }

  override get indexName(): string {
    return 'DateSorted';
  }

  override async add(worksheet: BAWorksheet): Promise<any> {
    await super.add(worksheet);
    if (worksheet.alarm) {
      return this.notificationsServ.updateBANotification(worksheet);
    }
  }

  override async update(worksheet: BAWorksheet): Promise<any> {
    await super.update(worksheet);
    if (worksheet.alarm) {
      return this.notificationsServ.updateBANotification(worksheet);
    } else {
      return this.notificationsServ.deleteWorksheetNotification(worksheet);
    }
  }

  override async delete(worksheet: BAWorksheet): Promise<any> {
    await super.delete(worksheet);
    return this.notificationsServ.deleteWorksheetNotification(worksheet);
  }
}
