import { Worksheet } from './worksheet';

export class SMWorksheet extends Worksheet {
  trigger!: string;
  feeling!: string;
  feelingLevel!: number;
  thought!: string;
  behavior!: string;
  response!: string;
  date!: Date;
  sample!: boolean;

  constructor(fields?: any) {
    super(fields);

    if (typeof this.date === 'string') {
      this.date = new Date(this.date);
    }

    if (!this.trigger) this.trigger = '';
    if (!this.feeling) this.feeling = '';
    if (!this.feelingLevel) this.feelingLevel = 0;
    if (!this.thought) this.thought = '';
    if (!this.behavior) this.behavior = '';
    if (!this.response) this.response = '';
    if (!this.sample) this.sample = false;
  }

  override toJSON() {
    let json: any = super.toJSON();

    if (this.trigger) json.trigger = this.trigger;
    if (this.feeling) json.feeling = this.feeling;
    if (this.feelingLevel) json.feelingLevel = this.feelingLevel;
    if (this.thought) json.thought = this.thought;
    if (this.behavior) json.behavior = this.behavior;
    if (this.response) json.response = this.response;
    if (this.date) json.date = this.date.toISOString();

    return json;
  }

  isSameDate(date: Date) {
    let format = 'YYYY-MM-DD';
    let dateString: string = this.formatDate(date, format);
    return this.formatDate(this.date, format) == dateString;
  }

  get isCompletion(): boolean {
    return (
      this.trigger.length > 0 &&
      this.feeling.length > 0 &&
      this.feelingLevel > 0 &&
      this.thought.length > 0 &&
      this.behavior.length > 0 &&
      this.response.length > 0 &&
      this.date != null
    );
  }

  override get isMaking(): boolean {
    return !this.isCompletion || this.sample;
  }

  get canSave(): boolean {
    return (
      this.trigger.length > 0 ||
      this.feeling.length > 0 ||
      this.feelingLevel > 0 ||
      this.thought.length > 0 ||
      this.behavior.length > 0 ||
      this.response.length > 0
    ) && this.date != null;
  }

  get displayFeelingString(): string {
    let feelingString = '';
    switch (this.feeling) {
      case 'angry':
        feelingString = '怒りやｲﾗｲﾗ';
        break;
      case 'happy':
        feelingString = '喜びや楽しみ';
        break;
      case 'anxious':
        feelingString = '不安や心配';
        break;
      case 'sad':
        feelingString = '憂うつや悲しい';
        break;
      default:
    }
    return feelingString;
  }

  get feelingIcons(): string[] {
    const icons: string[] = [];
    for (let i = 0; i < 5; i++) {
      if (i < this.feelingLevel) {
        icons.push(this.feeling);
      } else {
        icons.push('none');
      }
    }
    return icons;
  }

  override revert(originalData: any): void {
    super.revert(originalData);
    if (originalData.trigger) this.trigger = originalData.trigger;
    else this.trigger = '';
    if (originalData.feeling) this.feeling = originalData.feeling;
    else this.feeling = '';
    if (originalData.feelingLevel)
      this.feelingLevel = originalData.feelingLevel;
    else this.feelingLevel = 0;
    if (originalData.thought) this.thought = originalData.thought;
    else this.thought = '';
    if (originalData.behavior) this.behavior = originalData.behavior;
    else this.behavior = '';
    if (originalData.response) this.response = originalData.response;
    else this.response = '';
    if (originalData.date) this.date = new Date(originalData.date);
    else this.date = new Date();
  }
}
