import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { environment } from '../../../environments/environment';
import { DynamoDBService } from '../database/dynamodb.service';
import { PreSurvey } from '../../models/pre-survey';

export abstract class BasePreSurveyService {
  abstract put(preSurvey: PreSurvey): Promise<void>;
  abstract delete(): Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class PreSurveyService extends BasePreSurveyService {
  protected readonly table = environment.aws.resourceNamePrefix + '-PreSurvey';

  constructor(private dbServ: DynamoDBService) {
    super();
  }

  async put(preSurvey: PreSurvey): Promise<any> {
    const credentials = await Auth.currentCredentials();
    const doc = await this.dbServ.getDocumentClient(credentials);

    preSurvey.answeredAt = new Date();
    const item: any = preSurvey.toJSON();
    item.userId = credentials.identityId;

    const result = await doc.put({
      TableName: this.table,
      Item: item
    }).promise();

    return result;
  }

  async delete(): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        Key: {
          userId: credentials.identityId,
        },
      };
      await doc.delete(params).promise();  
    } catch (error) {
      console.log(`${this.constructor.name}: delete error`, error);
      throw error;
    }
  }
}
