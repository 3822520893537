import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Platform } from '@ionic/angular';

import { environment } from '../../../environments/environment';
import { Lesson, RequireFinishedWorksheet } from '../../models/lesson';
import { LessonScriptService } from './lesson-script.service';
import { ModuleService } from '../module/module.service';
import { DynamoDBService } from '../database/dynamodb.service';

export abstract class BaseLessonService {
  protected items: Lesson[] = [];
  protected createdAtProperty!: Date;
  protected updatedAtProperty!: Date;

  constructor(
    protected lessonScriptServ: LessonScriptService,
    protected moduleServ: ModuleService,
    protected platform: Platform
  ) {}

  get createdAt(): Date {
    return this.createdAtProperty;
  }

  get updatedAt(): Date {
    return this.updatedAtProperty;
  }

  get list(): Lesson[] {
    return this.items;
  }

  get last(): Lesson {
    return this.items[this.items.length - 1];
  }

  get module(): ModuleService {
    return this.moduleServ;
  }

  get currentLesson(): Lesson {
    const openedLessons = this.items.filter((v) => v.locked == false);
    const lastOpenedLesson = openedLessons[openedLessons.length - 1];
    if (openedLessons.length < 2 || lastOpenedLesson.firstLessonPartStartAt) {
      return lastOpenedLesson;
    } else {
      const nextToLastOpenedLesson = openedLessons[openedLessons.length - 2];
      return nextToLastOpenedLesson;
    }
  }

  get isSetupFinished(): boolean {
    // モジュールとレッスンの状態が一致しているか検査
    return this.moduleServ.list.every((v: { assigned: any; name: any; }) => {
      if (v.assigned) {
        return this.get(v.name) !== undefined;
      }
      return true;
    });
  }

  get scriptService(): LessonScriptService {
    return this.lessonScriptServ;
  }

  get(key: string): Lesson | undefined {
    return this.items.find((v) => v.lessonId == key);
  }

  abstract load(force?: boolean): Promise<any>;

  abstract save(): Promise<any>;

  abstract delete(): Promise<any>;

  add(key: string) {
    if (this.items.find((v) => v.lessonId == key)) return;
    const lessonScript = this.lessonScriptServ.getLesson(key);
    console.log('LessonService add:', lessonScript);
    const lesson = new Lesson(lessonScript);
    this.setLessonPartStatus(lesson);
    this.items.push(lesson);
  }

  protected setLessonPartStatus(lesson: Lesson): void {
    lesson.lessonParts.forEach((part) => {
      if (part.enabledCondition) {
        if (part.enabledCondition == 'IOS_ONLY') {
          if (!this.platform.is('ios')) {
            part.disabled = true;
          }
        } else if (part.enabledCondition == 'ANDROID_ONLY') {
          if (!this.platform.is('android')) {
            part.disabled = true;
          }
        } else if (part.enabledCondition == 'SM_ENABLED') {
          part.disabled = this.moduleServ.isAssigned('sm');
        }
      }
    });
  }

  sort() {
    let order = this.moduleServ.order;
    if (order.length == 0) return;
    this.items.sort((a, b) => {
      let ao = order.findIndex((v: string) => v == a.lessonId);
      let bo = order.findIndex((v: string) => v == b.lessonId);
      if (ao < 0) ao = order.length;
      if (bo < 0) bo = order.length;
      return ao - bo;
    });
  }

  updateModule(): boolean {
    let updated = false;
    this.items.forEach((item) => {
      item.lessonParts.forEach((part) => {
        if (part.finishedAt) {
          if (part.enableWorksheet) {
            updated = this.moduleServ.enableWorksheet(part.enableWorksheet, part.finishedAt);
            if (updated) {
              console.log('LessonService updateModule: enableWorksheet:', part.enableWorksheet);
            }
          }
          if (part.enableWorksheetItem) {
            const wi = part.enableWorksheetItem;
            updated = this.moduleServ.enableWorksheetItem(wi.name, wi.items);
            if (updated) {
              console.log('LessonService updateModule: enableWorksheetItem:', part.enableWorksheetItem);
            }
          }
        }
      });
    });
    return updated;
  }

  setup() {
    this.moduleServ.list.forEach((v: { assigned: any; name: string; }) => {
      if (v.assigned) {
        this.add(v.name);
      }
    });
    this.sort();
  }

  release(): void {
    this.items = [];
  }
}

@Injectable({
  providedIn: 'root'
})
export class LessonService extends BaseLessonService {
  protected readonly table = environment.aws.resourceNamePrefix + '-Lesson';

  constructor(
    lessonScriptServ: LessonScriptService,
    moduleServ: ModuleService,
    platform: Platform,
    private dbServ: DynamoDBService
  ) {
    super(lessonScriptServ, moduleServ, platform);
  }

  async load(force?: boolean): Promise<any> {
    if (!force && this.items.length > 0) {
      console.log('LessonService: lessons is loaded');
      return this.items;
    }

    try {
      if (this.module.code == undefined) {
        throw new Error('LessonService: module code is none');
      }
      await this.lessonScriptServ.loadLessons(this.module.code);

      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        Key: {
          userId: credentials.identityId
        }
      };
      const result = await doc.get(params).promise();
      const item = result.Item;
      if (item) {
        this.createdAtProperty = new Date(item['createdAt']);
        this.updatedAtProperty = new Date(item['updatedAt']);
        this.items = item['lessons'].map((v: any) => new Lesson(v));
        this.items.forEach((lesson) => {
          let data = this.lessonScriptServ.getLesson(lesson.lessonId);
          if (data) {
            lesson.title = data.title;
            lesson.requireFinishedWorksheet = data.requireFinishedWorksheet as RequireFinishedWorksheet;
            lesson.displayRestriction = data.displayRestriction;
            lesson.lessonParts.forEach((part) => {
              Object.assign(part, data.lessonParts[part.partIndex]);
            });
          }
          this.setLessonPartStatus(lesson);
        });

        this.sort();
        this.updateModule();
        console.log('LessonService: load', this.items);
        return this.items;
      } else {
        //1件もない場合に新規作成
        console.log(`LessonService: init ${this.module.code}`);
        this.createdAtProperty = new Date();
        this.items = [];
        this.setup();
        return this.save();
      }

    } catch(error) {
      console.log('LessonService: load error', error);
      throw error;
    };
  }

  async save(): Promise<any> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);
  
      this.updatedAtProperty = new Date();
      const params = {
        TableName: this.table,
        Item: {
          userId: credentials.identityId,
          createdAt: this.createdAtProperty.toISOString(),
          updatedAt: this.updatedAtProperty.toISOString(),
          lessons: this.items.map((v) => v.toJSON()),
        },
      };
      return await doc.put(params).promise();
    } catch(error) {
      console.log('LessonService: save error', error);
      throw error;
    }
  }

  async delete(): Promise<any> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        Key: {
          userId: credentials.identityId
        }
      };
      await doc.delete(params).promise();
      this.items = [];
    } catch(error) {
      console.log('LessonService: delete error', error);
      throw error;
    }
  }
}
