import { APP_INITIALIZER, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Amplify } from '@aws-amplify/core';
import { provideServiceWorker } from '@angular/service-worker';
import { initializeApp as initializeFirebaseApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import awsconfig from './aws-exports';

import { AppService } from './app/app.service';
import { AppConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
  disableConsole();
}

Amplify.configure(awsconfig);

export function initializeApp(appService: AppService) {
  return () => appService.initializeApp();
}

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom([
      IonicModule.forRoot({
        backButtonText: '',
        innerHTMLTemplatesEnabled: true
      }),
      IonicStorageModule.forRoot(),
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      provideFirebaseApp(() => initializeFirebaseApp(environment.firebase)),
      provideMessaging(() => getMessaging())
    ]),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: enableServiceWorker(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
});

function disableConsole() {
  if (console) {
    console.log = () => { };
    console.debug = () => { };
    console.warn = () => { };
    console.info = () => { };  
  }
}

function enableServiceWorker() {
  if (Capacitor.isNativePlatform()) {
    return false;
  }
  return !isDevMode() || AppConfig.DEBUG_SERVICE_WORKER_REGISTRATION;
}