export abstract class Survey {
  createdAt: Date | undefined = undefined;
  answeredAt!: Date;
  answers!: any[] | any;
  PHQ9Point: number | undefined;
  GAD7Point: number | undefined;
  CBTPoint: any;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }

    if (!this.createdAt) {
      this.createdAt = new Date();
    }

    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }

    if (typeof this.answeredAt === 'string') {
      this.answeredAt = new Date(this.answeredAt);
    }

    if (this.answers && !Array.isArray(this.answers)) {
      this.toArrayAnswers();
    }
  }

  private toArrayAnswers() {
    if (!this.answers) {
      return;
    }

    const arrayAnswres: any[] = [];
    Object.keys(this.answers).forEach((category) => {
      const items = this.answers[category];
      items.forEach((item: { itemId: any; answer: any; }) => {
        arrayAnswres.push({
          itemId: item.itemId,
          category: category,
          answer: item.answer
        });
      });
    });
    this.answers = arrayAnswres;
  }

  getAnswers(category:string): any[] {
    return this.answers ? this.answers.filter((e: { category: string; }) => e.category === category) : [];
  }

  toJSON(): any {
    const json: any = {};
    if (this.createdAt) json.createdAt = this.createdAt.toISOString();
    if (this.answeredAt) json.answeredAt = this.answeredAt.toISOString();

    json.answers = this.organizedAnswers;
    json.FCRI_SFPoint = this.getFCRI_SFPoint(json.answers);
    json.PHQ9Point = this.getPHQ9Point(json.answers);
    json.GAD7Point = this.getGAD7Point(json.answers);
    json.CBTPoint = this.getCBTPoint(json.answers);

    if (json.answers?.UCLA_LS3_J_SF10_SF3) {
      this.setPoint(json.answers.UCLA_LS3_J_SF10_SF3);
    }
    if (json.answers?.BRS_J) {
      this.setPoint(json.answers.BRS_J);
    }

    return json;
  }

  protected getFCRI_SFPoint(answers: any): any {
    if (!answers || !answers.FCRI_SF) {
      return undefined;
    }
    return answers.FCRI_SF.reduce((acc: number, e: { answer: any; itemId: any; point: any; }) => {
      let point = e.answer;
      const item = this.answers.find((answer: { itemId: any; }) => answer.itemId === e.itemId);
      if (item && item.params) {
        const inversion = item.params.inversion;
        if (inversion) {
          point = inversion - point;
        }
      }
      e.point = point;
      return acc + point;
    }, 0);
  }

  protected getPHQ9Point(answers: any): number | undefined {
    if (!answers || !answers.PHQ9) {
      return undefined;
    }
    return answers.PHQ9.reduce((acc: any, e: { answer: any; }) => acc + e.answer, 0);
  }

  protected getGAD7Point(answers: any): number | undefined  {
    if (!answers || !answers.GAD7) {
      return undefined;
    }
    return answers.GAD7.reduce((acc: any, e: { answer: any; }) => acc + e.answer, 0);
  }

  protected getCBTPoint(answers: any): any {
    if (!answers || !answers.CBT) {
      return undefined;
    }
    const CBTPoint: any = {};
    answers.CBT.forEach((e: { answer: any; itemId: any; point: any; }) => {
      let point = e.answer;
      const item = this.answers.find((answer: { itemId: any; }) => answer.itemId === e.itemId);
      if (item && item.params) {
        const inversion = item.params.inversion;
        if (inversion) {
          point = inversion - point;
        }

        const type = item.params.type;
        if (type) {
          if (!CBTPoint[type]) {
            CBTPoint[type] = point;
          } else {
            CBTPoint[type] += point;
          }
        }
      }
      e.point = point;
    });
    return CBTPoint;
  }

  protected setPoint(answers: any): any {
    return answers.forEach((e: { answer: any; itemId: any; point: any; }) => {
      let point = e.answer;
      const item = this.answers.find((answer: { itemId: any; }) => answer.itemId === e.itemId);
      if (item && item.params) {
        const inversion = item.params.inversion;
        if (inversion) {
          point = inversion - point;
        }
      }
      e.point = point;
    });
  }

  get organizedAnswers(): any {
    if (!this.answers) {
      return undefined;
    }

    const organizedAnswres: any = {};
    this.answers.forEach((e: { category: any; itemId: any; answer: any; }) => {
      const category = e.category;
      if (organizedAnswres[category]) {
        organizedAnswres[category].push({ itemId: e.itemId, answer: e.answer });
      } else {
        organizedAnswres[category] = [{ itemId: e.itemId, answer: e.answer }];
      }
    });

    return organizedAnswres;
  }
}