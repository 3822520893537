import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LessonScriptService {
  private static readonly LESSON_ASSETS_URL = `assets/lesson`;

  private code!: string;
  private lessons: any[];
  private parts: any;

  constructor(
    private http: HttpClient
  ) {
    this.lessons = [];
    this.parts = {};
  }

  getLessons(): any[] {
    return this.lessons;
  }

  getLesson(key: string): any {
    return this.lessons.find((v) => v.lessonId == key);
  }

  getParts(key: string): any {
    return this.parts[key];
  }

  async loadLessons(code: string): Promise<any[]> {
    if (this.code == code && this.lessons != null) {
      console.log('LessonData: lessons is loaded.');
      return Promise.resolve(this.lessons);
    }

    this.code = code;
    const url = `${LessonScriptService.LESSON_ASSETS_URL}/assignment/${this.code}.json`;
    this.lessons = await firstValueFrom(this.http.get(url)) as any[];
    return this.lessons;
  }

  async loadPart(key: string): Promise<any> {
    if (this.parts[key]) {
      return this.parts[key];
    }

    const url = `${LessonScriptService.LESSON_ASSETS_URL}/${key}.json`;
    this.parts[key] = await firstValueFrom(this.http.get(url));
    return this.parts[key];
  }
}
