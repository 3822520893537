// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  aws: {
    region: 'ap-northeast-1',
    resourceNamePrefix: 'smileaya-dev',
    customUserAgent: 'Rezitore smileaya v1.0.0'
  },
  firebase: {
    apiKey: "AIzaSyBMNpNukPiu-QwKcHbnkKeLA5IYMQZCJn8",
    authDomain: "smileaya-dev.firebaseapp.com",
    projectId: "smileaya-dev",
    storageBucket: "smileaya-dev.appspot.com",
    messagingSenderId: "767550632374",
    appId: "1:767550632374:web:92a592c9927812a2b55117"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
