import { v4 as uuid } from 'uuid';

export abstract class Worksheet {
  worksheetId!: string;
  createdAt!: Date;
  updatedAt!: Date;
  deletedAt: Date | undefined = undefined;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }

    if (!this.worksheetId) {
      this.worksheetId = uuid();
    }

    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }

    if (typeof this.updatedAt === 'string') {
      this.updatedAt = new Date(this.updatedAt);
    }

    if (typeof this.deletedAt === 'string') {
      this.deletedAt = new Date(this.deletedAt);
    }

    if (!this.createdAt) {
      this.createdAt = new Date();
    }

    if (!this.updatedAt) {
      this.updatedAt = new Date();
    }
  }

  toJSON() {
    const json: any = {
      worksheetId: this.worksheetId
    };

    if (this.createdAt) json.createdAt = this.createdAt.toISOString();
    if (this.deletedAt) json.deletedAt = this.deletedAt.toISOString();
    if (this.updatedAt) json.updatedAt = this.updatedAt.toISOString();

    return json;
  }

  equalsJson(other: any) {
    const self = this.toJSON();

    if (JSON.stringify(self) == JSON.stringify(other)) return true;

    for (let key in self) {
      if (!other[key] || self[key] != other[key]) {
        return false;
      }
    }

    for (let key in other) {
      if (!self[key]) {
        return false;
      }
    }

    return true;
  }

  formatDate(date: Date, format: string) {
    if (!format) {
      format = 'YYYY-MM-DD hh:mm:ss.SSS';
    }

    format = format.replace(/YYYY/g, date.getFullYear().toString());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));

    const matchs =format.match(/S/g);
    if (matchs) {
      const milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      const length = matchs.length;
      for (let i = 0; i < length; i++) {
        format = format.replace(/S/, milliSeconds.substring(i, i + 1));
      }
    }
    return format;
  }

  get isMaking(): boolean {
    return false;
  }

  revertDate(originalData: any): void {
    if (!originalData) {
      return;
    }
    if (originalData.createdAt)
      this.createdAt = new Date(originalData.createdAt);
    if (originalData.updatedAt)
      this.updatedAt = new Date(originalData.updatedAt);
    if (originalData.deletedAt)
      this.deletedAt = new Date(originalData.deletedAt);
    else delete this.deletedAt;
  }

  // toJSONで生成したJSONで復元する
  revert(originalData: any): void {
    if (!originalData) {
      return;
    }
    if (originalData.worksheetId) this.worksheetId = originalData.worksheetId;
    this.revertDate(originalData);
  }

  copy(): Worksheet | undefined {
    return undefined;
  }

  abstract get canSave(): boolean;
}
