import { AppConfig } from '../app.config';
import { Worksheet } from './worksheet';

export class BIWorksheet extends Worksheet {
  static readonly DIARY_COUNT_FOR_TITRATION = 5;
  static readonly TITRATION_EXPIRATION = 7;
  static readonly ADJUST_TIME_TO_GET_INTO_BED = 15;
  static readonly ADJUST_RECOMMENDED_TIME_SPENT_IN_BED = 30;
  static readonly UNIT_RECOMMENDED_TIME_SPENT_IN_BED = 15;

  startDate!: Date;
  diaries!: BIWorksheetDiary[];

  constructor(fields?: any) {
    super(fields);

    if (fields) {
      if (fields.startDate) {
        this.startDate = new Date(fields.startDate);
      }

      if (fields.diaries && fields.diaries.length > 0) {
        this.diaries = (fields.diaries as any[]).map(e => new BIWorksheetDiary(e));
      }
    } else {
      const date = new Date(AppConfig.now());
      date.setDate(date.getDate() - date.getDay());
      this.startDate = date;
    }

    if (!this.diaries) {
      const date = this.startDate;
      this.diaries = Array(7).fill(0).map((_, i)=> {
        return new BIWorksheetDiary({
          date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + i)
        });
      });
    }
  }

  get canSave(): boolean {
    return this.startDate && this.diaries.some(e => e.valid);
  }

  override get isMaking(): boolean {
    return this.diaries.some(e => e.valid) === false;
  }

  get endDate(): Date {
    const date = new Date(this.startDate);
    date.setDate(this.startDate.getDate() + 6);
    return date;
  }

  get isThisWeek(): boolean {
    const now = new Date(AppConfig.now());
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    return this.startDate.getTime() === date.getTime();
  }

  get isLastWeek(): boolean {
    const now = new Date(AppConfig.now());
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7);
    return this.startDate.getTime() === date.getTime();
  }

  get isEmpty(): boolean {
    return this.validDiaries.length === 0;
  }

  get validDiaries(): BIWorksheetDiary[] {
    return this.diaries.filter(e => e.valid);
  }

  get minTimeToGetIntoBed(): number | undefined {
    const validDiaries = this.validDiaries;
    if (validDiaries.length === 0) {
      return undefined;
    }
    return validDiaries.reduce((a, v) => {
      const time = v.timeToGetIntoBed;
      return time !== undefined ? Math.min(a, time) : a;
    }, 24 * 60);
  }

  get maxTimeToGetOutOfBed(): number | undefined {
    const validDiaries = this.validDiaries;
    if (validDiaries.length === 0) {
      return undefined;
    }
    return validDiaries.reduce((a, v) => {
      const time = v.timeToGetOutOfBed;
      return time !== undefined ? Math.max(a, time) : a;
    }, 0);
  }

  get headObjectiveDiaries(): BIWorksheetDiary[] {
    return this.diaries.filter(e => e.valid && e.objective && e.objective.valid).reduce((a, v) => {
      if (!a.find(vv => v.objective.isSame(vv.objective))) {
        a.push(v);
      }
      return a;
    }, [] as BIWorksheetDiary[]);
  }

  get objectiveItems(): BIWorksheetObjectiveItem[] {
    return this.diaries.filter(e => e.valid).reduce((a, v) => {
      if (v.objective && v.objective.valid) {
        v.objective.items.forEach(vv => {
          if (!a.find(vvv => vvv.key === vv.key)) {
            a.push(vv);
          }
        });
      }
      return a;
    }, [] as BIWorksheetObjectiveItem[]);
  }

  get prevWeek(): Date {
    const date = new Date(this.startDate);
    date.setDate(date.getDate() - 7);
    return date;
  }

  get nextWeek(): Date {
    const date = new Date(this.startDate);
    date.setDate(date.getDate() + 7);
    return date;
  }

  get isCurrentWeek(): boolean {
    const now = new Date(AppConfig.now());
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    return this.startDate.getTime() === date.getTime();
  }

  get averageSleepTime(): number {
    const diaries = this.diaries.filter(e => e.valid);
    if (diaries.length === 0) {
      return -1;
    }
    return Math.round(diaries.reduce((acc, cur) => {
      return cur.totalSleepTime !== undefined ? acc + cur.totalSleepTime : acc;
    }, 0) / diaries.length);
  }

  override toJSON(): any {
    const json: any = super.toJSON();

    if (this.startDate) {
      json.startDate = this.startDate.toISOString();
    }

    if (this.diaries) {
      json.diaries = this.diaries.map(e => e.toJSON());
    }

    return json;
  }

  override revert(originalData: any): void {
    if (!originalData) {
      return;
    }

    super.revert(originalData);

    if (originalData.startDate) {
      this.startDate = new Date(originalData.startDate);
    }

    if (originalData.diaries) {
      (originalData.diaries as any[]).forEach((e, i) => {
        if (i < this.diaries.length) {
          this.diaries[i].revert(e);
        } else {
          this.diaries[i] = new BIWorksheetDiary(e);
        }
      });
    }
  }

  isTarget(date: Date): boolean {
    return this.startDate.getTime() === date.getTime();
  }

  setObjective(objectiveSetting: BIObjectiveSetting): boolean {
    console.log("BIWorksheet.setObjective startDate: ", objectiveSetting.startDate);
    const time = objectiveSetting.startDate.getTime();
    const objectiveJson = objectiveSetting.objective.toJSON();
    let isUpdated = false;
    for (let diary of this.diaries) {
      if (time <= diary.date.getTime()) {
        console.log("updated: ", diary.date);
        diary.objective = new BIWorksheetObjective(objectiveJson);
        isUpdated = true;
      }
    }
    return isUpdated;
  }

  getDiariesForTitration(relatedWorksheet: BIWorksheet|undefined, minStartDate: Date|undefined): BIWorksheetDiary[] {
    let diaries = this.diaries;
    if (relatedWorksheet && relatedWorksheet.diaries) {
      diaries = diaries.concat(relatedWorksheet.diaries);
    }

    const expired = BIWorksheet.titrationExpirationDate;
    const min = minStartDate && expired.getTime() < minStartDate.getTime() ? minStartDate : expired;
    const now = new Date(AppConfig.now());
    const max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    diaries = diaries.filter(e => {
      const time = e.date.getTime();
      return e.valid && min.getTime() <= time && time <= max.getTime();
    });

    diaries.sort((a, b) => a.date.getTime() - b.date.getTime());

    return diaries;
  }

  hasValidDiaryAfterDate(date:Date): boolean {
    return this.diaries.some(e => e.valid && date.getTime() <= e.date.getTime());
  }

  static get titrationExpirationDate(): Date {
    const now = new Date(AppConfig.now());
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - BIWorksheet.TITRATION_EXPIRATION);
  }

  static getRecommendedObjective(diaries: BIWorksheetDiary[]): BIWorksheetRecommendedObjective {

    // 睡眠効率/調整就床時間の決定
    const sleepEfficiency = diaries.reduce((acc, cur) => {
      return cur.sleepEfficiency !== undefined ? acc + cur.sleepEfficiency : acc;
    }, 0) / diaries.length;
    let sleepEfficiencyStatus, adjustTimeToGetIntoBed;
    if (sleepEfficiency <= BIWorksheetDiary.SLEEP_EFFICIENCY_THRESHOLD_LOWER) {
      sleepEfficiencyStatus = BIWorksheetDiary.SLEEP_EFFICIENCY_STATUS_LOWER;
      adjustTimeToGetIntoBed = BIWorksheet.ADJUST_TIME_TO_GET_INTO_BED;
    } else if (BIWorksheetDiary.SLEEP_EFFICIENCY_THRESHOLD_UPPER <= sleepEfficiency) {
      sleepEfficiencyStatus = BIWorksheetDiary.SLEEP_EFFICIENCY_STATUS_UPPER;
      adjustTimeToGetIntoBed = BIWorksheet.ADJUST_TIME_TO_GET_INTO_BED * -1;
    } else {
      sleepEfficiencyStatus = BIWorksheetDiary.SLEEP_EFFICIENCY_STATUS_JUST_RIGHT;
      adjustTimeToGetIntoBed = 0;
    }

    // 推奨の就床時間の決定
    let timeToGetIntoBed;
    const objective = diaries[diaries.length - 1].objective;
    if (objective.valid) {
      timeToGetIntoBed = (objective.timeToGetIntoBed || 0) + adjustTimeToGetIntoBed;
      const timeToGetIntoBedRemainder = timeToGetIntoBed % BIWorksheetDiary.MINUTE_UNIT;
      if (BIWorksheetDiary.MINUTE_UNIT / 2 < timeToGetIntoBedRemainder) {
        timeToGetIntoBed += BIWorksheetDiary.MINUTE_UNIT;
      }
      timeToGetIntoBed -= timeToGetIntoBedRemainder;
    } else {
      timeToGetIntoBed = undefined;
    }

    // 平均睡眠時間の決定
    let averageSleepTime = diaries.reduce((acc, cur) => {
      return cur.totalSleepTime !== undefined ? acc + cur.totalSleepTime : acc;
    }, 0) / diaries.length;
    averageSleepTime = Math.floor(averageSleepTime);

    // 推奨の寝床ですごす時間の決定
    let timeSpentInBed = averageSleepTime + BIWorksheet.ADJUST_RECOMMENDED_TIME_SPENT_IN_BED;
    const timeSpentInBedRemainder = timeSpentInBed % BIWorksheet.UNIT_RECOMMENDED_TIME_SPENT_IN_BED;
    if (BIWorksheet.UNIT_RECOMMENDED_TIME_SPENT_IN_BED / 2 < timeSpentInBedRemainder) {
      timeSpentInBed += BIWorksheet.UNIT_RECOMMENDED_TIME_SPENT_IN_BED;
    }
    timeSpentInBed -= timeSpentInBedRemainder;

    // 昼寝の有無
    const hasNap = diaries.some(e => e.naps > 0);

    return {
      sleepEfficiency: sleepEfficiency,
      sleepEfficiencyStatus: sleepEfficiencyStatus,
      timeToGetIntoBed: timeToGetIntoBed,
      averageSleepTime: averageSleepTime,
      timeSpentInBed: timeSpentInBed,
      hasNap: hasNap
    };
  }
}

export class BIObjectiveSetting extends Worksheet {
  startDate!: Date;
  objective!: BIWorksheetObjective;

  constructor(fields?: any) {
    super(fields);

    if (fields) {
      if (fields.startDate) {
        this.startDate = new Date(fields.startDate);
      }

      if (fields.objective) {
        this.objective = new BIWorksheetObjective(fields.objective);
      }
    }

    if (!this.objective) {
      this.objective = new BIWorksheetObjective();
    }
  }

  get canSave(): boolean {
    return this.startDate && this.objective && this.objective.valid;
  }

  override get isMaking(): boolean {
    return !this.canSave;
  }

  override toJSON(): any {
    const json: any = super.toJSON();

    if (this.startDate) {
      json.startDate = this.startDate.toISOString();
    }

    if (this.objective) {
      json.objective = this.objective.toJSON();
    }

    return json;
  }

  override revert(originalData: any): void {
    if (!originalData) {
      return;
    }

    super.revert(originalData);

    if (originalData.startDate) {
      this.startDate = new Date(originalData.startDate);
    }

    if (originalData.objective) {
      this.objective = new BIWorksheetObjective(originalData.objective);
    }
  }
}

/**
 * BI Worksheet Diary
 *
 * 時間単位は分
 * timeToGetIntoBed, timeToGetOutOfBed は、date からの経過時間
 */
export class BIWorksheetDiary {
  static readonly SLEEP_EFFICIENCY_NONE = 'none';
  static readonly SLEEP_EFFICIENCY_STATUS_LOWER = 'lower';
  static readonly SLEEP_EFFICIENCY_STATUS_JUST_RIGHT = 'just-right';
  static readonly SLEEP_EFFICIENCY_STATUS_UPPER = 'upper';

  static readonly SLEEP_EFFICIENCY_THRESHOLD_LOWER = 85;
  static readonly SLEEP_EFFICIENCY_THRESHOLD_UPPER = 95;

  static readonly QULIRY_GOOD = 'good';
  static readonly QULIRY_USUALLY = 'usually';
  static readonly QULIRY_BAD = 'bad';

  static readonly dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ];

  static readonly MINUTE_UNIT = 5;

  date!: Date;
  timeToGetIntoBed: number | undefined = undefined;
  timeToGetOutOfBed: number | undefined = undefined;
  preSleepTime: number = 0;
  snoozeTime: number = 0;
  quality: string = '';
  awakenings: number = 0;
  awakeningTime: number = 0;
  naps: number = 0;
  napTime: number = 0;
  objective!: BIWorksheetObjective;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }

      if (typeof this.date === 'string') {
        this.date = new Date(this.date);
      }

      if (fields.objective) {
        this.objective = new BIWorksheetObjective(fields.objective);
      }
    }

    if (!this.objective) {
      this.objective = new BIWorksheetObjective();
    }
  }

  get valid(): boolean {
    return this.timeToGetIntoBed !== undefined
      && this.timeToGetOutOfBed !== undefined
      && this.timeToGetIntoBed < this.timeToGetOutOfBed
      && 0 <= this.timeToGetOutOfBed
      && this.quality !== '';
  }

  get canEntry(): boolean {
    const now = new Date(AppConfig.now());
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return this.date.getTime() <= date.getTime();
  }

  get day(): number {
    return this.date.getDate();
  }

  get indexDay(): number {
    return this.date.getDay();
  }

  get isToday(): boolean {
    const now = new Date(AppConfig.now());
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return this.date.getTime() === date.getTime();
  }

  get dayOfWeek(): string {
    return BIWorksheetDiary.dayOfWeekStrJP[this.date.getDay()];
  }

  get sleepTime(): number | undefined {
    return this.timeToGetIntoBed !== undefined
      ? this.timeToGetIntoBed + this.preSleepTime : undefined;
  }

  get wakeupTime(): number | undefined {
    return this.timeToGetOutOfBed !== undefined
      ? this.timeToGetOutOfBed - this.snoozeTime : undefined;
  }

  get totalTimeInBed(): number | undefined {
    return this.timeToGetOutOfBed !== undefined && this.timeToGetIntoBed !== undefined
      ? this.timeToGetOutOfBed - this.timeToGetIntoBed : undefined;
  }

  get totalWakeTime(): number {
    return this.preSleepTime + this.awakeningTime + this.snoozeTime;
  }

  get totalSleepTime(): number | undefined {
    return this.totalTimeInBed !== undefined
      ? this.totalTimeInBed - this.totalWakeTime : undefined;
  }

  get sleepEfficiency(): number | undefined {
    return this.totalSleepTime !== undefined && this.totalTimeInBed !== undefined
      ? Math.round(this.totalSleepTime / this.totalTimeInBed * 100) : undefined;
  }

  get sleepEfficiencyStatus():string {
    if (!this.valid) {
      return BIWorksheetDiary.SLEEP_EFFICIENCY_NONE;
    }
    const sleepEfficiency = this.sleepEfficiency;
    if (sleepEfficiency !== undefined) {
      if (sleepEfficiency <= BIWorksheetDiary.SLEEP_EFFICIENCY_THRESHOLD_LOWER) {
        return BIWorksheetDiary.SLEEP_EFFICIENCY_STATUS_LOWER;
      } else if (BIWorksheetDiary.SLEEP_EFFICIENCY_THRESHOLD_UPPER <= sleepEfficiency) {
        return BIWorksheetDiary.SLEEP_EFFICIENCY_STATUS_UPPER;
      }  
    }
    return BIWorksheetDiary.SLEEP_EFFICIENCY_STATUS_JUST_RIGHT;
  }

  get qualityIsGood(): boolean {
    return this.quality === BIWorksheetDiary.QULIRY_GOOD;
  }

  get qualityIsUsually(): boolean {
    return this.quality === BIWorksheetDiary.QULIRY_USUALLY;
  }

  get qualityIsBad(): boolean {
    return this.quality === BIWorksheetDiary.QULIRY_BAD;
  }

  toJSON(): any {
    const json: any = {};

    if (this.date) {
      json.date = this.date.toISOString();
    }

    if (this.valid) {
      json.timeToGetIntoBed = this.timeToGetIntoBed;
      json.timeToGetOutOfBed = this.timeToGetOutOfBed;
      json.preSleepTime = this.preSleepTime;
      json.snoozeTime = this.snoozeTime;
      json.quality = this.quality;
      json.awakenings = this.awakenings;
      json.awakeningTime = this.awakeningTime;
      json.naps = this.naps;
      json.napTime = this.napTime;
    }

    if (this.objective) {
      json.objective = this.objective.toJSON();
    }

    return json;
  }

  revert(originalData: any): void {
    if (originalData.date) {
      this.date = new Date(originalData.date);
    }

    this.timeToGetIntoBed = originalData.timeToGetIntoBed;
    this.timeToGetOutOfBed = originalData.timeToGetOutOfBed;
    this.preSleepTime = (originalData.preSleepTime !== undefined) ? originalData.preSleepTime : 0;
    this.snoozeTime = (originalData.snoozeTime !== undefined) ? originalData.snoozeTime : 0;
    this.quality = (originalData.quality !== undefined) ? originalData.quality : "";
    this.awakenings = (originalData.awakenings !== undefined) ? originalData.awakenings : 0;
    this.awakeningTime = (originalData.awakeningTime !== undefined) ? originalData.awakeningTime : 0;
    this.naps = (originalData.naps !== undefined) ? originalData.naps : 0;
    this.napTime = (originalData.napTime !== undefined) ? originalData.napTime : 0;

    if (originalData.objective) {
      this.objective.revert(originalData.objective);
    }
  }

  hasObjectiveItem(key:string): boolean {
    return this.objective && this.objective.items.find(e => e.key === key) !== undefined;
  }

  objectiveItemValue(key:string): boolean {
    const item = this.objective.items.find(e => e.key === key);
    return item !== undefined && item.value !== undefined && item.value;
  }
}

export class BIWorksheetObjective {
  static readonly MAX_ITEM_COUNT = 2;

  timeToGetIntoBed: number | undefined = undefined;
  timeToGetOutOfBed: number | undefined = undefined;
  items: BIWorksheetObjectiveItem[] = [];
  createdAt!: Date;

  constructor(fields?: any) {
    if (fields) {
      if (fields.timeToGetIntoBed !== undefined) {
        this.timeToGetIntoBed = fields.timeToGetIntoBed;
      }

      if (fields.timeToGetOutOfBed !== undefined) {
        this.timeToGetOutOfBed = fields.timeToGetOutOfBed;
      }

      if (fields.items && fields.items.length > 0) {
        this.items = fields.items.map((e: any) => new BIWorksheetObjectiveItem(e));
      }

      if (fields.createdAt) {
        this.createdAt = new Date(fields.createdAt);
      }
    }
  }

  get valid(): boolean {
    return this.timeToGetIntoBed !== undefined && this.timeToGetOutOfBed !== undefined;
  }

  toJSON(): any {
    const json: any = {};

    if (this.timeToGetIntoBed !== undefined) {
      json.timeToGetIntoBed = this.timeToGetIntoBed;
    }

    if (this.timeToGetOutOfBed !== undefined) {
      json.timeToGetOutOfBed = this.timeToGetOutOfBed;
    }

    if (this.items && this.items.length > 0) {
      json.items = this.items.map(e => e.toJSON());
    }

    if (this.createdAt) {
      json.createdAt = this.createdAt.toISOString();
    }

    return json;
  }

  revert(originalData: any): void {
    this.timeToGetIntoBed = originalData.timeToGetIntoBed;
    this.timeToGetOutOfBed = originalData.timeToGetOutOfBed;

    if (originalData.items && originalData.items.length > 0) {
      (originalData.items as any[]).forEach((e, i) => {
        if (i < this.items.length) {
          this.items[i].revert(e);
        } else {
          this.items[i] = new BIWorksheetObjectiveItem(e);
        }
      });
    }

    if (originalData.createdAt) {
      this.createdAt = new Date(originalData.createdAt);
    }
  }

  isSame(target:BIWorksheetObjective): boolean {
    if (this.timeToGetIntoBed !== target.timeToGetIntoBed
     || this.timeToGetOutOfBed !== target.timeToGetOutOfBed
     || this.items.length !== target.items.length
     || this.items.some((v, i) => v.key !== target.items[i].key)) {
      return false;
    }
    return true;
  }
}

export class BIWorksheetObjectiveItem {
  static readonly DESCRIPTIONS: {[key:string]:string} = {
    "caffeine": "就寝前の6時間はカフェインを取らない",
    "moisture": "就寝前に水分をとらない",
    "alcohol":  "就寝前の３時間はお酒を飲まない",
    "clock": "夜中に時計を見ない"
  };

  key!: string;
  originalDescription!: string;
  value: boolean | undefined = undefined;

  constructor(fields?: any) {
    if (fields) {
      if (fields.key !== undefined) {
        this.key = fields.key;
      };

      if (fields.originalDescription !== undefined) {
        this.originalDescription = fields.originalDescription;
      };

      if (fields.value !== undefined) {
        this.value = fields.value;
      };
    }
  }

  get isOriginal(): boolean {
    return this.key !== undefined && this.key.startsWith('original');
  }

  get name(): string {
    if (this.isOriginal) {
      return 'original';
    }
    return this.key;
  }

  get description(): string {
    return this.isOriginal ?
      this.originalDescription : BIWorksheetObjectiveItem.DESCRIPTIONS[this.key];
  }

  get ok(): boolean {
    return this.value !== undefined && this.value === true;
  }

  get ng(): boolean {
    return this.value !== undefined && this.value === false;
  }

  toJSON(): any {
    const json: any = {};

    if (this.key !== undefined) {
      json.key = this.key;
    }

    if (this.originalDescription !== undefined) {
      json.originalDescription = this.originalDescription;
    }

    if (this.value !== undefined) {
      json.value = this.value;
    }

    return json;
  }

  revert(originalData: any): void {
    if (originalData.key !== undefined) {
      this.key = originalData.key;
    };

    if (originalData.originalDescription !== undefined) {
      this.originalDescription = originalData.originalDescription;
    };

    if (originalData.value !== undefined) {
      this.value = originalData.value;
    };
  }
}

export interface BIWorksheetRecommendedObjective {
  sleepEfficiency: number;
  sleepEfficiencyStatus: string,
  timeToGetIntoBed: number | undefined;
  averageSleepTime: number;
  timeSpentInBed: number;
  hasNap: boolean
}