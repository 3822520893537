import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/services';

@Injectable({
  providedIn: 'root'
})
export class PreSurveyGuard {
  constructor(
    private userServ: UserService,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const status = this.userServ.getAttribute(UserService.ATTR_STATUS);
    console.log("PreSurveyGuard.canActivate UserService.ATTR_STATUS: ", status);
    if (status === UserService.STATUS_SURVEY) {
      this.router.navigate(['/pre-survey']);
      return false;
    }

    return true;
  }
}
