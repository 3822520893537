import { BAMission } from './ba-mission';

export class BAWorksheet extends BAMission {
  isOriginal!: boolean;
  accomplishment!: number;
  delight!: number;
  forcastedAccomplishment!: number;
  forcastedDelight!: number;
  when: Date | undefined;
  alarm: boolean | undefined;
  where: string | undefined;
  whoWith: string | undefined;
  obstacle: string | undefined;
  counterMeasure!: string | undefined;
  done: boolean; //終わったかどうか
  successfulCompletion: boolean | undefined; //完了か未了か
  memo: string | undefined; //気づいたこと
  incompletionReview!: string | undefined; //未了のとき感じたこと
  post: boolean | undefined;

  constructor(fields?: any) {
    super(fields);

    if (fields) {
      //おすすめ活動目標/再追加/mock読み込み
      this.done = fields.done ? fields.done : false;
      this.successfulCompletion = fields.successfulCompletion
        ? fields.successfulCompletion
        : false;

      if (typeof this.when === 'string') {
        this.when = new Date(this.when);
      }
    } else {
      //オリジナル活動目標
      this.isOriginal = true;
      this.done = false;
      this.successfulCompletion = false;
      this.post = false;
    }
    if (this.isOriginal == undefined) this.isOriginal = false;
    if (this.accomplishment == undefined) this.accomplishment = 0;
    if (this.delight == undefined) this.delight = 0;
    if (this.forcastedAccomplishment == undefined) this.forcastedAccomplishment = 0;
    if (this.forcastedDelight == undefined) this.forcastedDelight = 0;
  }

  override toJSON(): any {
    let json: any = super.toJSON();

    if (this.isOriginal != undefined) json.isOriginal = this.isOriginal;
    if (this.accomplishment != undefined) json.accomplishment = this.accomplishment;
    if (this.delight != undefined) json.delight = this.delight;
    if (this.forcastedAccomplishment != undefined)
      json.forcastedAccomplishment = this.forcastedAccomplishment;
    if (this.forcastedDelight != undefined)
      json.forcastedDelight = this.forcastedDelight;
    if (this.when) json.when = this.when.toISOString();
    if (this.alarm) json.alarm = this.alarm;
    if (this.where) json.where = this.where;
    if (this.whoWith) json.whoWith = this.whoWith;
    if (this.obstacle) json.obstacle = this.obstacle;
    if (this.counterMeasure) json.counterMeasure = this.counterMeasure;
    if (this.done) json.done = this.done;
    if (this.successfulCompletion)
      json.successfulCompletion = this.successfulCompletion;
    if (this.memo) json.memo = this.memo;
    if (this.incompletionReview)
      json.incompletionReview = this.incompletionReview;
    if (this.post != null) json.post = this.post;

    return json;
  }

  override toContent(): any {
    let json: any = super.toContent();

    delete json.delight;
    delete json.accomplishment;
    delete json.done;
    delete json.successfulCompletion;
    delete json.memo;
    delete json.incompletionReview;
    if (this.isOriginal) json.isOriginal = this.isOriginal;
    return json;
  }

  override get canSave(): boolean {
    if (
      this.title &&
      this.title.length > 0 &&
      this.detail &&
      this.detail.length > 0 &&
      this.category &&
      this.category.length > 0
    ) {
      return true;
    }
    return false;
  }

  get isPlanned(): boolean {
    if (
      this.when != undefined ||
      (this.where && this.where.length > 0) ||
      (this.whoWith && this.whoWith.length > 0) ||
      (this.obstacle && this.obstacle.length > 0) ||
      (this.counterMeasure && this.counterMeasure.length > 0)
    ) {
      return true;
    }
    return false;
  }

  override get isMaking(): boolean {
    return !this.done;
  }

  override revert(originalData: any): void {
    this.isOriginal = originalData.isOriginal;
    if (this.isOriginal) {
      this.title = originalData.title;
      this.detail = originalData.detail;
      this.category = originalData.category;
    }
    this.accomplishment = originalData.accomplishment;
    this.delight = originalData.delight;
    this.forcastedAccomplishment = originalData.forcastedAccomplishment;
    this.forcastedDelight = originalData.forcastedDelight;
    this.done = originalData.done;
    if (originalData.when) this.when = new Date(originalData.when);
    else delete this.when;
    if (originalData.alarm) this.alarm = originalData.alarm;
    else delete this.alarm;
    if (originalData.where) this.where = originalData.where;
    else delete this.where;
    if (originalData.whoWith) this.whoWith = originalData.whoWith;
    else delete this.whoWith;
    if (originalData.obstacle) this.obstacle = originalData.obstacle;
    else delete this.obstacle;
    if (originalData.counterMeasure)
      this.counterMeasure = originalData.counterMeasure;
    else delete this.counterMeasure;
    if (originalData.successfulCompletion)
      this.successfulCompletion = originalData.successfulCompletion;
    else delete this.successfulCompletion;
    if (originalData.memo) this.memo = originalData.memo;
    else delete this.memo;
    if (originalData.incompletionReview)
      this.incompletionReview = originalData.incompletionReview;
    else delete this.incompletionReview;
    if (originalData.post) this.post = originalData.post;
    else delete this.post;
  }
}
