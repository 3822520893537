import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';

import { Events } from './services/events/events.service';
import { ModuleService } from './services/module/module.service';
import { AuthStatus } from './services/user/user.service';
import {
  UserService,
  AccessService,
  LessonService,
  SelfCheckService
} from './services/services';
import { NotificationsService } from './services/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private platform: Platform,
    private storage: Storage,
    private events: Events,
    private moduleServ: ModuleService,
    private userServ: UserService,
    private accessServ: AccessService,
    private lessonServ: LessonService,
    private selfCheckServ: SelfCheckService,
    private translateServ: TranslateService
  ) { }

  async initializeApp(): Promise<void> {
    this.initTranslate();
    await this.initStorage();
    await this.initUser();
    await this.initPlatform();
    await this.startAction();
  }

  private initTranslate(): void {
    // Set the default language for translation strings, and the current language.
    this.translateServ.setDefaultLang('ja');

    if (this.translateServ.getBrowserLang() !== undefined) {
      this.translateServ.use(this.translateServ.getBrowserLang()!);
    } else {
      this.translateServ.use('ja'); // Set your language here
    }
  }

  private async initStorage() {
    await this.storage.create();
    console.log('Ionic Storage has been initialized.');
  }

  private async initUser(): Promise<void> {
    const authStatus = await this.userServ.isAuthenticated();
    console.log('auth status:', AuthStatus[authStatus]);
    if (authStatus == AuthStatus.DONE || authStatus == AuthStatus.EMAIL_VERIFICATION_REQUIRED) {
      await this.accessServ.resume(new Date());

      const status = this.userServ.getAttribute(UserService.ATTR_STATUS);
      console.log("UserService.ATTR_STATUS: ", status);
      if (status == UserService.STATUS_MAIN) {
        const assignment = this.userServ.getAttribute(UserService.ATTR_ASSIGNMENT);
        console.log("UserService.ATTR_ASSIGNMENT: ", assignment);
        if (assignment) {
          //モジュール設定
          this.moduleServ.setAssignment(assignment);

          //レッスン、セルフチェックデータ読み込み
          await this.lessonServ.load(true);
          await this.selfCheckServ.load(true);

          //セルフチェック設定
          const type = this.moduleServ.selfCheck;
          if (!this.selfCheckServ.isSetupFinished(type)) {
            console.log('error: invalid self check data.');
            const username = this.userServ.getUser().username;
            const initDate = new Date(this.userServ.getAttribute(UserService.ATTR_STARTED_AT));
            await this.selfCheckServ.setup(username, type, initDate);
          }
        }
      }
    }
  }

  private async initPlatform(): Promise<void> {
    await this.platform.ready();

    if (Capacitor.isNativePlatform()) {
      this.platform.resume.subscribe((e) => {
        this.resumeAction();
      });
      this.platform.pause.subscribe((e) => {
        this.pauseAction();
      });
    } else {
      this.resumeAction();

      document.addEventListener("visibilitychange", () => {
        console.log(`visibilitychange hidden=${document.hidden}`);
        if (document.hidden) {
          this.pauseAction();
        } else {
          this.resumeAction();
        }
      });  
    }
  }

  private async startAction(): Promise<void> {
    try {
      if (Capacitor.isNativePlatform()) {
        await StatusBar.setStyle({ style: Style.Default });
        await SplashScreen.hide();
      }
    } catch (error) {
      console.log('error start action ', error);
    }
  }

  private resumeAction(): void {
    console.log('resume');
    if (this.userServ.getAuthStatus() == AuthStatus.DONE) {
      this.userServ.refreshSession().then(() => {
        this.accessServ.resume(new Date()).catch((error) => {
          console.log('access[status=resume] error', error);
        });
      })
      .catch((error) => {
        console.log('update session error', error);
      });

      this.events.publish('resume');
      this.events.publish('refresh-lesson');
      this.events.publish('refresh-self-check');
      this.events.publish('refresh-bi-worksheet');
      this.events.publish('reset-bi-worksheet');
    }
  }

  private pauseAction(): void {
    console.log('pause');
    if (this.userServ.getAuthStatus() == AuthStatus.DONE) {
      this.accessServ.pause(new Date()).catch((error) => {
        console.log('access[status=pause] error:', error);
      });
    }
  }

  async setupNotifications(notificationsServ: NotificationsService): Promise<void> {
    const authStatus = this.userServ.getAuthStatus();
    if (authStatus == AuthStatus.DONE || authStatus == AuthStatus.EMAIL_VERIFICATION_REQUIRED) {
      // 通知設定
      if (await notificationsServ.hasPermission() === false) {
        return;
      }

      // 再開イベント設定
      this.setupNotificationResumeEvent(notificationsServ);

      // 最終アクセス日時通知更新
      await notificationsServ.updateLastAccess(
        this.userServ.getAttribute(UserService.ATTR_MORNING_NOTIFICATION)
      );

      const status = this.userServ.getAttribute(UserService.ATTR_STATUS);
      if (status == UserService.STATUS_MAIN) {
        const assignment = this.userServ.getAttribute(UserService.ATTR_ASSIGNMENT);
        if (assignment) {

          //セルフチェック設定
          const type = this.moduleServ.selfCheck;
          if (!this.selfCheckServ.isSetupFinished(type)) {
            await notificationsServ.setupSelfCheckNotification(
              this.selfCheckServ.startDate as Date,
              this.userServ.getAttribute(UserService.ATTR_EVENING_NOTIFICATION),
              this.selfCheckServ.answerIndexs,
              this.selfCheckServ.finishedAnswerIndexs
            );
          }

          //睡眠日記通知更新
          const biNotificationFlag = this.userServ.getAttribute(UserService.ATTR_BI_NOTIFICATION_FLAG);
          if (biNotificationFlag === 'true') {
            const isScheduled = await notificationsServ.isBINotificationScheduled();
            if (!isScheduled) {
              await notificationsServ.updateBINotification(
                this.userServ.getAttribute(UserService.ATTR_BI_NOTIFICATION)
              );
            }
          }
        }
      }
    }
  }

  private setupNotificationResumeEvent(notificationsServ: NotificationsService): void {
    this.events.subscribe('resume', () => {
      notificationsServ.updateLastAccess(
        this.userServ.getAttribute(UserService.ATTR_MORNING_NOTIFICATION)
      );
    });
  }
}
