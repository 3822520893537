import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { BIWorksheet } from '../../../models/bi-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BIWorksheetService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-BIWorksheet';

  constructor(
    dbServ: DynamoDBService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): BIWorksheet {
    return item ? new BIWorksheet(item) : new BIWorksheet();
  }

  override get indexName(): string {
    return 'DateSorted';
  }

  override sort(): void {
    (this.items as BIWorksheet[])?.sort((a, b) => {
      return b.startDate.getTime() - a.startDate.getTime();
    });
  }
}
