import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { BARecommendation } from '../../../models/ba-recommendation';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

export abstract class BaseBARecommendationService {
  protected abstract table: string;

  constructor(protected dbServ: DynamoDBService) {}

  createRecommendation(item?: any): BARecommendation {
    return new BARecommendation(item);
  }

  abstract query(missionId: string): Promise<any>;

  abstract add(recommend: BARecommendation): Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class BARecommendationService extends BaseBARecommendationService {
  protected readonly table = environment.aws.resourceNamePrefix + '-BARecommendation';

  protected items: BARecommendation[] = [];

  constructor(dbServ: DynamoDBService) {
    super(dbServ);
  }

  get scanIndexForward(): boolean {
    return false;
  }

  override async query(missionId: string): Promise<any> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params: any = {
        TableName: this.table,
        KeyConditionExpression: '#userId = :userId and #missionId = :missionId',
        ExpressionAttributeNames: {
          '#userId': 'userId',
          '#missionId': 'missionId',
        },
        ExpressionAttributeValues: {
          ':userId': credentials.identityId,
          ':missionId': missionId,
        },
        FilterExpression: 'attribute_not_exists(deletedAt)',
        ScanIndexForward: this.scanIndexForward,
      };

      const result = await doc.query(params).promise();
      const items = result.Items;
      console.log('BARecommendationService: query', items);

      this.items = [];
      if (items) {
        this.items = [...items.map((item) => this.createRecommendation(item))];
      }

      return this.items;
    } catch (error) {
      console.log('BARecommendationService: query error', error);
      throw error;
    }
  }

  override async add(recommend: BARecommendation): Promise<any> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const item: any = recommend.toJSON();
      item.userId = credentials.identityId;
      const params = {
        TableName: this.table,
        Item: item,
        ConditionExpression: 'attribute_not_exists(worksheetId)',
      };
      await doc.put(params).promise();

      if (this.items == null) {
        this.items = [];
      }
      this.items.unshift(recommend);
    } catch (error) {
      console.log('BARecommendationService: add error', error);
      throw error;
    }
  }
}
