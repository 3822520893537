import { Component, OnInit } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';

import { AppService } from './app.service';
import { NotificationsService } from './services/notifications/notifications.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private appServ: AppService,
    private notificationsServ: NotificationsService,
  ) { }

  get isDesktop(): boolean {
    return this.isMobile === false;
  }

  get isMobile(): boolean {
    return this.platform.is('android')
      || this.platform.is('iphone')
      || this.platform.is('ipad');
  }

  async ngOnInit() {
    await this.appServ.setupNotifications(this.notificationsServ);
  }
}
