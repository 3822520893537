import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { Auth } from 'aws-amplify';

AWS.config.region = environment.aws.region;
AWS.config.update({ customUserAgent: environment.aws.customUserAgent });
AWS.config.update({ dynamoDbCrc32: false });

@Injectable({
  providedIn: 'root'
})
export class DynamoDBService {

  constructor() { }

  async getDocumentClient(credentials: any = undefined): Promise<DocumentClient> {
    if (!credentials) {
      credentials = await Auth.currentCredentials();
    }
    return new AWS.DynamoDB.DocumentClient({ credentials });
  }
}