import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { environment } from '../../../environments/environment';
import { DynamoDBService } from '../database/dynamodb.service';

export abstract class BaseAccessService {
  abstract resume(date: Date): Promise<any>;
  abstract pause(date: Date): Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class AccessService extends BaseAccessService {
  protected readonly table = environment.aws.resourceNamePrefix + '-Access';

  constructor(private dbServ: DynamoDBService) {
    super();
  }

  resume(date: Date): Promise<any> {
    return this.put('resume', date);
  }

  pause(date: Date): Promise<any> {
    return this.put('pause', date);
  }

  private async put(status: string, date: Date): Promise<any> {
    const credentials = await Auth.currentCredentials();
    const doc = await this.dbServ.getDocumentClient(credentials);

    const item: any = {
      userId: credentials.identityId,
      status: status,
      createdAt: date.toISOString(),
    };
    const result = await doc.put({
      TableName: this.table,
      Item: item,
      ConditionExpression: 'attribute_not_exists(createdAt)',
    }).promise();

    return result;
  }
}
