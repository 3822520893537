import { Auth } from 'aws-amplify';

import { AppConfig } from '../../../app/app.config';
import { DynamoDBService } from '../database/dynamodb.service';
import { Worksheet } from '../../models/worksheet';

export abstract class WorksheetService {
  protected abstract table: string;

  protected items: Worksheet[] | null = null;

  constructor(protected dbServ: DynamoDBService) {}

  abstract createWorksheet(item?: any): Worksheet;

  get indexName(): string | null {
    return null;
  }

  get scanIndexForward(): boolean {
    return false;
  }

  sort(): void {
    if (this.items !== null) {
      this.items.sort((a, b) => {
        if (b.createdAt && a.createdAt) {
          return b.createdAt.getTime() - a.createdAt.getTime()
        }
        return 0;
      });  
    }
  }

  async query(force?: boolean): Promise<Worksheet[]> {
    if (!force && this.items != null) {
      console.log(`${this.constructor.name}: worksheets is loaded`);
      return this.items;
    }

    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params: any = {
        TableName: this.table,
        KeyConditionExpression: '#userId = :userId',
        ExpressionAttributeNames: {
          '#userId': 'userId',
        },
        ExpressionAttributeValues: {
          ':userId': credentials.identityId,
        },
        FilterExpression: 'attribute_not_exists(deletedAt)',
        ScanIndexForward: this.scanIndexForward,
      };

      if (this.indexName) {
        params['IndexName'] = this.indexName;
      }

      const result = await doc.query(params).promise();
      const items = result.Items;

      this.items = [];
      if (items) {
        this.items = [...items.map((item) => this.createWorksheet(item))];

        if (!this.indexName) {
          this.sort();
        }
      }
      return this.items;
    } catch (error) {
      console.log(`${this.constructor.name}: query error`, error);
      throw error;
    }
  }

  async add(worksheet: Worksheet): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      worksheet.createdAt = new Date(AppConfig.now());
      worksheet.updatedAt = worksheet.createdAt;

      const item: any = worksheet.toJSON();
      item.userId = credentials.identityId;
      if (AppConfig.WAITING_LIST_USER) {
        item.waitingListUser = true;
      }

      const params = {
        TableName: this.table,
        Item: item,
        ConditionExpression: 'attribute_not_exists(worksheetId)',
      };
      await doc.put(params).promise();

      if (this.items == null) {
        this.items = [];
      }
      this.items.unshift(worksheet);
      this.sort();
    } catch (error) {
      console.log(`${this.constructor.name}: add error`, error);
      throw error;
    }
  }

  async update(worksheet: Worksheet): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      worksheet.updatedAt = new Date();
      const item: any = worksheet.toJSON();
      item.userId = credentials.identityId;
      if (AppConfig.WAITING_LIST_USER) {
        item.waitingListUser = true;
      }

      const params = {
        TableName: this.table,
        Item: item
      };
      await doc.put(params).promise();

      if (this.items) {
        const index = this.items.findIndex(item => item.worksheetId === worksheet.worksheetId);
        if (-1 < index) {
          this.items[index] = worksheet;
        }
        this.sort();
      }
    } catch (error) {
      console.log(`${this.constructor.name}: update error`, error);
      throw error;
    }
  }

  async delete(worksheet: Worksheet, real: boolean = false): Promise<void> {
    if (this.items == null) {
      return;
    }

    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);
  
      worksheet.deletedAt = new Date();
  
      const item: any = worksheet.toJSON();
      item.userId = credentials.identityId;

      if (real) {
        const params = {
          TableName: this.table,
          Key: {
            userId: item.userId,
            worksheetId: item.worksheetId
          }
        };
        await doc.delete(params).promise();
      } else {
        const params = {
          TableName: this.table,
          Item: item
        };
        await doc.put(params).promise();
      }
  
      const index = this.items.findIndex(item => item.worksheetId === worksheet.worksheetId);
      if (-1 < index) {
        this.items.splice(index, 1);
      }
    } catch (error) {
      console.log(`${this.constructor.name}: delete error`, error);
      delete worksheet.deletedAt;
      this.items.push(worksheet);
      this.sort();
      throw error;
    }
  }

  async deleteAll(real: boolean = false): Promise<any> {
    if (this.items == null) {
      return;
    }
    await Promise.all(
      this.items.map((item) => {
        return this.delete(item, real);
      })
    );
  }

  release(): void {
    this.items = null;
  }

  count(): number {
    return this.items ? this.items.length : 0;
  }
}
