const awsconfig = {
  API: {
    endpoints: [
      {
        name: 'AssignmentAPI',
        endpoint: 'https://n78cfydf6h.execute-api.ap-northeast-1.amazonaws.com/v1',
        region: 'ap-northeast-1'
      }
    ]
  },
  Auth: {
    identityPoolId: 'ap-northeast-1:2828daa8-374a-4d41-a55e-fad60a74e12a',
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_1UcSdekee',
    userPoolWebClientId: 'mn6vamr5bk5dtp16sa65urf9m',
    mandatorySignIn: false,
  }
}

export default awsconfig
