import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ja } from 'date-fns/locale';

import { AppConfig } from '../../app.config';
import { Lesson } from '../../models/lesson';
import { SelfCheck } from '../../models/self-check';
import { Worksheet } from '../../models/worksheet';
import { BAWorksheet } from '../../models/ba-worksheet';
import { PSWorksheet } from '../../models/ps-worksheet';
import { LocalNotificationsService, Schedule as LocalNotificationsSchedule } from './local-notifications.service';
import { WebPushService } from '../services';
import { Schedule as WebPushSchedule} from './web-push.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public static readonly LAST_ACCESS_TIME: number = 5 * 24 * 60 * 60 * 1000;

  public static readonly LAST_ACCESS_ID: number = 0;
  public static readonly LESSON_STEP1_ID: number = 100;
  public static readonly LESSON_STEP2_ID: number = 101;
  public static readonly LESSON_STEP3_ID: number = 102;
  public static readonly LESSON_UNLOCK_ID: number = 103;
  public static readonly PREFIX_SELF_CHECK_ID: number = 1000;
  public static readonly PREFIX_SELF_CHECK_ID_MAX: number = 1100; //実際はセルフチェックのIDは1100までない
  public static readonly SELF_CHECK_REGISTER_COUNT: number = 20;

  public static readonly WORKSHEET_NOTIFICATION_SIZE: number = 20;
  public static readonly THRESHOLD_WORKSHEET_NOTIFICATION_ID: number = 100000;
  public static readonly PS_WORKSHEET_DATE: number = 7;

  private static readonly SELFCHECK_COLOR_NORMAL: string = "#488aff";
  private static readonly SELFCHECK_COLOR_DANGER: string = "#f53d3d";

  // Icons should be placed in your app's `res/drawable` folder.
  // Only available for Android.
  private static readonly SELFCHECK_ICONS: string[] = [
    "ic_hakase",
    "ic_kodo",
    "ic_ninchi",
    "ic_cbtrobo",
    "ic_oldrobo",
    "ic_asa"
  ];

  private static readonly BI_ID: number = 200;
  private static readonly BI_TITLE: string = "睡眠日記の記入";
  private static readonly BI_TEXT: string = "昨日の睡眠はいかがでしたか？\n睡眠日記の記入をお願いします。";
  private static readonly BI_COLOR: string = "#488aff";

  // Icons should be placed in your app's `res/drawable` folder.
  // Only available for Android.
  private static readonly BI_ICON: string = "ic_hakase";

  public static get NOTIFICATION_BEFORE_DAY(): number {
    return AppConfig.NOTIFICATION_BEFORE_DAY;
  }

  public static get NOTIFICATION_LESSON_INTERVAL_DAYS(): number {
    return AppConfig.NOTIFICATION_LESSON_INTERVAL_DAYS;
  }

  constructor(
    private localNotificationsServ: LocalNotificationsService,
    private webPushServ: WebPushService
  ) { }

  static get notificationStep1Date() {
    return (
      NotificationsService.NOTIFICATION_LESSON_INTERVAL_DAYS -
      NotificationsService.NOTIFICATION_BEFORE_DAY
    );
  }

  static get notificationStep2Date() {
    return NotificationsService.NOTIFICATION_LESSON_INTERVAL_DAYS + 1;
  }

  static get notificationStep3Date() {
    return (
      NotificationsService.NOTIFICATION_LESSON_INTERVAL_DAYS * 2 -
      NotificationsService.NOTIFICATION_BEFORE_DAY
    );
  }

  static get notification2ndTargetDate() {
    return NotificationsService.notificationStep2Date + 2;
  }

  private parseTime(
    notificationTime: string
  ): { hours: number; minutes: number } {
    const t = notificationTime.split(':');
    const hours = parseInt(t[0], 10);
    const minutes = parseInt(t[1], 10);
    return { hours: hours, minutes: minutes };
  }

  private toWebPushIcon(icon: string): string {
    return `icon-${icon.substring("ic_".length)}.webp`
  }

  /*
   * 共通機能
   */

  async hasPermission(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      return this.localNotificationsServ.hasPermission();
    } else {
      return this.webPushServ.hasPermission();
    }
  }

  async canRequestPermission(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      return this.localNotificationsServ.canRequestPermission();
    } else {
      return this.webPushServ.canRequestPermission();
    }
  }

  async requestPermission(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      return this.localNotificationsServ.canRequestPermission();
    } else {
      return this.webPushServ.requestPermission();
    }
  }

  async updateNotification(
    notificationId: number,
    schedule: Date | number | object,
    title: string,
    body: string,
    params?: any
  ): Promise<void> {
    try {
      let triggerAt: null | Date = null;
      if (schedule instanceof Date) {
        triggerAt = schedule;
      } else if (typeof schedule === 'number') {
        triggerAt = new Date(schedule);
      }

      if (triggerAt != null) {
        if (triggerAt.getTime() < AppConfig.now()) {
          console.log(`update notification id = ${notificationId}. triggerAt:${triggerAt} is past.`);
          return;
        }
        schedule = { at: triggerAt };
      }

      if (Capacitor.isNativePlatform()) {
        return this.localNotificationsServ.updateNotification(
          notificationId,
          schedule as LocalNotificationsSchedule,
          title,
          body,
          params
        );
      } else {
        return this.webPushServ.updateNotification(
          notificationId,
          schedule as WebPushSchedule,
          title,
          body,
          params?.webpush
        );
      }
    } catch (e) {
      console.log('update notification error', e);
    }
  }

  async deleteNotification(notificationId: number): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return this.localNotificationsServ.deleteNotification(notificationId);
    } else {
      return this.webPushServ.deleteNotification(notificationId);
    }
  }

  async deleteAllNotification(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return this.localNotificationsServ.deleteAllNotification();
    } else {
      return this.webPushServ.deleteAllNotification();
    }
  }

  async getPending(): Promise<any[]> {
    if (Capacitor.isNativePlatform()) {
      return this.localNotificationsServ.getPending();
    } else {
      return this.webPushServ.getPending();
    }
  }

  /**
   * アプリ最終アクセス通知
   */
  async updateLastAccess(notificationTime: string): Promise<void> {
    console.log('NotificationsService.updateLastAccess');
    if (!notificationTime) {
      return;
    }

    try {
      const t = this.parseTime(notificationTime);
      const d = new Date(AppConfig.now());
      d.setHours(t.hours);
      d.setMinutes(t.minutes);
      d.setSeconds(0);

      const triggerAt = new Date(d.getTime() + NotificationsService.LAST_ACCESS_TIME);
      console.log(`*** updateLastAccess: ${triggerAt.toLocaleDateString()} ${triggerAt.toLocaleTimeString()} ***`);

      await this.updateNotification(
        NotificationsService.LAST_ACCESS_ID,
        triggerAt,
        'レジトレ！の活用はいかがですか？',
        'アプリ内で、お待ちしております。'
      );
    } catch (e) {
      console.log('updateLastAccess error', e);
    }
  }

  /**
   * レッスン通知
   */
  updateLessonNotification(
    startedAt: Date,
    lessons: Lesson[],
    morningNotification: string,
    eveningNotification: string
  ): Promise<any> {
    console.log('NotificationsService.updateLessonNotification');
    const mainLessons = lessons.filter((lesson) => lesson.lessonId.startsWith(AppConfig.EPILOGUE_MODULE_PREFIX) == false);
    const currentLesson = mainLessons.find((lesson) => {
      return (
        lesson.firstLessonPartStartAt != null &&
        lesson.lastLessonPartFinishedAt == null
      );
    });
    console.log('current lesson:', currentLesson);

    if (currentLesson == null) {
      console.log('lesson release notification and clear lesson notification');
      return Promise.all([
        this.updateLessonUnlockNotification(startedAt, lessons, morningNotification),
        this.deleteNotification(NotificationsService.LESSON_STEP1_ID),
        this.deleteNotification(NotificationsService.LESSON_STEP2_ID),
        this.deleteNotification(NotificationsService.LESSON_STEP3_ID),
      ]);
    }

    const isLastLesson =
      currentLesson.lessonId == mainLessons[mainLessons.length - 1].lessonId;
    console.log('is last lesson:', isLastLesson);

    const date = new Date(currentLesson.firstLessonPartStartAt);
    let t = this.parseTime(eveningNotification);
    date.setHours(t.hours);
    date.setMinutes(t.minutes);
    date.setSeconds(0);
    const dateStep1 = new Date(date).setDate(
      date.getDate() + NotificationsService.notificationStep1Date
    );
    const dateStep2 = new Date(date).setDate(
      date.getDate() + NotificationsService.notificationStep2Date
    );
    const dateStep3 = new Date(date).setDate(
      date.getDate() + NotificationsService.notificationStep3Date
    );
    console.log(`dateStep1:${new Date(dateStep1).toISOString()}`);
    console.log(`dateStep2:${new Date(dateStep2).toISOString()}`);
    console.log(`dateStep3:${new Date(dateStep3).toISOString()}`);

    const tagretDate = new Date(date).setDate(
      date.getDate() + NotificationsService.notification2ndTargetDate
    );
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(tagretDate, timeZone);
    const tagretDateFormatString = format(zonedDate, 'MM月dd日', { locale: ja });

    const tasks = [
      this.deleteNotification(NotificationsService.LESSON_UNLOCK_ID)
    ];

    tasks.push(
      this.updateNotification(
        NotificationsService.LESSON_STEP1_ID,
        dateStep1,
        'よく頑張っておられますね。',
        isLastLesson
          ? '最後のレッスンの解除まで、あと少し。お待ちしています。'
          : '次のレッスンの解除まで、あと少し。お待ちしています。'
      )
    );

    tasks.push(
      this.updateNotification(
        NotificationsService.LESSON_STEP2_ID,
        dateStep2,
        'レッスンを進めるのは大変ですね。',
        isLastLesson
          ? `ご苦労様です。あまり間があくと効果が薄まりますので、${tagretDateFormatString}を目標にしませんか？`
          : `ご苦労様です。あまり間があくと効果が薄まりますので、${tagretDateFormatString}を目標にしませんか？`
      )
    );

    tasks.push(
      this.updateNotification(
        NotificationsService.LESSON_STEP3_ID,
        dateStep3,
        'よく頑張っておられますね。',
        isLastLesson
          ? '最後のレッスンの解除まで、あと少し。お待ちしています。'
          : '次のレッスンの解除まで、あと少し。お待ちしています。'
      )
    );

    return Promise.all(tasks);
  }

  private updateLessonUnlockNotification(
    startedAt: Date,
    lessons: Lesson[],
    notificationTime: string
  ): Promise<any> {

    const lockedLessonIndex = lessons.findIndex((lesson) => {
      return lesson.locked;
    });

    // 初回レッスンまたは未開始レッスンがない場合は、開放通知なし
    if (lockedLessonIndex < 1) {
      return this.deleteNotification(NotificationsService.LESSON_UNLOCK_ID);
    }

    const prevLesson = lessons[lockedLessonIndex - 1];

    // まだ前のレッスンが完了していなければ、開放通知なし
    if (!prevLesson.finishDate) {
      return this.deleteNotification(NotificationsService.LESSON_UNLOCK_ID);
    }

    // 起点日と待機期間を設定
    let originAt, interval;
    const prevLessonOriginAt = prevLesson.firstLessonPartStartAt;
    const lockedLesson = lessons[lockedLessonIndex];
    const isEpilogue = prevLesson.lessonId.startsWith(AppConfig.EPILOGUE_MODULE_PREFIX);
    const firstAdditionalLesson = isEpilogue && lockedLesson.displayRestriction;
    if (prevLesson.lessonId == AppConfig.INIT_LESSON_MODULE || firstAdditionalLesson) {
      originAt = prevLessonOriginAt;
      interval = AppConfig.FIRST_LESSON_INTERVAL_DAYS;
    } else if (lockedLesson.lessonId.startsWith(AppConfig.EPILOGUE_MODULE_PREFIX)) {
      const epilogueUnlockAt = new Date(
        startedAt.getFullYear(),
        startedAt.getMonth(),
        startedAt.getDate() + AppConfig.INTERVAL_EPILOGUE
      );

      const prevLessonUnlockAt = new Date(
        prevLessonOriginAt.getFullYear(),
        prevLessonOriginAt.getMonth(),
        prevLessonOriginAt.getDate() + AppConfig.LESSON_INTERVAL_DAYS
      );

      if (epilogueUnlockAt < prevLessonUnlockAt) {
        originAt = prevLessonOriginAt;
        interval = AppConfig.LESSON_INTERVAL_DAYS;
      } else {
        originAt = startedAt;
        interval = AppConfig.INTERVAL_EPILOGUE;
      }      
    } else {
      originAt = prevLessonOriginAt;
      interval = AppConfig.LESSON_INTERVAL_DAYS;
    }

    // 待機期間がなければ、開放通知なし
    if (!originAt || interval <= 0) {
      return this.deleteNotification(NotificationsService.LESSON_UNLOCK_ID);
    }

    // 開放日が取得できなければ、開放通知なし
    const unlockAt = this.getLessonUnlockDate(originAt, interval, notificationTime);
    if (!unlockAt) {
      return this.deleteNotification(NotificationsService.LESSON_UNLOCK_ID);
    }

    // 開放通知を設定
    return this.updateNotification(
      NotificationsService.LESSON_UNLOCK_ID,
      unlockAt,
      '新しいレッスンに取り組めます',
      '今日も楽しくレジリエンス力を高めましょう！'
    );
  }

  private getLessonUnlockDate(originAt: Date, interval: number, notificationTime: string) {
    // 解除日は対象レッスンの最後のパートの完了時間 + intervalの00:00
    const unlockAt = new Date(
      originAt.getFullYear(),
      originAt.getMonth(),
      originAt.getDate() + interval
    );

    if (unlockAt.getTime() < AppConfig.now()) {
      return undefined;
    }

    const t = this.parseTime(notificationTime);
    unlockAt.setHours(t.hours);
    unlockAt.setMinutes(t.minutes);
    unlockAt.setSeconds(0);
    console.log(`unlockAt:${new Date(unlockAt).toISOString()}`);

    return unlockAt;
  }

  /**
   * セルフチェック通知
   */
  setupSelfCheckNotification(
    startDate: Date,
    notificationTime: string,
    answerIndexs: number[],
    finishedAnswerIndexs: number[]
  ): Promise<any> {
    console.log(`NotificationsService.setupSelfCheckNotification: ${notificationTime}`);
    return this.queryScheduledSelfCheckIds().then((scheduledIds) => {
      if (!scheduledIds) {
        return Promise.resolve();
      }

      console.log('scheduled id count:' + scheduledIds.length);
      if (
        NotificationsService.SELF_CHECK_REGISTER_COUNT - scheduledIds.length <=
        0
      ) {
        return Promise.resolve();
      }

      return this.addSelfCheckNotification(
        startDate,
        notificationTime,
        scheduledIds,
        answerIndexs,
        finishedAnswerIndexs
      );
    });
  }

  private async queryScheduledSelfCheckIds(): Promise<number[]> {
    try {
      const notifications = await this.getPending();
      const ids = notifications.map(notification => notification.id);
      return ids.filter(id => {
        return (
          id >= NotificationsService.PREFIX_SELF_CHECK_ID &&
          id < NotificationsService.PREFIX_SELF_CHECK_ID_MAX
        );
      });
    } catch (e) {
      console.log('queryScheduledSelfCheckIds error', e);
      return [];
    }
  }

  private async addSelfCheckNotification(
    startDate: Date,
    notificationTime: string,
    scheduledIds: number[],
    answerIndexs: number[],
    finishedAnswerIndexs: number[]
  ): Promise<any> {
    const date = new Date(startDate);
    const t = this.parseTime(notificationTime);
    date.setHours(t.hours);
    date.setMinutes(t.minutes);
    date.setSeconds(0);
    console.log(`first can answer at: ${date.toISOString()}`);
    console.log(`finished answer indexs: [${finishedAnswerIndexs.toString()}]`);

    const now = AppConfig.now();
    console.log(`now: ${new Date(now).toISOString()}`);

    const tasks: Promise<any>[] = [];

    const addNotification = (answerIndex: number): number => {
      let register = 0;
      const index = answerIndex - 1;
      const firstId = NotificationsService.PREFIX_SELF_CHECK_ID + index * 2;
      const secondId = firstId + 1;

      const week = SelfCheck.indexToWeek(answerIndex);
      const firstWeek = SelfCheck.indexToWeek(1);
      const addDate = 7 * (week - firstWeek);

      const firstAt = new Date(date).setDate(
        date.getDate() + addDate
      );
      const secondAt = new Date(date).setDate(
        date.getDate() + addDate + 1
      );
      console.log(
        `answerIndex:${answerIndex} first at:${new Date(firstAt).toISOString()} second at:${new Date(secondAt).toISOString()}`
      );

      if (finishedAnswerIndexs.findIndex((v) => v == answerIndex) != -1) {
        console.log(`finished`);
        return register;
      }

      const icon = this.getSelfCheckIcon(answerIndex);

      if (
        now < firstAt &&
        scheduledIds.findIndex((id) => id == firstId) == -1
      ) {
        tasks.push(
          this.updateNotification(
            firstId,
            firstAt,
            `第${week}週のセルフチェックの日になりました。`,
            `2日以内に回答をお願いします。`,
            {
              largeIcon: icon,
              iconColor: NotificationsService.SELFCHECK_COLOR_NORMAL,
              webpush: {
                notification: {
                  icon: `assets/icons/${this.toWebPushIcon(icon)}`,
                  requireInteraction: true
                },
                fcmOptions: {
                  link: '/tabs/self-check'
                }
              }
            }
          )
        );
        register++;
      } else {
        console.log(`id:${firstId} is scheduled or invalid`);
      }

      if (
        now < secondAt &&
        scheduledIds.findIndex((id) => id == secondId) == -1
      ) {
        tasks.push(
          this.updateNotification(
            secondId,
            secondAt,
            `第${week}週のセルフチェックが未回答です。`,
            `本日中の回答をお願いします。`,
            {
              largeIcon: icon,
              iconColor: NotificationsService.SELFCHECK_COLOR_DANGER,
              webpush: {
                notification: {
                  icon: `assets/icons/${this.toWebPushIcon(icon)}`,
                  requireInteraction: true
                },
                fcmOptions: {
                  link: '/tabs/self-check'
                }
              }
            }
          )
        );
        register++;
      } else {
        console.log(`id:${secondId} is scheduled or invalid`);
      }
      return register;
    };

    const max = NotificationsService.SELF_CHECK_REGISTER_COUNT - scheduledIds.length;
    let cnt = 0;
    for (const answerIndex of answerIndexs) {
      cnt += addNotification(answerIndex);
      if (max <= cnt) {
        break;
      }
    }

    return Promise.all(tasks).catch((e) => {
      console.log('addSelfCheckNotification error', e);
    });
  }

  async updateSelfCheckNotification(notificationTime: string): Promise<any> {
    try {
      console.log(`NotificationsService.updateSelfCheckNotification: ${notificationTime}`);
      const notifications = await this.queryScheduledSelfCheckNotifications();
      if (!notifications) {
        return;
      }

      const tasks: Promise<any>[] = [];
      const t = this.parseTime(notificationTime);
      notifications.forEach((notification: any) => {
        const triggerAt = notification.schedule ? notification.schedule.at : notification.triggerAt;
        if (!triggerAt) {
          return;
        }
        const schedule = new Date(triggerAt);
        schedule.setHours(t.hours);
        schedule.setMinutes(t.minutes);
        schedule.setSeconds(0);

        const isFirstId = (id: number)=>{
          return (id - NotificationsService.PREFIX_SELF_CHECK_ID) % 2 == 0;
        }

        let baseId, color;
        if (isFirstId(notification.id)) {
          baseId = Math.floor((notification.id - NotificationsService.PREFIX_SELF_CHECK_ID) / 2);
          color = NotificationsService.SELFCHECK_COLOR_NORMAL;
        } else {
          baseId = Math.floor((notification.id - NotificationsService.PREFIX_SELF_CHECK_ID - 1) / 2);
          color = NotificationsService.SELFCHECK_COLOR_DANGER;
        }

        const icon = this.getSelfCheckIcon(baseId + 1);

        tasks.push(
          this.updateNotification(
            notification.id,
            schedule,
            notification.title,
            notification.body,
            {
              largeIcon: icon,
              iconColor: color,
              webpush: {
                notification: {
                  icon: `assets/icons/${this.toWebPushIcon(icon)}`,
                  requireInteraction: true
                },
                fcmOptions: {
                  link: '/tabs/self-check'
                }
              }
            }
          )
        );
      });
      return Promise.all(tasks);
    } catch(e) {
      console.log('updateSelfCheckNotification error', e);
    }
  }

  private async queryScheduledSelfCheckNotifications(): Promise<any> {
    try {
      const notifications = await this.getPending();
      return notifications.filter(notification => {
        return (
          notification.id >= NotificationsService.PREFIX_SELF_CHECK_ID &&
          notification.id < NotificationsService.PREFIX_SELF_CHECK_ID_MAX
        );
      });
    } catch (e) {
      console.log('queryScheduledSelfCheckNotifications error', e);
    }
  }

  private getSelfCheckIcon(answerIndex: number): string {
    const index = answerIndex - 1;
    if (index - 1 < NotificationsService.SELFCHECK_ICONS.length) {
      return NotificationsService.SELFCHECK_ICONS[index];
    } else {
      return NotificationsService.SELFCHECK_ICONS[NotificationsService.SELFCHECK_ICONS.length - 1];
    }
  }

  deleteSelfCheckNotification(selfCheck: SelfCheck): Promise<any> {
    console.log('NotificationsService.deleteSelfCheckNotification');
    const index = selfCheck.answerIndex - 1;
    const firstId = NotificationsService.PREFIX_SELF_CHECK_ID + index * 2;
    const secondId = firstId + 1;
    return Promise.all([
      this.deleteNotification(firstId),
      this.deleteNotification(secondId),
    ]);
  }

  deleteAllSelfCheckNotification(): Promise<any> {
    console.log('NotificationsService.deleteAllSelfCheckNotification');
    return this.queryScheduledSelfCheckIds().then((scheduledIds) => {
      if (!scheduledIds) {
        return Promise.resolve();
      }

      console.log('scheduled id count:' + scheduledIds.length);
      return Promise.all(
        scheduledIds.map((id) => {
          return this.deleteNotification(id);
        })
      ).then(() => {
        return Promise.resolve();
      });
    });
  }

  /**
   * 行動活性化通知
   */
  updateBANotification(worksheet: BAWorksheet): Promise<any> {
    const title = worksheet.title;
    const triggerAt = worksheet.when;
    const notificationId = worksheet.createdAt.getTime();
    console.log(
      `*** updateBANotification: ${triggerAt?.toLocaleDateString()} ${triggerAt?.toLocaleTimeString()} ***`
    );
    return this.updateNotification(
      notificationId,
      triggerAt as Date,
      `活動目標実行日`,
      `「${title}」をやりましょう`,
      {
        webpush: {
          fcmOptions: {
            link: '/tabs/ba-worksheet'
          }
        }
      }
    );
  }

  /**
   * 解決シート通知
   */
  updatePSNotification(worksheet: PSWorksheet): Promise<any> {
    const idea = worksheet?.selectedIdea?.title;
    const updatedAt = new Date(worksheet.updatedAt);
    const triggerAt = new Date(
      updatedAt.setDate(
        updatedAt.getDate() + NotificationsService.PS_WORKSHEET_DATE
      )
    );
    const notificationId = worksheet.createdAt.getTime();
    console.log(
      `*** updatePSNotification: ${triggerAt.toLocaleDateString()} ${triggerAt.toLocaleTimeString()} ***`
    );
    return this.updateNotification(
      notificationId,
      triggerAt,
      `「${idea}」のアイデアは実行できましたか？`,
      `アプリ内でお待ちしております。`,
      {
        webpush: {
          fcmOptions: {
            link: '/tabs/ps-worksheet'
          }
        }
      }
    );
  }

  /**
   * ワークシート通知の削除
   */
  deleteWorksheetNotification(worksheet: Worksheet): Promise<any> {
    return this.deleteNotification(worksheet.createdAt.getTime());
  }

  /**
   * 睡眠日記通知
   */
  async updateBINotification(notificationTime: string): Promise<void> {
    console.log('NotificationsService.updateBINotification');
    if (!notificationTime) {
      return;
    }
    try {
      const t = this.parseTime(notificationTime);
      console.log(`*** updateBINotification: ${t.hours}:${t.minutes} ***`);

      await this.updateNotification(
        NotificationsService.BI_ID,
        {
          allowWhileIdle: true,
          on: {
            hour: t.hours,
            minute: t.minutes
          }
        },
        NotificationsService.BI_TITLE,
        NotificationsService.BI_TEXT,
        {
          largeIcon: NotificationsService.BI_ICON,
          iconColor: NotificationsService.BI_COLOR,
          webpush: {
            notification: {
              icon: `assets/icons/${this.toWebPushIcon(NotificationsService.BI_ICON)}`,
              requireInteraction: true
            },
            fcmOptions: {
              link: '/tabs/bi-worksheet'
            }
          }
        }
      );
    } catch(e) {
      console.log('updateBINotification error', e);
    }
  }

  deleteBINotification(): Promise<any> {
    return this.deleteNotification(NotificationsService.BI_ID);
  }

  async isBINotificationScheduled(): Promise<boolean> {
    try {
      const notifications = await this.getPending();
      return notifications.some(notification => notification.id === NotificationsService.BI_ID);
    } catch(e) {
      console.log('isBINotificationScheduled error', e);
      return false;
    }
  }
}
