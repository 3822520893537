import { Worksheet } from './worksheet';

export class PSWorksheet extends Worksheet {
  problem!: string;
  goal!: string;
  step!: number;
  ideas!: PSWorksheetIdea[];

  public static readonly STEP_COMPLETED: number = 5;
  public static readonly STEP_EXECUTION: number = 4;
  public static readonly STEP_MEIT_DEMERIT: number = 3;
  public static readonly STEP_IDEA: number = 2;
  public static readonly STEP_GOALE: number = 1;
  public static readonly STEP_PROBLEM: number = 0;

  public static readonly MIN_VALID_IDEA_COUNT = 3;

  constructor(fields?: any) {
    super(fields);
    if (!this.problem) this.problem = '';
    if (!this.goal) this.goal = '';
    if (!this.step) this.step = 0;

    this.ideas = [];
    if (fields && fields.ideas && fields.ideas.length > 0) {
      fields.ideas.forEach((e: any) => {
        this.ideas.push(new PSWorksheetIdea(e));
      });
    }
  }

  override toJSON(): any {
    const json: any = super.toJSON();

    if (this.problem) json.problem = this.problem;
    if (this.goal) json.goal = this.goal;
    if (this.step) json.step = this.step;

    if (this.ideas.length > 0) {
      json.ideas = [];
      this.ideas.forEach((idea) => {
        json.ideas.push(idea.toJSON());
      });
    }

    return json;
  }

  isSameDate(date: Date): boolean {
    let format = 'YYYY-MM-DD';
    let dateString: string = this.formatDate(date, format);
    return this.formatDate(this.createdAt, format) == dateString;
  }

  override get isMaking(): boolean {
    return this.step < PSWorksheet.STEP_EXECUTION;
  }

  get canSave(): boolean {
    return this.problem.length > 0;
  }

  get isCompletion(): boolean {
    return this.step == PSWorksheet.STEP_COMPLETED;
  }

  get isExecution(): boolean {
    return this.step == PSWorksheet.STEP_EXECUTION;
  }

  get selectedIdea(): PSWorksheetIdea | undefined {
    return this.ideas.find((e) => {
      return e.isSelected;
    });
  }

  get selectedIdeaIndex(): number {
    return this.ideas.findIndex((e) => {
      return e.isSelected;
    });
  }

  get validIdeasCount(): number {
    let validIdeasCount = 0;
    this.ideas.forEach((idea) => {
      if (idea.title && idea.title.length > 0 && !idea.invalid) {
        validIdeasCount++;
      }
    });

    return validIdeasCount;
  }

  get hasMinValidIdeas(): boolean {
    return this.validIdeasCount >= PSWorksheet.MIN_VALID_IDEA_COUNT;
  }

  get emptyIdeasCount(): number {
    let emptyIdeaCount = 0;
    this.ideas.forEach((idea) => {
      if (!idea.invalid && (!idea.title || idea.title.length == 0)) {
        emptyIdeaCount += 1;
      }
    });
    return emptyIdeaCount;
  }

  addIdea(): void {
    this.ideas.push(new PSWorksheetIdea());
    this.sortIdeaOrderByInvalid();
  }

  selectIdea(ideaIndex: number): void {
    this.ideas.forEach((idea, index) => {
      if (index == ideaIndex) {
        idea.selected = true;
      } else {
        idea.selected = false;
      }
    });
  }

  deleteEmptyIdea(): void {
    this.ideas.forEach((idea, index) => {
      if (!idea.title || idea.title.length == 0) {
        this.ideas.splice(index, 1);
      }
    });
  }

  sortIdeaOrderByInvalid(): void {
    const validIdeas: PSWorksheetIdea[] = [];
    const invalidIdeas: PSWorksheetIdea[] = [];
    this.ideas.forEach((idea, index) => {
      if (idea.invalid) {
        invalidIdeas.push(idea);
      } else {
        validIdeas.push(idea);
      }
    });
    this.ideas = validIdeas.concat(invalidIdeas);
  }

  override revert(originalData: any): void {
    super.revert(originalData);
    if (originalData.problem) this.problem = originalData.problem;
    else this.problem = '';
    if (originalData.goal) this.goal = originalData.goal;
    else this.goal = '';
    if (originalData.step) this.step = originalData.step;
    else this.step = 0;

    this.ideas = [];
    if (originalData.ideas && originalData.ideas.length > 0) {
      originalData.ideas.forEach((e: any) => {
        this.ideas.push(new PSWorksheetIdea(e));
      });
    }
  }

  override copy(): PSWorksheet {
    const copiedItem = this.toJSON();
    delete copiedItem.worksheetId;
    // 完了、実行中のシートは作成の最終工程としてコピー
    if (copiedItem.step >= PSWorksheet.STEP_EXECUTION) {
      copiedItem.step = PSWorksheet.STEP_EXECUTION - 1;
      copiedItem.ideas = copiedItem.ideas.map((idea: any) => {
        idea.memo = '';
        idea.helpfulness = 0;
        return idea;
      });
    }
    return new PSWorksheet(copiedItem);
  }
}

export class PSWorksheetIdea {
  public static readonly THRESHOLD_HELPFULNESS = 5;

  title!: string;
  merits: Array<Merit>;
  demerits: Array<Demerit>;
  when!: string;
  where!: string;
  memo!: string;
  helpfulness!: number;
  selected!: Boolean;
  invalid!: Boolean;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }
    if (!this.invalid) this.invalid = false;

    this.merits = [];
    if (fields && fields.merits && fields.merits.length > 0) {
      fields.merits.forEach((e: any) => {
        this.merits.push(new Merit(e));
      });
    }

    this.demerits = [];
    if (fields && fields.demerits && fields.demerits.length > 0) {
      fields.demerits.forEach((e: any) => {
        this.demerits.push(new Demerit(e));
      });
    }
  }

  get importances(): Array<Merit | Demerit> {
    const items = [ ...this.merits, ...this.demerits];
    items.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
    return items;
  }

  get totalImportance(): number {
    return this.merit - this.demerit;
  }

  get hasMerit(): boolean {
    return this.merits && this.merits.length > 0;
  }

  get merit(): number {
    let totalMerit: number = 0;
    if (this.merits) {
      this.merits.forEach((e) => {
        if (e.value) {
          totalMerit += e.value;
        }
      });
    }
    return totalMerit;
  }

  get hasDemerit(): boolean {
    return this.demerits && this.demerits.length > 0;
  }

  get demerit(): number {
    let totalDemerit: number = 0;
    if (this.demerits) {
      this.demerits.forEach((e) => {
        if (e.value) {
          totalDemerit += e.value;
        }
      });
    }
    return totalDemerit;
  }

  get isHelpfulness(): boolean {
    if (!this.helpfulness) {
      return false;
    }
    if (this.helpfulness <= PSWorksheetIdea.THRESHOLD_HELPFULNESS) {
      return false;
    }
    return true;
  }

  get isSelected(): boolean {
    return !this.invalid && this.selected && this.selected.valueOf();
  }

  addMerit(): void {
    if (this.merits) {
      this.merits.push(new Merit());
    } else {
      this.merits = [new Merit()];
    }
  }

  addDemerit(): void {
    if (this.demerits) {
      this.demerits.push(new Demerit());
    } else {
      this.demerits = [new Demerit()];
    }
  }

  toJSON(): any {
    const json: any = {};

    if (this.title) json.title = this.title;
    if (this.when) json.when = this.when;
    if (this.where) json.where = this.where;

    if (this.memo) json.memo = this.memo;
    if (this.helpfulness) json.helpfulness = this.helpfulness;
    if (this.selected) json.selected = this.selected;
    if (this.invalid) json.invalid = this.invalid;

    if (this.merits && this.merits.length > 0) {
      json.merits = [];
      this.merits.forEach((merit) => {
        json.merits.push(merit.toJSON());
      });
    }

    if (this.demerits && this.demerits.length > 0) {
      json.demerits = [];
      this.demerits.forEach((demerit) => {
        json.demerits.push(demerit.toJSON());
      });
    }
    return json;
  }
}

export abstract class Importance {
  value!: number;
  description!: string;
  type!: string;
  createdAt!: Date;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }

    if (!this.createdAt) {
      this.createdAt = new Date();
    }

    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }
  }

  toJSON(): any {
    const json: any = {};
    if (this.value) json.value = this.value;
    if (this.description) json.description = this.description;
    if (this.createdAt) json.createdAt = this.createdAt.toISOString();

    return json;
  }
}

export class Merit extends Importance {
  public static readonly TYPE = 'merit';

  constructor(fields?: any) {
    super(fields);

    this.type = Merit.TYPE;
  }
}

export class Demerit extends Importance {
  public static readonly TYPE = 'demerit';

  constructor(fields?: any) {
    super(fields);

    this.type = Demerit.TYPE;
  }
}
