import { SMWorksheet } from './sm-worksheet';

export class CRWorksheet extends SMWorksheet {
  public static readonly INPUT_TEXT_VALUE_NONE: string = 'INPUT_TEXT_VALUE_NONE';

  public static readonly LESSONINPUT_KEY_AUTO_THOUGHT = 'autoThought';
  public static readonly LESSONINPUT_KEY_REBUTTAL1 = 'rebuttal1';
  public static readonly LESSONINPUT_KEY_REBUTTAL2 = 'rebuttal2';
  public static readonly LESSONINPUT_KEY_REBUTTAL3 = 'rebuttal3';
  public static readonly LESSONINPUT_KEY_FEELING = 'feeling';
  public static readonly LESSONINPUT_KEY_FEELING_LABEL = 'feelingLabel';
  public static readonly LESSONINPUT_KEY_FEELING_LEVEL = 'feelingLevel';
  public static readonly LESSONINPUT_KEY_AUTO_THOUGHT_PROBABILITY = 'autoThoughtProbability';
  public static readonly LESSONINPUT_KEY_REBUTTAL_PROBABILITY = 'rebuttalProbability';

  public static readonly LESSONINPUT_COMMON_KEYS = [
    CRWorksheet.LESSONINPUT_KEY_AUTO_THOUGHT,
    CRWorksheet.LESSONINPUT_KEY_REBUTTAL1,
    CRWorksheet.LESSONINPUT_KEY_REBUTTAL2,
    CRWorksheet.LESSONINPUT_KEY_REBUTTAL3,
    CRWorksheet.LESSONINPUT_KEY_FEELING_LEVEL
  ]

  public static readonly LESSONINPUT_TARGET_PROBABILITY = 'probability';

  probability!: {
    autoThought: string | null;
    autoThoughtProbability: number;
    rebuttal1: string | null;
    rebuttal2: string | null;
    rebuttal3: string | null;
    rebuttalProbability: number;
    feelingLevel: number;
    result: string | null;
    progress: number;
    done: boolean;
  };

  objectivity!: {
    autoThought: string | null;
    rebuttal1: string | null;
    rebuttal2: string | null;
    rebuttal3: string | null;
    feelingLevel: number;
    result: string | null;
    progress: number;
    done: boolean;
  };

  positiveAction!: {
    autoThought: string | null;
    rebuttal1: string | null;
    rebuttal2: string | null;
    rebuttal3: string | null;
    feelingLevel: number;
    result: string | null;
    progress: number;
    done: boolean;
  };

  constructor(fields?: any) {
    super(fields);

    if (!this.probability) {
      this.probability = this.blankProbability;
    }

    if (!this.objectivity) {
      this.objectivity = this.blankObjectivity;
    }

    if (!this.positiveAction) {
      this.positiveAction = this.blankPositiveAction;
    }
  }

  override toJSON() {
    let json: any = super.toJSON();

    // 簡単なコピー オブジェクトがない場合有効
    if (this.probability)
      json.probability = JSON.parse(JSON.stringify(this.probability));
    if (this.objectivity)
      json.objectivity = JSON.parse(JSON.stringify(this.objectivity));
    if (this.positiveAction)
      json.positiveAction = JSON.parse(JSON.stringify(this.positiveAction));

    return json;
  }

  get canReconstruct(): boolean {
    return (
      this.trigger.length > 0 &&
      this.feeling.length > 0 &&
      this.feelingLevel > 0 &&
      this.thought.length > 0 &&
      this.date != null
    );
  }

  override get isMaking(): boolean {
    return (
      !this.probability.done ||
      !this.objectivity.done ||
      !this.positiveAction.done
    );
  }

  override get canSave() {
    return true;
  }

  get isEnableRecap(): boolean {
    return (
      this.probability.done || this.objectivity.done || this.positiveAction.done
    );
  }

  get blankProbability() {
    return {
      autoThought: null,
      autoThoughtProbability: 0,
      rebuttal1: null,
      rebuttal2: null,
      rebuttal3: null,
      rebuttalProbability: 0,
      feelingLevel: this.feelingLevel,
      result: 'nochange',
      progress: 0,
      done: false,
    };
  }

  get blankObjectivity() {
    return {
      autoThought: null,
      rebuttal1: null,
      rebuttal2: null,
      rebuttal3: null,
      feelingLevel: this.feelingLevel,
      result: 'nochange',
      progress: 0,
      done: false,
    };
  }

  get blankPositiveAction() {
    return {
      autoThought: null,
      rebuttal1: null,
      rebuttal2: null,
      rebuttal3: null,
      feelingLevel: this.feelingLevel,
      result: 'nochange',
      progress: 0,
      done: false,
    };
  }

  get result(): string {
    let feelingIsPositive = this.feeling === 'happy';
    var bestJudge = 0;

    if (this.probability.done) {
      let probabilityJudge = this.judgeFeelingLevel(
        this.feelingLevel,
        this.probability.feelingLevel,
        feelingIsPositive
      );
      bestJudge = bestJudge >= probabilityJudge ? bestJudge : probabilityJudge;
    }
    if (this.objectivity.done) {
      let objectivityJudge = this.judgeFeelingLevel(
        this.feelingLevel,
        this.objectivity.feelingLevel,
        feelingIsPositive
      );
      bestJudge = bestJudge >= objectivityJudge ? bestJudge : objectivityJudge;
    }
    if (this.positiveAction.done) {
      let positiveActionJudge = this.judgeFeelingLevel(
        this.feelingLevel,
        this.positiveAction.feelingLevel,
        feelingIsPositive
      );
      bestJudge =
        bestJudge >= positiveActionJudge ? bestJudge : positiveActionJudge;
    }
    return this.judgeToJudgeText(bestJudge);
  }

  targetRecapResult(targetRecap: string): string {
    console.log(targetRecap);
    return 'good';
  }

  updateRecapFeelingLevel(targetRecap: string, feelingLevel: number) {
    const recap = (this as any)[targetRecap];
    recap.feelingLevel = feelingLevel;
    recap.result = this.judgeToJudgeText(
      this.judgeFeelingLevel(
        this.feelingLevel,
        recap.feelingLevel,
        this.feeling == 'happy'
      )
    );
  }

  override revert(originalData: any) {
    super.revert(originalData);
    if (originalData.probability) this.probability = originalData.probability;
    else this.probability = this.blankProbability;
    if (originalData.objectivity) this.objectivity = originalData.objectivity;
    else this.objectivity = this.blankObjectivity;
    if (originalData.positiveAction)
      this.positiveAction = originalData.positiveAction;
    else this.positiveAction = this.positiveAction;
  }

  private judgeToJudgeText(judge: number): string {
    if (judge == 0) {
      return 'bad';
    } else if (judge == 1) {
      return 'nochange';
    } else if (judge == 2) {
      return 'good';
    } else {
      throw 'Invalid number';
    }
  }

  private judgeFeelingLevel(
    levelBefore: number,
    levelAfter: number,
    isPositive: boolean
  ): number {
    var judge = 0;
    if (isPositive) {
      if (levelBefore < levelAfter || levelAfter == 5) {
        judge = 2;
      } else if (levelBefore == levelAfter) {
        judge = 1;
      } else {
        judge = 0;
      }
    } else {
      if (levelBefore > levelAfter) {
        judge = 2;
      } else if (levelBefore == levelAfter) {
        judge = 1;
      } else {
        judge = 0;
      }
    }
    return judge;
  }

  setLessonInputValue(target:string, key:string, value: any): void {
    if ((typeof(value) === 'number') || value && value.length > 0) {
      (this as any)[target][key] = value;
    } else if (!value) {
      (this as any)[target][key] = CRWorksheet.INPUT_TEXT_VALUE_NONE;
    }
    if (key == CRWorksheet.LESSONINPUT_KEY_FEELING_LEVEL) {
      this.updateRecapFeelingLevel(target, value);
    }
  }

  toLessonInputs(target:string): any {
    const item = (this as any)[target];
    const lessonInputs: any = {};

    lessonInputs[CRWorksheet.LESSONINPUT_KEY_FEELING] = this.feeling;
    lessonInputs[CRWorksheet.LESSONINPUT_KEY_FEELING_LABEL] = this.displayFeelingString;

    for (const key of CRWorksheet.LESSONINPUT_COMMON_KEYS) {
      lessonInputs[key] = item[key];
    }

    if (target == CRWorksheet.LESSONINPUT_TARGET_PROBABILITY) {
      if (lessonInputs[CRWorksheet.LESSONINPUT_KEY_AUTO_THOUGHT] && item[CRWorksheet.LESSONINPUT_KEY_AUTO_THOUGHT_PROBABILITY] >= 0) {
        lessonInputs[CRWorksheet.LESSONINPUT_KEY_AUTO_THOUGHT_PROBABILITY] = item[CRWorksheet.LESSONINPUT_KEY_AUTO_THOUGHT_PROBABILITY];
      }
      if (lessonInputs[CRWorksheet.LESSONINPUT_KEY_REBUTTAL1] && item[CRWorksheet.LESSONINPUT_KEY_REBUTTAL_PROBABILITY] >= 0) {
        lessonInputs[CRWorksheet.LESSONINPUT_KEY_REBUTTAL_PROBABILITY] = item[CRWorksheet.LESSONINPUT_KEY_REBUTTAL_PROBABILITY];
      }
    }

    return lessonInputs;
  }
}
