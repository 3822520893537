export class ConsultationStates {
  items!: { [id: string]: ConsultationState };
  createdAt!: Date;
  updatedAt!: Date;

  constructor(fields: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }

    if (this.items) {
      for (const id in this.items) {
        this.items[id] = new ConsultationState(this.items[id]);
      }
    }

    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }

    if (typeof this.updatedAt === 'string') {
      this.updatedAt = new Date(this.updatedAt);
    }

    if (!this.items) {
      this.items = {};
    }

    if (!this.createdAt) {
      this.createdAt = new Date();
    }

    if (!this.updatedAt) {
      this.updatedAt = new Date();
    }
  }

  toJSON() {
    const json: any = {};
    if (this.items) {
      json.items = {};
      for (const id in this.items) {
        json.items[id] = this.items[id].toJSON();
      }
    }
    if (this.createdAt) json.createdAt = this.createdAt.toISOString();
    if (this.updatedAt) json.updatedAt = this.updatedAt.toISOString();
    return json;
  }

  get(id: string): ConsultationState | undefined {
    return this.items[id];
  }

  set(id: string, state: ConsultationState): void {
    this.items[id] = state;
  }
}

export class ConsultationState {
  progress!: number;
  startedAt!: Date;
  finishedAt!: Date;
  evaluation!: string;

  constructor(fields: any) {
    for (const f in fields) {
      this[(f as keyof this)] = fields[f];
    }

    if (this.progress == undefined) this.progress = 0;
    if (fields['startedAt']) this.startedAt = new Date(fields['startedAt']);
    if (fields['finishedAt']) this.finishedAt = new Date(fields['finishedAt']);
  }

  toJSON() {
    let json: any = {};
    if (this.progress) json.progress = this.progress;
    if (this.startedAt) json.startedAt = this.startedAt.toISOString();
    if (this.finishedAt) json.finishedAt = this.finishedAt.toISOString();
    if (this.evaluation) json.evaluation = this.evaluation;
    return json;
  }
}
