import { Survey } from './survey';
import { AppConfig } from '../app.config';

export class SelfCheck extends Survey {
  public static CORE_ANSWER_INDEX: number = 3;
  public static DEADLINE_DAYS: number[] = [
    0, 14, 22, 29
  ];
  public static INTEX_TO_WEEK: number[] = [
    0, 2, 4, 8
  ];

  public static indexToWeek(index: number): number {
    if (index < SelfCheck.INTEX_TO_WEEK.length) {
      return SelfCheck.INTEX_TO_WEEK[index];
    }
    return 0;
  }

  private static readonly ALERT_PHQ9_POINT: number = 10;
  private static readonly ALERT_PHQ9_ANSWER_ID: string = 'PHQ9_9';
  private static readonly ALERT_PHQ9_ANSWER_POINT: number = 2;

  updatedAt: Date | undefined;
  deletedAt: Date | undefined;

  canAnswerAt: Date | undefined;
  notifyAt: Date | undefined;
  notices: Date[] | undefined;
  alertAt: Date | undefined;

  title!: string;
  answerIndex!: number;
  type!: string;

  username!: string;

  constructor(fields: any) {
    delete fields['answerIndexType'];
    super(fields);

    if (!this.createdAt) {
      this.createdAt = new Date();
    }

    if (!this.updatedAt) {
      this.updatedAt = new Date();
    }

    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }

    if (typeof this.updatedAt === 'string') {
      this.updatedAt = new Date(this.updatedAt);
    }

    if (typeof this.deletedAt === 'string') {
      this.deletedAt = new Date(this.deletedAt);
    }

    if (typeof this.canAnswerAt === 'string') {
      this.canAnswerAt = new Date(this.canAnswerAt);
    }

    if (typeof this.answeredAt === 'string') {
      this.answeredAt = new Date(this.answeredAt);
    }

    if (typeof this.notifyAt === 'string') {
      this.notifyAt = new Date(this.notifyAt);
    }

    if (Array.isArray(this.notices)) {
      this.notices = this.notices.map(
        notice => typeof notice === 'string' ? new Date(notice) : notice
      );
    }

    if (typeof this.alertAt === 'string') {
      this.alertAt = new Date(this.alertAt);
    }
  }

  get isInitSelfCheck(): boolean {
    return this.answerIndex == undefined || this.answerIndex == 0;
  }

  get answerIndexType() {
    return `${('00' + this.answerIndex.toString()).slice(-2)}-${this.type}`;
  }

  get displayWeek() {
    return SelfCheck.indexToWeek(this.answerIndex);
  }

  setResult(results:any[]): void {
    this.answers = results;
    const answers = this.organizedAnswers;
    this.PHQ9Point = this.getPHQ9Point(answers);
    this.GAD7Point = this.getGAD7Point(answers);
    this.CBTPoint = this.getCBTPoint(answers);
  }

  setAlert(): void {
    if (this.shouldAlert) {
      this.alertAt = new Date(AppConfig.now());
      this.alertAt.setDate(this.alertAt.getDate() + 1); //翌日
      this.alertAt.setHours(0, 0, 0, 0);
    }
  }

  override toJSON() {
    const json = super.toJSON();

    if (this.updatedAt) json.updatedAt = this.updatedAt.toISOString();
    if (this.deletedAt) json.deletedAt = this.deletedAt.toISOString();

    if (this.canAnswerAt) json.canAnswerAt = this.canAnswerAt.toISOString();
    if (this.notifyAt) json.notifyAt = this.notifyAt.toISOString();
    if (this.notices) json.notices = this.notices.map(notice => notice.toISOString());
    if (this.alertAt) json.alertAt = this.alertAt.toISOString();

    if (this.title) json.title = this.title;
    if (this.answerIndex != null) json.answerIndex = this.answerIndex;
    if (this.type) json.type = this.type;
    if (this.answerIndex != null && this.type) json.answerIndexType = this.answerIndexType;

    if (this.username) json.username = this.username;

    return json;
  }

  get shouldAlert(): boolean {
    const answers = this.organizedAnswers;
    if (!answers) {
      return false;
    }

    const PHQ9Point = this.getPHQ9Point(answers);
    if (PHQ9Point == undefined || PHQ9Point < SelfCheck.ALERT_PHQ9_POINT) {
      return false;
    }

    const item = answers.PHQ9.find((e: { itemId: string; }) => e.itemId == SelfCheck.ALERT_PHQ9_ANSWER_ID);
    if (item == undefined || item.answer < SelfCheck.ALERT_PHQ9_ANSWER_POINT) {
      return false;
    }

    return true;
  }

  get deadline(): Date {
    const to = new Date(this.canAnswerAt as Date);
    if (this.answerIndex < SelfCheck.DEADLINE_DAYS.length) {
      const deadlineDay = SelfCheck.DEADLINE_DAYS[this.answerIndex];
      to.setDate(to.getDate() + deadlineDay);  
    }

    to.setMinutes(to.getMinutes() - 1);
    return to;
  }
}
