import { Worksheet } from './worksheet';

export class BAMission extends Worksheet {
  missionId: string | undefined;
  title: string | undefined;
  detail: string | undefined;
  category: string | undefined;
  recommend: number | undefined;

  constructor(fields?: any) {
    super(fields);
  }

  override toJSON(): any {
    let json: any = super.toJSON();

    if (this.missionId) json.missionId = this.missionId;
    if (this.title) json.title = this.title;
    if (this.detail) json.detail = this.detail;
    if (this.category) json.category = this.category;
    if (this.recommend) json.recommend = this.recommend;

    return json;
  }

  toContent(): any {
    let json: any = super.toJSON();
    if (this.missionId) json.missionId = this.missionId;
    if (this.title) json.title = this.title;
    if (this.detail) json.detail = this.detail;
    if (this.category) json.category = this.category;
    if (this.recommend) json.recommend = this.recommend;
    delete json.worksheetId;
    delete json.createdAt;
    delete json.updatedAt;
    delete json.deletedAt;

    return json;
  }

  get canSave(): boolean {
    if (
      this.missionId && this.missionId.length > 0 &&
      this.title && this.title.length > 0 &&
      this.detail && this.detail.length > 0 &&
      this.category && this.category.length > 0
    ) {
      return true;
    }
    return false;
  }
}
