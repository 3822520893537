import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { WorksheetService } from '../worksheet.service';
import { Worksheet } from '../../../models/worksheet';
import { BAMission } from '../../../models/ba-mission';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BAMissionService extends WorksheetService {
  protected readonly table = environment.aws.resourceNamePrefix + '-BAMission';

  constructor(dbServ: DynamoDBService) {
    super(dbServ);
  }

  override get scanIndexForward(): boolean {
    return true;
  }

  createWorksheet(item: any) {
    return new BAMission(item);
  }

  override async query(force?: boolean): Promise<BAMission[]> {
    if (!force && this.items != null) {
      console.log('BAMissionService: BAMission is loaded');
      return this.items as BAMission[];
    }

    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params: any = {
        TableName: this.table,
        KeyConditionExpression: '#userId = :userId',
        ExpressionAttributeNames: {
          '#userId': 'userId',
        },
        ExpressionAttributeValues: {
          ':userId': 'master',
        },
        FilterExpression: 'attribute_not_exists(deletedAt)',
        ScanIndexForward: this.scanIndexForward,
      };

      const result = await doc.query(params).promise();
      const items = result.Items;

      this.items = [];
      if (items) {
        this.items = [...items.map((item) => this.createWorksheet(item))];
        this.sort();
      }
      return this.items as BAMission[];
    } catch (error) {
      console.log('BAMissionService: query error', error);
      throw error;
    }
  }

  override sort(): void {
    (this.items as BAMission[]).sort((a, b) => {
      if (b.recommend && a.recommend) return b.recommend - a.recommend;
      if (!b.recommend && a.recommend) return -1;
      if (b.recommend && !a.recommend) return 1;
      return 0;
    });
  }

  override async update(worksheet: Worksheet): Promise<any> {}

  override async add(worksheet: Worksheet): Promise<any> {}

  override async delete(worksheet: Worksheet): Promise<any> {}

  override async deleteAll(): Promise<any> {}
}
