import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { AppConfig } from '../../app.config';
import { ModuleService } from '../module/module.service';

export enum AuthStatus {
  YET,
  EMAIL_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  EMAIL_VERIFICATION_REQUIRED,
  DONE
}

export abstract class BaseUserService {
  static readonly STATUS_MAIN: string = 'main';
  static readonly STATUS_SURVEY: string = 'survey';

  static readonly ATTR_STATUS: string = 'custom:status';
  static readonly ATTR_EMAIL: string = 'email';
  static readonly ATTR_EMAIL_VERIFIED: string = 'email_verified';
  static readonly ATTR_NICKNAME: string = 'nickname';
  static readonly ATTR_AVATAR: string = 'custom:avatar';
  static readonly ATTR_ASSIGNMENT: string = 'custom:assignment';
  static readonly ATTR_ENTRY: string = 'custom:entry';
  static readonly ATTR_ACTIVATED_AT: string = 'custom:activatedAt';
  static readonly ATTR_STARTED_AT: string = 'custom:startedAt';
  static readonly ATTR_IDENTITY_ID: string = 'custom:identityId';
  static readonly ATTR_APP_VERSION: string = 'custom:appVersion';
  static readonly ATTR_MORNING_NOTIFICATION: string = 'custom:morningNotification';
  static readonly ATTR_EVENING_NOTIFICATION: string = 'custom:eveningNotification';
  static readonly ATTR_BI_NOTIFICATION: string = 'custom:biNotification';
  static readonly ATTR_BI_NOTIFICATION_FLAG: string = 'custom:biNotificationFlag';

  static readonly DEFAULT_ATTR_MORNING_NOTIFICATION: string = '08:00';
  static readonly DEFAULT_ATTR_EVENING_NOTIFICATION: string = '18:00';
  static readonly DEFAULT_ATTR_BI_NOTIFICATION: string = '08:00';
  static readonly DEFAULT_ATTR_BI_NOTIFICATION_FLAG: string = 'false';

  static readonly EVENT_AUTHID = 'user:auth';

  abstract isSpecial(): boolean;

  abstract signUp(username: string, password: string, email: string): Promise<any>;
  abstract confirmSignUp(username: string, code: string): Promise<any>;
  abstract resendSignUp(username: string): Promise<any>;
  abstract signIn(username: string, password: string): Promise<AuthStatus>;
  abstract signOut(): Promise<any>;
  abstract isAuthenticated(): Promise<AuthStatus>;
  abstract refreshSession(): Promise<any>;
  abstract loadAttributes(): Promise<void>;
  abstract updateAttributes(attr: {[x: string]: string;}): Promise<void>;
  abstract completeNewPasswordChallenge(password: string, attributes?: object): Promise<any>;
  abstract verifyEmail(): Promise<void>;
  abstract verifyEmailSubmit(code: string): Promise<string>;
  abstract changePassword(oldPassword: string, newPassword: string): Promise<string>;
  abstract forgotPassword(username: string): Promise<any>;
  abstract forgotPasswordSubmit(username: string, confirmationCode: string, newPassword: string): Promise<string>;
  abstract assignModule(): Promise<void>;

  protected user: any = null;
  protected attributes: {[name: string]: string} = {};
  protected authStatus: AuthStatus = AuthStatus.YET;

  constructor() { }

  getUser(): any {
    return this.user;
  }

  getAttribute(key: string): string {
    return this.attributes[key];
  }

  getAuthStatus(): AuthStatus {
    return this.authStatus;
  }
}

const SESSION_REFRESH_INTERVAL = 30 * 60 * 1000;
const ASSIGNMENT_API_NAME = 'AssignmentAPI';
const ASSIGNMENT_API_PATH = '/items';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseUserService {
  private sessionRefreshTimer: any;

  constructor() {
    super();
    this.sessionRefreshTimer = null;
  }

  isSpecial(): boolean {
    if (!this.user) return false;
    const username = this.user.username;
    return username && username.indexOf(AppConfig.SPECIAL_USERNAME_PREFIX) == 0;
  }

  async signUp(username: string, password: string, email: string): Promise<any> {
    try {
      const result = await Auth.signUp({
        username,
        password,
        attributes: {
          email
        }
      });
      console.log('signUp success:', result);
      return result;
    } catch(error) {
      console.log('signUp failure:', error);
      throw error;
    }
  }

  async confirmSignUp(username: string, code: string): Promise<any> {
    try {
      const result = await Auth.confirmSignUp(username, code);
      console.log('confirmSignUp success:', result);
      return result;
    } catch(error) {
      console.log('confirmSignUp failure:', error);
      throw error;
    }
  }

  async resendSignUp(username: string): Promise<any> {
    try {
      const result = await Auth.resendSignUp(username);
      console.log('resendSignUp success:', result);
      return result;
    } catch(error) {
      console.log('resendSignUp failure:', error);
      throw error;
    }
  }

  async signIn(username: string, password: string): Promise<AuthStatus> {
    try {
      this.user = await Auth.signIn(username, password);
      const requiredAttributes = this.user.challengeParam?.requiredAttributes;
      if (requiredAttributes?.find((e: string) => e === 'email')) {
        console.log('emailRequired');
        this.authStatus = AuthStatus.EMAIL_REQUIRED;
        return this.authStatus;
      }
      else if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('newPasswordRequired');
        this.authStatus = AuthStatus.NEW_PASSWORD_REQUIRED;
        return this.authStatus;
      }
      return await this.isAuthenticated();
    } catch(error: any) {
      if (error.code == 'UserNotConfirmedException') {
        this.authStatus = AuthStatus.EMAIL_VERIFICATION_REQUIRED;
        return this.authStatus;
      }
      console.log(error);
      throw error;
    }
  }

  async signOut(): Promise<any> {
    this.user = null;
    this.attributes = {};
    this.authStatus = AuthStatus.YET;

    if (this.sessionRefreshTimer) {
      clearInterval(this.sessionRefreshTimer);
      this.sessionRefreshTimer = null;
    }

    return Auth.signOut();
  }

  async isAuthenticated(): Promise<AuthStatus> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        this.authStatus = AuthStatus.YET;
        return this.authStatus;
      }

      this.user = user;
      await this.loadAttributes();

      const emailVerified = this.getAttribute('email_verified');
      if (!emailVerified || emailVerified == 'false') {
        this.authStatus = AuthStatus.EMAIL_VERIFICATION_REQUIRED;
      }
      else {
        this.authStatus = AuthStatus.DONE;
      }

      // ユーザー属性に appVersion または identityId が無ければユーザー属性に保存
      let appVersion = AppConfig.VERSION;
      if (Capacitor.isNativePlatform()) {
        try {
          appVersion = (await App.getInfo()).version;
        } catch (err) {
          console.log(err);
        }
      }

      const attr:any = {};
      if (appVersion) {
        const attrAppVersion = this.getAttribute(UserService.ATTR_APP_VERSION);
        console.log(`appVersion:${appVersion} attribute appVersion: ${attrAppVersion}`);
        if (attrAppVersion != appVersion) {
          console.log('custom:appVersion is updated');
          attr[UserService.ATTR_APP_VERSION] = appVersion;
        }
      }

      let identityId = this.getAttribute(UserService.ATTR_IDENTITY_ID);
      if (!identityId) {
        identityId = (await Auth.currentCredentials()).identityId;
        attr[UserService.ATTR_IDENTITY_ID] = identityId;
      }

      if (AppConfig.SKIP_ACTIVATION) {
        attr[UserService.ATTR_ACTIVATED_AT] = new Date(AppConfig.now()).toISOString();
      }

      if (attr[UserService.ATTR_APP_VERSION]
        || attr[UserService.ATTR_IDENTITY_ID]
        || attr[UserService.ATTR_ACTIVATED_AT]) {
        await this.updateAttributes(attr);
      }

      console.log(`#### identityId:${identityId} ####`);  
      this.setSessionRefresh();

      return this.authStatus;
    } catch(error) {
      console.log('authentication failed:', error);
      return AuthStatus.YET;
    }
  }

  async refreshSession(): Promise<any> {
    if (this.authStatus != AuthStatus.DONE) {
      console.log('user have not logged in');
      return;
    }

    const session = await Auth.currentSession();
    if (session.isValid()) {
      console.log('session update is unnecessary');
      return;
    }

    console.log(`#### refresh session:${this.authStatus} ####`);
    const result = await this.updateSession();
    this.setSessionRefresh();

    return result;
  }

  private setSessionRefresh() {
    if (this.sessionRefreshTimer) {
      console.log('clear session refresh timer');
      clearInterval(this.sessionRefreshTimer);
    }

    this.sessionRefreshTimer = setInterval(async () => {
      try {
        if (this.authStatus != AuthStatus.DONE) {
          return;
        }
  
        console.log(`#### refresh session:${AuthStatus[this.authStatus]} ####`);
        await this.updateSession();
      } catch(err) {
        console.log('update session err', err);
      }
    }, SESSION_REFRESH_INTERVAL);
  }

  private async updateSession(): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        console.log('can not find user');
        return;
      }

      const session = await Auth.currentSession();
      console.log('refresh session successfully.');

      return session;
    } catch (error) {
      console.log('Failed to update session', error);
      throw error;
    }
  }

  async loadAttributes(): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.userAttributes(user);
    const attributes: {[name: string]: string} = {};
    for (let i = 0; i < result.length; i++) {
      const name = result[i].getName();
      const value = result[i].getValue();
      console.log(`${name}:${value}`);
      attributes[name] = value;
    }
    this.attributes = attributes;
  }

  async updateAttributes(attr: { [x: string]: string; }): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, attr);
  
      for (let x in attr) {
        this.attributes[x] = attr[x];
      }
    } catch (error) {
      console.log('updateAttributes error:', error);
      throw error;
    }
  }

  async completeNewPasswordChallenge(password: string, attributes?: object): Promise<any> {
    try {
      const result = await Auth.completeNewPassword(this.user, password, attributes);
      console.log('completeNewPasswordChallenge success:', result);
      return result;
    } catch(error) {
      console.log('completeNewPasswordChallenge failure:', error);
      throw error;
    }
  }

  async verifyEmail(): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttribute('email');
      console.log('verifyEmail success:');
    } catch(error) {
      console.log('verifyEmail failure:', error);
      throw error;
    }
  }

  async verifyEmailSubmit(code: string): Promise<string> {
    try {
      const result = await Auth.verifyCurrentUserAttributeSubmit('email', code);
      console.log('verifyEmailSubmit success:', result);
      if (this.authStatus == AuthStatus.EMAIL_VERIFICATION_REQUIRED) {
        await this.isAuthenticated();
      }
      return result;
    } catch(error) {
      console.log('verifyEmailSubmit failure:', error);
      throw error;
    }
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<string> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.changePassword(user, oldPassword, newPassword);
      console.log('changePassword success:', result);
      return result;
    } catch(error) {
      console.log('changePassword failure:', error);
      throw error;
    }
  }

  async forgotPassword(username: string): Promise<any> {
    try {
      const result = await Auth.forgotPassword(username);
      console.log('forgotPassword success:', result);
      return result;
    } catch(error) {
      console.log('forgotPassword failure:', error);
      throw error;
    }
  }

  async forgotPasswordSubmit(
    username: string,
    confirmationCode: string,
    newPassword: string
  ): Promise<string> {
    try {
      const result = await Auth.forgotPasswordSubmit(username, confirmationCode, newPassword);
      console.log('forgotPasswordSubmit success:', result);
      return result;
    } catch(error) {
      console.log('forgotPasswordSubmit failure:', error);
      throw error;
    }
  }

  async assignModule() {
    const isOfficial = ():boolean => {
      if (AppConfig.PRODUCTION && this.isSpecial() === false) {
        return true;
      }
      if (AppConfig.DEBUG_ASSIGINMENT_BOOKING ||
          AppConfig.FIX_ASSIGNMENT ||
          AppConfig.LOCAL_ASSIGNMENT ||
          this.isSpecial()) {
        return false;
      }
      return true;
    }

    const doAssignmentAPI = async () => {
      try {
        const myInit = {
          body: {
            username: this.user.username
          }
        }
        const response = await API.post(ASSIGNMENT_API_NAME, ASSIGNMENT_API_PATH, myInit);
        const result = JSON.parse(response);
        if (result.status === 'newAssign') {
          this.attributes[UserService.ATTR_ASSIGNMENT] = result.assignment;
          // NOTE: smileaya 割当APIでは下記の値は取得できないため、固定値を設定
          this.attributes[UserService.ATTR_ENTRY] = AppConfig.FIX_ENTRY;
        }
        return result;
      } catch (err) {
        console.log('error:', err);
        throw err;
      }
    }

    const updateAssignmentAttributes = async () => {
      const attr:any = {};
      if (AppConfig.DEBUG_ASSIGINMENT_BOOKING) {
        attr[UserService.ATTR_ASSIGNMENT] = AppConfig.DEBUG_ASSIGINMENT_BOOKING;
        AppConfig.DEBUG_ASSIGINMENT_BOOKING = null;
      }
      else if (AppConfig.FIX_ASSIGNMENT || this.isSpecial()) {
        attr[UserService.ATTR_ASSIGNMENT] = AppConfig.FIX_ASSIGNMENT_CODE;
      }
      else if (AppConfig.LOCAL_ASSIGNMENT) {
        const index = Math.floor(Math.random() * ModuleService.assignments.length) + 1;
        attr[UserService.ATTR_ASSIGNMENT] = `C${index}`;
      }
      attr[UserService.ATTR_ENTRY] = AppConfig.FIX_ENTRY;
      await this.updateAttributes(attr);
    }

    if (isOfficial()) {
      await doAssignmentAPI();
    } else {
      await updateAssignmentAttributes();
    }
    console.log(`assignModule: ${this.attributes[UserService.ATTR_ASSIGNMENT]}`);
  }
}