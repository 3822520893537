export class WebPush {
  id: number = -1;
  title!: string;
  body!: string;
  triggerAt!: number;
  token?: string;
  schedule?: any;
  extra?: any;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }
  }

  toJSON() {
    const json: any = {
      id: this.id,
      title: this.title,
      body: this.body,
      triggerAt: this.triggerAt
    };

    if (this.token) json.token = this.token;
    if (this.schedule) json.schedule = this.schedule;
    if (this.extra) json.extra = this.extra;

    return json;
  }
}