import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  public static readonly PRODUCTION: boolean = environment.production;

  public static readonly VERSION: string = "1.1.0";

  //有効化機能利用
  public static readonly SKIP_ACTIVATION: boolean = true;

  //割付
  public static readonly FIX_ASSIGNMENT: boolean = false;
  public static readonly FIX_ASSIGNMENT_CODE = 'C3';
  public static readonly FIX_ENTRY = '';
  public static readonly LOCAL_ASSIGNMENT: boolean = false;

  // ニックネームの長さ
  public static readonly NICKNAME_MIN_LEN = 1;
  public static readonly NICKNAME_MAX_LEN = 8;

  //レッスン
  public static readonly INIT_LESSON_MODULE: string = 'pe';
  public static readonly EPILOGUE_MODULE_PREFIX: string = 'ep';
  public static readonly LESSON_INTERVAL_DAYS: number = 7;
  public static readonly FIRST_LESSON_INTERVAL_DAYS: number = 0;
  public static readonly INTERVAL_EPILOGUE: number = 7 * 8;
  public static readonly NOTIFICATION_BEFORE_DAY: number = 2;
  public static readonly NOTIFICATION_LESSON_INTERVAL_DAYS: number = 7;

  //問い合わせ
  public static readonly INQUIRY_ADDRESS: string =
    'info@smileaya-project.org';
  public static readonly INQUIRY_SUBJECT: string =
    '【レジトレ！ for SMILE AYA】に関するご提案・ご感想';
  public static readonly INQUIRY_BODY: string = `【レジトレ！ for SMILE AYA】のご利用有り難うございます。
私たち開発チームは皆様のご感想をいただき、出来る限り皆様に使っていただきやすいアプリになるよう、より一層の努力を重ねる所存でおります。
アプリの不具合のご指摘、あるいは改善点のご提案など、どのようなフィードバックでも大歓迎ですので、なにとぞ御報告をお願い申し上げます。
なお、個々のご提案全てにご返事できない場合がありますので、あらかじめお詫び申し上げます。
【SMILE-AYA研究事務局】

お名前：__USER_NAME__
ユーザーID：__USER_ID__

※※この下に内容を記入して下さい※※
`;

  //トースト通知時間
  public static readonly TOAST_DURATION: number = 3000;

  //申請用特別アカウント
  public static readonly SPECIAL_USERNAME_PREFIX = 'spuser';

  //待機群ユーザー
  public static WAITING_LIST_USER: boolean = false;

  //有効ワークシート
  public static readonly VALID_WORKSHEETS = [
    'ps'
  ];

  //デバック
  public static DEBUG_ASSIGINMENT_BOOKING: string | null = null;
  public static readonly DEBUG_ACCEPTED_ASSIGINMENT_BOOKING_CODES = [
    'C3',
    'C12_C3'
  ];
  public static DEBUG_LESSON_IMMEDIATE_FINISH: boolean = false;
  public static DEBUG_DUMMY_PRE_SURVEY: boolean = false;
  public static DEBUG_TIME: boolean = false;
  public static DEBUG_TIME_NOW: number = Date.now();
  public static DEBUG_IGNORE_LESSON_UNLOCK_CONDITION: boolean = false;
  public static DEBUG_IGNORE_LESSON_UNLOCK_TIME_CONDITION: boolean = false;
  public static readonly DEBUG_SERVICE_WORKER_REGISTRATION: boolean = true;

  public static now(): number {
    return !AppConfig.PRODUCTION && AppConfig.DEBUG_TIME
      ? AppConfig.DEBUG_TIME_NOW
      : Date.now();
  }
}
