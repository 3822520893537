import { LessonPart, UnlockCondition } from './lesson-part';

export interface RequireFinishedWorksheet {
  type: string;
  condition: string;
  message: string | undefined;
}

export class Lesson {
  lessonId!: string;
  title!: string;
  lessonParts: LessonPart[] = [];

  locked!: boolean;
  hidden!: boolean;
  displayRestriction!: string;
  requireFinishedWorksheet!: RequireFinishedWorksheet;
  unlockConditions!: UnlockCondition[];
  expectedStartDate: Date | undefined;
  unlockDate!: Date;
  finishDate!: Date;

  constructor(fields: any) {
    for (const f in fields) {
      if (f == 'lessonParts') {
        this.lessonParts = fields[f].map((v: any) => new LessonPart(v));
      } else {
        this[(f as keyof this)] = fields[f];
      }
    }

    if (fields['locked'] == undefined) this.locked = true;
    if (fields['hidden'] == undefined) this.hidden = true;
    if (fields['unlockDate']) this.unlockDate = new Date(fields['unlockDate']);
    if (fields['finishDate']) this.finishDate = new Date(fields['finishDate']);
  }

  toJSON() {
    let json: any = {};
    if (this.lessonId) json.lessonId = this.lessonId;
    if (this.lessonParts)
      json.lessonParts = this.lessonParts.map((v) => v.toJSON());
    json.locked = this.locked;
    json.hidden = this.hidden;
    if (this.unlockDate != null) json.unlockDate = this.unlockDate.toISOString();
    if (this.finishDate != null) json.finishDate = this.finishDate.toISOString();
    return json;
  }

  get isAllLessonPartsFinished(): boolean {
    return (
      this.lessonParts.find((v) => !v.disabled && v.finishedAt == null) == null
    );
  }

  get firstLessonPartStartAt(): Date {
    let parts = this.enabledLessonParts;
    return parts[0].startedAt;
  }

  get lastLessonPartFinishedAt(): Date {
    let parts = this.enabledLessonParts;
    return parts[parts.length - 1].finishedAt;
  }

  get enabledLessonParts(): LessonPart[] {
    return this.lessonParts.filter((v) => !v.disabled);
  }
}
