import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { CRWorksheet } from '../../../models/cr-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CRWorksheetService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-CRWorksheet';

  constructor(
    dbServ: DynamoDBService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): CRWorksheet {
    return item ? new CRWorksheet(item) : new CRWorksheet();
  }

  override get indexName(): string {
    return 'DateSorted';
  }

  override sort(): void {
    (this.items as CRWorksheet[])?.sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });
  }
}
