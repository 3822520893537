import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Badge } from '@capawesome/capacitor-badge';

import { AppConfig } from '../../app.config';
import { Events } from '../events/events.service';
import { environment } from '../../../environments/environment';
import { DynamoDBService } from '../database/dynamodb.service';
import { SelfCheck } from '../../models/self-check';

const WeekIndex = {
  w0: 0,
  w2: 1,
  w4: 2,
  w8: 3
};

const InterventionSelfCheckSetting = [
  { type: 'WEEK2', index: WeekIndex.w2, notices:[0, 7, 11], title: 'アンケート' },
  { type: 'WEEK4', index: WeekIndex.w4, notices:[0, 7, 14, 19], title: 'アンケート' },
  { type: 'WEEK8', index: WeekIndex.w8, notices:[0, 7, 14, 21, 26], title: 'アンケート' }
];

const ObservationSelfCheckSetting = [
  { type: 'WEEK2', index: WeekIndex.w2, notices:[0, 7, 11], title: 'アンケート' },
  { type: 'WEEK4', index: WeekIndex.w4, notices:[0, 7, 14, 19], title: 'アンケート' },
  { type: 'WEEK8', index: WeekIndex.w8, notices:[0, 7, 14, 21, 26], title: 'アンケート' }
];

const SelfCheckSettings: {[key: string]: any[]} = {
  'intervention': InterventionSelfCheckSetting,
  'observation': ObservationSelfCheckSetting
};

export abstract class BaseSelfCheckService {
  protected items: SelfCheck[] = [];

  private eventTimer: any;

  constructor(protected events: Events) {}

  get isUninitialized(): boolean {
    return this.items.length == 0;
  }

  get count(): number {
    return this.items.length;
  }

  get selfChecks(): SelfCheck[] {
    this.sort();
    return this.items;
  }

  get requests(): SelfCheck[] {
    const compare = (a: SelfCheck, b: SelfCheck) => {
      const priority = {
        'PHQ9': 1,
        'WEEK2': 2,
        'WEEK4': 3,
        'WEEK8': 4,
      };
      type TypeKey = 'PHQ9' | 'WEEK2' | 'WEEK4' | 'WEEK8';
      const aValue = priority[a.type as TypeKey] ? priority[a.type as TypeKey] : 0;
      const bValue = priority[b.type as TypeKey] ? priority[b.type as TypeKey] : 0;
      return bValue - aValue;
    }

    const now = AppConfig.now();
    return this.items.filter((item) => {
      if (!item.answeredAt && item.canAnswerAt) {
        const from = (item.canAnswerAt as Date).getTime();
        const to = item.deadline.getTime();
        if (from <= now && now < to) {
          console.log(`selfCheckService.requests:${item.answerIndexType} [${item.canAnswerAt}]`);
          return true;
        }
      }
      return false;
    }).sort(compare);
  }

  get firstSelfCheck(): SelfCheck | undefined {
    return this.items.find((v) => v.answerIndex === WeekIndex.w2);
  }

  get startDate(): Date | undefined {
    const firstSelfCheck = this.firstSelfCheck;
    if (!firstSelfCheck) {
      return undefined;
    }
    return firstSelfCheck.canAnswerAt instanceof Date ? firstSelfCheck.canAnswerAt : undefined;
  }

  get answerIndexs(): number[] {
    return this.items
    .filter((v, i, a) => {
      //検出対象外のインデックスか判定
      if (v.answerIndex <= 0) return false;

      //同じインデックス内の先頭か判定
      if (a.findIndex((vv) => vv.answerIndex == v.answerIndex) !== i)
        return false;

      return true;
    })
    .map((v) => v.answerIndex);
  }

  get finishedAnswerIndexs(): number[] {
    return this.items
      .filter((v, i, a) => {
        //終了検出対象外のインデックスか判定
        if (v.answerIndex <= 0) return false;

        //未回答か判定
        if (!v.answeredAt) return false;

        //同じインデックス内の先頭か判定
        if (a.findIndex((vv) => vv.answerIndex == v.answerIndex) !== i)
          return false;

        //同じインデックスで未回答があるか判定
        return (
          a.filter((vv) => {
            return vv.answerIndex == v.answerIndex && !vv.answeredAt;
          }).length == 0
        );
      })
      .map((v) => v.answerIndex);
  }

  isSetupFinished(type:string): boolean {
    return this.items.length === SelfCheckSettings[type].length + 1;
  }

  protected abstract query(force?: boolean): Promise<SelfCheck[]>;

  protected abstract add(selfCheck: SelfCheck): Promise<void>;

  protected abstract addItems(selfChecks: SelfCheck[]): Promise<void>;

  protected abstract update(selfCheck: SelfCheck): Promise<void>;

  protected abstract remove(): Promise<void>;

  protected sort() {
    this.items.sort((a, b) => {
      if (a.answerIndex - b.answerIndex != 0) {
        return a.answerIndex - b.answerIndex;
      } else {
        return (a.createdAt as Date).getTime() - (b.createdAt as Date).getTime();
      }
    });
  }

  protected setupEvent() {
    clearTimeout(this.eventTimer);

    let items = this.items
      .filter((item) => {
        return (
          !item.answeredAt &&
          item.canAnswerAt &&
          item.canAnswerAt.getTime() > AppConfig.now()
        );
      })
      .sort((a, b) => {
        return (a.canAnswerAt as Date).getTime() - (b.canAnswerAt as Date).getTime();
      });
    if (items.length > 0) {
      let selfCheck = items[0];
      this.eventTimer = setTimeout(
        () => {
          this.events.publish('can-answer-self-check', selfCheck);
        },
        (selfCheck.canAnswerAt as Date).getTime() - AppConfig.now()
      );
    }
  }

  protected clearEvent() {
    clearTimeout(this.eventTimer);
  }

  load(force?: boolean): Promise<any> {
    const isloaded = this.items.length > 0;
    return this.query(force).then((items) => {
      if (force || isloaded == false) {
        this.setupEvent();
      }
      return this.updateBadge().then((result) => {
        return items;
      });
    });
  }

  init(selfCheck: SelfCheck): Promise<any> {
    selfCheck.answerIndex = 0;
    selfCheck.answeredAt = new Date(AppConfig.now());
    selfCheck.setAlert();

    return this.add(selfCheck).then(() => {
      this.events.publish('can-answer-self-check', selfCheck);
    });
  }

  setup(
    username: string,
    type: string,
    initDate: Date
  ): Promise<any> {
    const items = this.createSetupItems(
      username,
      type,
      initDate
    );
    return this.addItems(
      items.filter((item) => {
        return (
          this.items.find((selfSeck) => {
            return selfSeck.answerIndexType == item.answerIndexType;
          }) == null
        );
      })
    ).then(() => {
      this.setupEvent();
      return this.updateBadge();
    });
  }

  async answer(selfCheck: SelfCheck): Promise<any> {
    selfCheck.answeredAt = new Date(AppConfig.now());
    selfCheck.notifyAt = undefined;
    selfCheck.setAlert();

    await this.update(selfCheck);
    this.setupEvent();

    await this.updateBadge();

    this.events.publish('answered-self-check', selfCheck);
  }

  async delete(): Promise<any> {
    await this.remove();
    this.clearEvent();
    return this.updateBadge();
  }

  release(): void {
    this.items = [];
  }

  private createSetupItems(
    username: string,
    type: string,
    initDate: Date
  ): SelfCheck[] {
    const date = new Date(initDate);
    date.setHours(0, 0, 0, 0);

    const canAnswerAt = (answerIndex: number) => {
      const tempDate = new Date(date);
      const days = SelfCheck.indexToWeek(answerIndex) * 7;
      tempDate.setDate(tempDate.getDate() + days);
      return tempDate;
    };
    const notifyAt = (answerIndex: number, notice: number) => {
      const tempDate = canAnswerAt(answerIndex);
      tempDate.setDate(tempDate.getDate() + notice);
      tempDate.setHours(0, 0, 0, 0);
      return tempDate;
    };
    const createSelfCheck = (
      type: string,
      index: number,
      notices: number[],
      title?: string
    ) => {
      let item = {
        answerIndex: index,
        type: type,
        canAnswerAt: canAnswerAt(index),
        username: username,
      };

      if (notices) {
        const noticeDates = notices.map(notice => notifyAt(index, notice));
        item = Object.assign(item, { notices: noticeDates });
        if (noticeDates.length > 0) {
          item = Object.assign(item, { notifyAt: noticeDates[0] });
        }
      }

      if (title) {
        item = Object.assign(item, { title: title });
      }

      return new SelfCheck(item);
    };

    const items: SelfCheck[] = [];
    SelfCheckSettings[type].forEach(schedule => {
      items.push(createSelfCheck(
        schedule.type,
        schedule.index,
        schedule.notices,
        schedule.title
      ));
    });

    return items;
  }

  private async updateBadge(): Promise<boolean> {
    try {
      let result = await Badge.checkPermissions();
      if (result.display == 'prompt') {
        result = await Badge.requestPermissions();
      }
      if (result.display == 'granted') {
        if (this.requests.length == 0) {
          await Badge.clear();
        } else {
          const count = this.requests.length;
          await Badge.set({ count });
        }
      }
      return true;
    } catch (error) {
      console.log('updateBadge', error);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SelfCheckService extends BaseSelfCheckService {
  protected readonly table = environment.aws.resourceNamePrefix + '-SelfCheck';

  constructor(
    private dbServ: DynamoDBService,
    events: Events
  ) {
    super(events);
  }

  protected async query(force?: boolean): Promise<SelfCheck[]> {
    if (!force && this.items.length > 0) {
      console.log('SelfCheckService: items is loaded');
      return this.items;
    }

    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        KeyConditionExpression: '#userId = :userId',
        ExpressionAttributeNames: {
          '#userId': 'userId',
        },
        ExpressionAttributeValues: {
          ':userId': credentials.identityId,
        },
        FilterExpression: 'attribute_not_exists(deletedAt)',
        ScanIndexForward: false,
      };
      const result = await doc.query(params).promise();
      const items = result.Items;

      this.items = [];
      if (items) {
        items.forEach((item) => {
          this.items.push(new SelfCheck(item));
        });
        this.sort();  
      }

      console.log('SelfCheckService: query', this.items);
      return this.items;
    } catch (error) {
      console.log('SelfCheckService: query error', error);
      throw error;
    }
  }

  protected async add(selfCheck: SelfCheck): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      selfCheck.createdAt = new Date();
      const item: any = selfCheck.toJSON();
      item.userId = credentials.identityId;
      const params = {
        TableName: this.table,
        Item: item,
        ConditionExpression: 'attribute_not_exists(answerIndexType)',
      };
      await doc.put(params).promise();
  
      this.items.push(selfCheck);
      this.sort();
    } catch (error) {
      console.log('SelfCheckService: add error', error);
      throw error;
    }
  }

  protected async addItems(selfSecks: SelfCheck[]): Promise<void> {
    if (selfSecks.length === 0) {
      return;
    }

    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const requestSelfcecks: SelfCheck[][] = [];
      const length = selfSecks.length;
      const chunkSize = 25;
    
      for (let i = 0; i < length; i += chunkSize) {
        requestSelfcecks.push(selfSecks.slice(i, i + chunkSize));
      }

      for (const requestSelfCheck of requestSelfcecks) {
        const requestItems: any = {};
        requestItems[`${this.table}`] = requestSelfCheck.map((selfCheck) => {
          selfCheck.createdAt = new Date();
          const item: any = selfCheck.toJSON();
          item.userId = credentials.identityId;
          return {
            PutRequest: {
              Item: item,
              ConditionExpression: 'attribute_not_exists(answerIndexType)',
            },
          };
        });
        await doc.batchWrite({ RequestItems: requestItems }).promise();
      }

      this.items = selfSecks.concat(
        this.items.filter((item) => {
          return (
            selfSecks.find((selfSeck) => {
              return selfSeck.answerIndexType == item.answerIndexType;
            }) == null
          );
        })
      );
      this.sort();
    } catch (error) {
      console.log('SelfCheckService: addItems error', error);
      throw error;
    }
  }

  protected async update(selfCheck: SelfCheck): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      selfCheck.updatedAt = new Date();
      const item: any = selfCheck.toJSON();
      item.userId = credentials.identityId;
      const params = {
        TableName: this.table,
        Item: item,
      };
      await doc.put(params).promise();

      const index = this.items.findIndex(e => e.answerIndexType == selfCheck.answerIndexType);
      this.items[index] = selfCheck;  
      this.sort();
    } catch (error) {
      console.log('SelfCheckService: update error', error);
      throw error;
    }
  }

  protected async remove(): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const tasks = this.items.map((item) => {
        const params = {
          TableName: this.table,
          Key: {
            userId: credentials.identityId,
            answerIndexType: item.answerIndexType,
          },
        };
        return doc.delete(params).promise();
      });
      await Promise.all(tasks);

      this.items = [];
    } catch (error) {
      console.log('SelfCheckService: remove error', error);
      throw error;
    }
  }
}
