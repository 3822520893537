import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Auth } from 'aws-amplify';

import { environment } from '../../../environments/environment';
import { SelfCheckService } from '../services';
import { SelfCheck } from '../../models/self-check';
import {
  ConsultationStates,
  ConsultationState
} from '../../models/consultation-states';
import { DynamoDBService } from '../database/dynamodb.service';

export interface TroubleConsultation {
  id: number,
  title: string
}

export interface TroubleFactor {
  id: number,
  title: string,
  pasId: string,
  consultations: TroubleConsultation[]
}

export interface TroubleCategory {
  id: string,
  order: number,
  title: string,
  factors: TroubleFactor[]
}

export interface Trouble {
  week0SelfCheck?: SelfCheck;
  week0Categories: TroubleCategory[];
  week4SelfCheck?: SelfCheck;
  week4Categories: TroubleCategory[];
  allCategories: TroubleCategory[];
  consultationStates?: ConsultationStates;
}

export abstract class BaseConsultationService {
  private static readonly CONSULTATION_ASSETS_URL = `assets/consultation`;

  protected trouble: Trouble | null = null;

  constructor(
    private http: HttpClient,
    private selfCheckServ: SelfCheckService
  ) {}

  getTrouble(): Trouble | null {
    return this.trouble;
  }

  getConsultationState(id: number): ConsultationState | undefined {
    if (this.trouble == null) return;
    return this.trouble?.consultationStates?.get(`${id}`);
  }

  setConsultationState(id: number, state: ConsultationState): void {
    if (this.trouble == null) return;
    if (this.trouble.consultationStates == undefined) {
      this.trouble.consultationStates = new ConsultationStates({});
    }
    this.trouble.consultationStates.set(`${id}`, state);
  }

  protected abstract loadConsultationStates(force?: boolean): Promise<ConsultationStates>;
  protected abstract saveConsultationStates(): Promise<any>;
  protected abstract deleteConsultationStates(): Promise<any>;

  async load(force?: boolean): Promise<Trouble> {
    if (!force && this.trouble != null) {
      console.log(`${this.constructor.name}: trouble is loaded.`);
      return Promise.resolve(this.trouble);
    }

    await this.selfCheckServ.load();

    const url = `${ConsultationService.CONSULTATION_ASSETS_URL}/trouble.json`;
    const allCategories = await firstValueFrom(this.http.get(url)) as any[];

    const selfChecks = this.selfCheckServ.selfChecks;
    const week0SelfCheck = selfChecks.find((selfCheck) => selfCheck.type === 'WEEK0');
    const week4SelfCheck = selfChecks.find((selfCheck) => selfCheck.type === 'WEEK4');
    const week0Categories = this.getWeekCategories(week0SelfCheck, allCategories);
    const week4Categories = this.getWeekCategories(week4SelfCheck, allCategories);
    this.updateWeek4Categories(week4Categories, week0Categories);

    const consultationStates = await this.loadConsultationStates();

    this.trouble = {
      week0SelfCheck,
      week0Categories,
      week4SelfCheck,
       week4Categories,
      allCategories: JSON.parse(JSON.stringify(allCategories)),
      consultationStates
    };

    return this.trouble;
  }

  private getWeekCategories(selfCheck: SelfCheck | undefined, allCategories: TroubleCategory[]): any[] {
    if (selfCheck == undefined || selfCheck.answers == undefined) return [];

    const pas = selfCheck.answers.filter((answer: any) => {
      return answer.category === 'PAS' && answer.answer === 1
    });

    const weekCategories = [ ...allCategories ].map(category => {
      const weekCategory = { ...category }; 
      weekCategory.factors = weekCategory.factors.filter((factor: any) => {
        if (factor.pasIds != undefined) {
          return factor.pasIds.find((pasId: string) => {
            return pas.find((pas: any) => pas.itemId == pasId) !== undefined;
          }) !== undefined;
        }
        return pas.find((pas: any) => pas.itemId == factor.pasId) !== undefined;
      }).map((factor: any) => {
        const weekFactor = { ...factor };
        if (weekFactor.pasIds != undefined) {
          weekFactor.pasIds = weekFactor.pasIds.filter((pasId: string) => {
            return pas.find((pas: any) => pas.itemId == pasId) !== undefined;
          });
          weekFactor.titles = weekFactor.titles.map((title: any) => {
            const titleData = weekFactor.pasIds.find((pasId: string) => pasId == title.pasId);
            return titleData ? title.value : undefined;
          });
          weekFactor.titles = weekFactor.titles.filter((title: string) => title !== undefined);
        }
        return weekFactor;
      });
      return weekCategory;
    }).filter(category => category.factors.length > 0);

    return weekCategories;
  }

  private updateWeek4Categories(week4Categories: TroubleCategory[], week0Categories: TroubleCategory[]): void {
    // 新規 / 継続している苦痛をマーク
    week4Categories.forEach((week4category: any) => {
      const week0Category = week0Categories.find((week0Category: any) => week0Category.id === week4category.id);
      if (week0Category) {
        week4category.factors.forEach((week4Factor: any) => {
          if (week0Category.factors.find((week0Factor: any) => week0Factor.id === week4Factor.id)) {
            week4Factor.continuation = true;
          } else {
            week4Factor.new = true;
          }
        });
      } else {
        week4category.factors.forEach((week4Factor: any) => {
          week4Factor.new = true;
        });
      }
    });

    // 解消された苦痛をマーク
    week0Categories.forEach((week0Category: any) => {
      const week4Category = week4Categories.find((week4Category: any) => week4Category.id === week0Category.id);
      if (week4Category) {
        const week4Factors = week4Category.factors;
        week0Category.factors.forEach((week0Factor: any) => {
          if (week4Factors.find((week4Factor: any) => week4Factor.id === week0Factor.id) === undefined) {
            const copy =  { ...week0Factor };
            copy.rsolved = true;
            week4Factors.push(copy);
          }
        });
        week4Factors.sort((a: any, b: any) => a.id - b.id);
      } else {
        const copy = JSON.parse(JSON.stringify(week0Category));
        copy.factors.forEach((factor: any) => {
          factor.rsolved = true;
        });
        week4Categories.push(copy);
      }
    });

    // カテゴリーの並び替え
    week4Categories.sort((a: any, b: any) => a.order - b.order);
  }

  async save(): Promise<void> {
    await this.saveConsultationStates();
  }

  async delete(): Promise<void> {
    await this.deleteConsultationStates();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConsultationService extends BaseConsultationService {
  protected readonly table = environment.aws.resourceNamePrefix + '-ConsultationStates';

  constructor(
    http: HttpClient,
    selfCheckServ: SelfCheckService,
    private dbServ: DynamoDBService
  ) {
    super(http, selfCheckServ);
  }

  protected async loadConsultationStates(force?: boolean): Promise<ConsultationStates> {
    if (!force && this.trouble?.consultationStates) {
      console.log(`${this.constructor.name}: consultationStates is loaded`);
      return this.trouble.consultationStates;
    }

    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        Key: {
          userId: credentials.identityId
        }
      };
      const result = await doc.get(params).promise();
      const item = result.Item;
      if (item) {
        return new ConsultationStates(item);
      } else {
        console.log(`${this.constructor.name}: initial consultationStates`);
        return new ConsultationStates({});
      }
    } catch(error) {
      console.log('${this.constructor.name}: load error', error);
      throw error;
    };
  }

  protected async saveConsultationStates(): Promise<any> {
    if (!this.trouble?.consultationStates) return;

    const credentials = await Auth.currentCredentials();
    const doc = await this.dbServ.getDocumentClient(credentials);

    const consultationStates = this.trouble.consultationStates;
    const item: any = consultationStates.toJSON();
    item.userId = credentials.identityId;

    const result = await doc.put({
      TableName: this.table,
      Item: item
    }).promise();

    return result;
  }

  protected async deleteConsultationStates(): Promise<any> {
    try {
      const credentials = await Auth.currentCredentials();
      const doc = await this.dbServ.getDocumentClient(credentials);

      const params = {
        TableName: this.table,
        Key: {
          userId: credentials.identityId,
        },
      };
      await doc.delete(params).promise();  
    } catch (error) {
      console.log(`${this.constructor.name}: delete error`, error);
      throw error;
    }
  }
}
