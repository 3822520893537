import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { ATWorksheet } from '../../../models/at-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ATWorksheetService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-ATWorksheet';

  constructor(
    dbServ: DynamoDBService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): ATWorksheet {
    return item ? new ATWorksheet(item) : new ATWorksheet();
  }

  override get indexName(): string {
    return 'DateSorted';
  }
}
