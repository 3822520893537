import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/services';
import { AuthStatus } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private userServ: UserService,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const authStatus = this.userServ.getAuthStatus();
    console.log('AuthGuard.canActivate auth status: ', AuthStatus[authStatus]);

    if (authStatus !== AuthStatus.EMAIL_VERIFICATION_REQUIRED && authStatus !== AuthStatus.DONE) {
      this.router.navigate(['/auth']);
      return false;
    }

    const activatedAt = this.userServ.getAttribute(UserService.ATTR_ACTIVATED_AT);
    console.log("AuthGuard.canActivate UserService.ATTR_ACTIVATED_AT: ", activatedAt);
    if (!activatedAt) {
      this.router.navigate(['/auth']);
      return false;
    }

    return true;
  }
}
