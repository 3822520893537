import { RequireFinishedWorksheet } from './lesson';

export interface EnableWorksheetItem {
  name: string;
  items: Array<string>;
}

export interface UnlockCondition {
  locked: boolean;
  name: string;
  message: string|undefined;
  isMaking: boolean;
}

export class LessonPart {
  title!: string;
  lockedTitle!: string;
  partIndex!: number;
  enabledCondition!: string;
  disabled!: boolean;
  locked!: boolean;
  contentsPath!: string;
  comments!: any[];
  enableWorksheet!: string;
  requireFinishedWorksheet!: RequireFinishedWorksheet;

  // flag for enable cr items
  enableWorksheetItem!: EnableWorksheetItem;

  unlockCondition!: UnlockCondition;

  isLessonFinish!: boolean;

  progress!: number;
  startedAt!: Date;
  finishedAt!: Date;

  lessonInputs: any;

  constructor(fields: any) {
    for (const f in fields) {
      this[(f as keyof this)] = fields[f];
    }

    if (fields['locked'] == undefined) this.locked = true;
    if (this.progress == undefined) this.progress = 0;
    if (!this.lessonInputs) this.lessonInputs = {};
    if (fields['startedAt']) this.startedAt = new Date(fields['startedAt']);
    if (fields['finishedAt']) this.finishedAt = new Date(fields['finishedAt']);
  }

  toJSON() {
    let json: any = {};
    if (this.partIndex != null) json.partIndex = this.partIndex;
    if (this.progress != null) json.progress = this.progress;
    if (this.startedAt != null) json.startedAt = this.startedAt.toISOString();
    if (this.finishedAt != null)
      json.finishedAt = this.finishedAt.toISOString();
    if (this.lessonInputs) json.lessonInputs = this.lessonInputs;
    json.locked = this.locked;
    return json;
  }

  get Title(): string {
    return this.lockedTitle ? this.lockedTitle : this.title;
  }

  get status(): string {
    if (this.locked) {
      return 'locked';
    } else if (this.finishedAt) {
      return 'finished';
    } else if (this.startedAt) {
      return 'activated';
    } else {
      return 'new';
    }
  }
}
