import { Worksheet } from './worksheet';

export class ATWorksheet extends Worksheet {
  public static readonly LESSONINPUT_KEY_OPPONENT = 'ONSAY-who';
  public static readonly LESSONINPUT_KEY_TRIGGER = 'ONSAY-trigger';
  public static readonly LESSONINPUT_KEY_ON1 = 'ONSAY-on1';
  public static readonly LESSONINPUT_KEY_ON2 = 'ONSAY-on2';
  public static readonly LESSONINPUT_KEY_ON3 = 'ONSAY-on3';
  public static readonly LESSONINPUT_KEY_ON4 = 'ONSAY-on4';
  public static readonly LESSONINPUT_KEY_ON_CHECK = 'ONSAY-on-check';
  public static readonly LESSONINPUT_KEY_FEELING = 'ONSAY-say-feeling';
  public static readonly LESSONINPUT_KEY_PROPOSAL1 = 'ONSAY-say-proposal1';
  public static readonly LESSONINPUT_KEY_PROPOSAL2 = 'ONSAY-say-proposal2';
  public static readonly LESSONINPUT_KEY_ADVANTAGE = 'ONSAY-say-advantage';
  public static readonly LESSONINPUT_KEY_VALUATION = 'ONSAY-valuation';
  public static readonly LESSONINPUT_KEY_FREE = 'ONSAY-free';

  public static readonly LESSONINPUT_KEY_ON_SKIP_VALUE = 'ヒント';
  public static readonly LESSONINPUT_KEY_PROPOSAL_SKIP_VALUE = 'ヒント';

  public static readonly LESSONINPUT_KEY_OPPONENT_TARGET = 'opponent';

  progress!: number;
  opponent!: string;
  trigger!: string;
  firstOn!: string;
  secondOn!: string;
  thirdOn!: string;
  on!: string;
  onCheck!: string;
  onStep!: number;
  feeling!: string;
  proposal!: string;
  proposalStep!: number;
  advantage!: string;
  valuation!: string;
  free!: string;

  constructor(fields?: any) {
    super(fields);

    if (this.progress == undefined) this.progress = 0;
    if (!this.opponent) this.opponent = '';
    if (!this.trigger) this.trigger = '';
    if (!this.firstOn) this.firstOn = '';
    if (!this.secondOn) this.secondOn = '';
    if (!this.thirdOn) this.thirdOn = '';
    if (!this.on) this.on = '';
    if (this.onStep == undefined) this.onStep = 0;
    if (!this.onCheck) this.onCheck = '';
    if (!this.feeling) this.feeling = '';
    if (!this.proposal) this.proposal = '';
    if (this.proposalStep == undefined) this.proposalStep = 0;
    if (!this.advantage) this.advantage = '';
    if (!this.valuation) this.valuation = '';
    if (!this.free) this.free = '';
  }

  override toJSON(): any {
    const json: any = super.toJSON();

    if (this.progress != undefined) json.progress = this.progress;
    if (this.opponent) json.opponent = this.opponent;
    if (this.trigger) json.trigger = this.trigger;
    if (this.firstOn) json.firstOn = this.firstOn;
    if (this.secondOn) json.secondOn = this.secondOn;
    if (this.thirdOn) json.thirdOn = this.thirdOn;
    if (this.on) json.on = this.on;
    if (this.onStep != undefined) json.onStep = this.onStep;
    if (this.onCheck) json.onCheck = this.onCheck;
    if (this.feeling) json.feeling = this.feeling;
    if (this.proposal) json.proposal = this.proposal;
    if (this.proposalStep != undefined) json.proposalStep = this.proposalStep;
    if (this.advantage) json.advantage = this.advantage;
    if (this.valuation) json.valuation = this.valuation;
    if (this.free) json.free = this.free;

    return json;
  }

  isSameDate(date: Date): boolean {
    const format = 'YYYY-MM-DD';
    const dateString: string = this.formatDate(date, format);
    return this.formatDate(this.createdAt, format) == dateString;
  }

  override revert(originalData: any): void {
    super.revert(originalData);

    if (originalData.progress) this.progress = originalData.progress;
    else this.progress = 0;
    if (originalData.opponent) this.opponent = originalData.opponent;
    else this.opponent = '';
    if (originalData.trigger) this.trigger = originalData.trigger;
    else this.trigger = '';
    if (originalData.firstOn) this.firstOn = originalData.firstOn;
    else this.firstOn = '';
    if (originalData.secondOn) this.secondOn = originalData.secondOn;
    else this.secondOn = '';
    if (originalData.thirdOn) this.thirdOn = originalData.thirdOn;
    else this.thirdOn = '';
    if (originalData.on) this.on = originalData.on;
    else this.on = '';
    if (originalData.onStep) this.onStep = originalData.onStep;
    else this.onStep = 0;
    if (originalData.onCheck) this.onCheck = originalData.onCheck;
    else this.onCheck = '';
    if (originalData.feeling) this.feeling = originalData.feeling;
    else this.feeling = '';
    if (originalData.proposal) this.proposal = originalData.proposal;
    else this.proposal = '';
    if (originalData.proposalStep) this.proposalStep = originalData.proposalStep;
    else this.proposalStep = 0;
    if (originalData.advantage)
      this.advantage = originalData.advantage;
    else this.advantage = '';
    if (originalData.valuation) this.valuation = originalData.valuation;
    else this.valuation = '';
    if (originalData.free) this.free = originalData.free;
    else this.free = '';
  }

  get digest(): string {
    if (this.trigger) {
      const trigger = this.trigger.trim();
      const index = trigger.indexOf('\n');
      if (index > 0) {
        return trigger.substring(0, index);
      }
      return trigger;
    }
    return '';
  }

  override get isMaking(): boolean {
    return !this.isCompletion;
  }

  get canSave(): boolean {
    return (
      this.opponent.length > 0 ||
      this.trigger.length > 0 ||
      this.on.length > 0 ||
      this.feeling.length > 0 ||
      this.proposal.length > 0 ||
      this.advantage.length > 0 ||
      this.valuation.length > 0 ||
      this.free.length > 0
    );
  }

  get isCompletion(): boolean {
    return (
      this.opponent.length > 0 &&
      this.trigger.length > 0 &&
      this.on.length > 0 &&
      this.feeling.length > 0 &&
      this.proposal.length > 0 &&
      this.advantage.length > 0 &&
      this.valuation.length > 0 &&
      this.free.length > 0
    );
  }

  setLessonInputValue(key:string, value: any): void {
    if (key == ATWorksheet.LESSONINPUT_KEY_OPPONENT) {
      this.opponent = value;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_TRIGGER) {
      this.trigger = value;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_ON1) {
      if (ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE != value) {
        this.firstOn = value;
        this.on = value;
      }
      this.onStep = 1;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_ON2) {
      this.secondOn = value;
      this.on = value;
      this.onStep = 2;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_ON3) {
      if (ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE != value) {
        this.thirdOn = value;
        this.on = value;
      }
      this.onStep = 3;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_ON4) {
      this.on = value;
      this.onStep = 4;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_ON_CHECK) {
      this.onCheck = value;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_FEELING) {
      this.feeling = value;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_PROPOSAL1) {
      if (ATWorksheet.LESSONINPUT_KEY_PROPOSAL_SKIP_VALUE != value) {
        this.proposal = value;
      }
      this.proposalStep = 1;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_PROPOSAL2) {
      this.proposal = value;
      this.proposalStep = 2;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_ADVANTAGE) {
      this.advantage = value;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_VALUATION) {
      this.valuation = value;
    }
    else if (key == ATWorksheet.LESSONINPUT_KEY_FREE) {
      this.free = value;
    }
  }

  toLessonInputs(): any {
    const lessonInputs: {[key:string]:any} = {};
    if (this.opponent) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_OPPONENT] = this.opponent;
    }
    if (this.trigger) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_TRIGGER] = this.trigger;
    }
    if (this.onStep == 1) {
      if (this.firstOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = this.firstOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
    } else if (this.onStep == 2) {
      if (this.firstOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = this.firstOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
      if (this.secondOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON2] = this.secondOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON2] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
    } else if (this.onStep == 3) {
      if (this.firstOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = this.firstOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
      if (this.secondOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON2] = this.secondOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON2] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
      if (this.thirdOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON3] = this.thirdOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON3] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
    } else if (this.onStep == 4) {
      if (this.firstOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = this.firstOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON1] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
      if (this.secondOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON2] = this.secondOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON2] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
      if (this.thirdOn) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON3] = this.thirdOn;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON3] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
      if (this.on) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON4] = this.on;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON4] = ATWorksheet.LESSONINPUT_KEY_ON_SKIP_VALUE;
      }
    }
    if (this.onCheck) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_ON_CHECK] = this.onCheck;
    }
    if (this.feeling) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_FEELING] = this.feeling;
    }
    if (this.proposalStep == 1) {
      if (this.proposal) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_PROPOSAL1] = this.proposal;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_PROPOSAL1] = ATWorksheet.LESSONINPUT_KEY_PROPOSAL_SKIP_VALUE;
      }
    } else if (this.proposalStep == 2) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_PROPOSAL1] = ATWorksheet.LESSONINPUT_KEY_PROPOSAL_SKIP_VALUE;
      if (this.proposal) {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_PROPOSAL2] = this.proposal;
      } else {
        lessonInputs[ATWorksheet.LESSONINPUT_KEY_PROPOSAL2] = ATWorksheet.LESSONINPUT_KEY_PROPOSAL_SKIP_VALUE;
      }
    }
    if (this.advantage) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_ADVANTAGE] = this.advantage;
    }
    if (this.valuation) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_VALUATION] = this.valuation;
    }
    if (this.free) {
      lessonInputs[ATWorksheet.LESSONINPUT_KEY_FREE] = this.free;
    }
    return lessonInputs;
  }

  override copy(): ATWorksheet {
    const copiedItem = this.toJSON();
    copiedItem.progress = 1;
    delete copiedItem.worksheetId;
    delete copiedItem.firstOn;
    delete copiedItem.secondOn;
    delete copiedItem.thirdOn;
    delete copiedItem.on;
    delete copiedItem.onStep;
    delete copiedItem.onCheck;
    delete copiedItem.feeling;
    delete copiedItem.proposal;
    delete copiedItem.proposalStep;
    delete copiedItem.advantage;
    delete copiedItem.valuation;
    delete copiedItem.free;
    return new ATWorksheet(copiedItem);
  }
}
