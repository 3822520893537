export class BARecommendation {
  missionId!: string;

  constructor(fields?: any) {
    if (fields) {
      for (const f in fields) {
        this[(f as keyof this)] = fields[f];
      }
    }
  }

  toJSON() {
    let json: any = {};

    if (this.missionId) json.missionId = this.missionId;

    return json;
  }
}
