import { Injectable } from '@angular/core';

import { WorksheetService } from '../worksheet.service';
import { SMWorksheet } from '../../../models/sm-worksheet';
import { DynamoDBService } from '../../database/dynamodb.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SMWorksheetService extends WorksheetService {
  protected table: string = environment.aws.resourceNamePrefix + '-SMWorksheet';

  constructor(
    dbServ: DynamoDBService
  ) {
    super(dbServ);
  }

  createWorksheet(item?: any): SMWorksheet {
    return item ? new SMWorksheet(item) : new SMWorksheet();
  }

  override get indexName(): string {
    return 'DateSorted';
  }

  override sort(): void {
    (this.items as SMWorksheet[])?.sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });
  }

  override async query(force?: boolean): Promise<any> {
    await super.query(force);
    if (!this.items || this.items.length == 0) {
      this.items = [];
      this.items.push(
        new SMWorksheet({
          trigger:
            '例文）彼女と一緒にレストランへ行き、僕が上司の悪口を言うと、彼女は「上司の悪口を言う人は嫌い」と言い、口論になってしまいました。',
          feeling: 'angry',
          feelingLevel: 5,
          thought: '彼女なのに、僕の話に共感してくれないなんて、ひどい。',
          behavior: 'かなり大声で怒鳴ってしまい、机も叩いた。',
          response: 'ふるえ（足がふるえる、手がふるえる）',
          date: new Date(2018, 3, 1, 20, 0),
          sample: true,
        })
      );
    }
    return this.items;
  }

  override async add(worksheet: SMWorksheet): Promise<any> {
    if (worksheet.sample) {
      return;
    }
    return super.add(worksheet);
  }

  override async update(worksheet: SMWorksheet): Promise<any> {
    if (worksheet.sample) {
      return;
    }
    return super.update(worksheet);
  }

  override async delete(worksheet: SMWorksheet): Promise<any> {
    if (worksheet.sample) {
      this.items?.splice(this.items?.indexOf(worksheet), 1);
      return Promise.resolve();
    }
    return super.delete(worksheet);
  }
}
